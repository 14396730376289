import { useMemo } from "react";
import { useState, useCallback } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Content, Footer, Icon, Question } from "../components";
import data from "../ppl";
import { arrayShuffle, lsReadRandomize, lsWriteRandomize } from "../util";
import css from "./study.module.css";

function Study() {
  const p = useParams<{ subject: string }>();
  const [answers, setAnswer] = useState<{ [q: number]: number }>({});
  const [randomize, setRandomize] = useState(lsReadRandomize());
  const id = p.subject ? parseInt(p.subject.split("-")[0]) : 0;
  const questions = useMemo(() => {
    const key = data.findIndex((d) => d.num === id);
    if (key >= 0) {
      const tmp = {
        ...data[key],
        questions: [...data[key].questions],
      };
      if (randomize) arrayShuffle(tmp.questions);
      return tmp;
    }
    return undefined;
  }, [id, randomize]);

  const onAnswer = useCallback((question: number, answer: number) => {
    setAnswer((a) => ({ ...a, [question]: answer }));
  }, []);

  const onRandomize = useCallback(() => {
    setRandomize((r) => {
      lsWriteRandomize(!r);
      return !r;
    });
  }, []);

  if (!questions) return <Navigate to="/" replace />;

  return (
    <>
      <Content className={css.header} tag="header">
        <Link to="/">
          <Icon type="back" />
        </Link>
        <h1>{questions.group}</h1>
        <button
          className={randomize ? css.active : ""}
          title="Randomize order"
          onClick={onRandomize}
        >
          <Icon type="random" />
        </button>
      </Content>
      <main>
        {questions.questions.map((q, i) => (
          <Question
            mode="study"
            key={i}
            num={q.num}
            question={q}
            onAnswer={onAnswer}
            answer={answers[q.num]}
          />
        ))}
      </main>
      <Footer />
    </>
  );
}

export default Study;
