const ppl: TData = [
  {
    num: 10,
    group: "Air Law",
    questions: [
      {
        num: 1,
        question:
          "Which of the following documents have to be on board for an international flight?\na) Certificate of aircraft registration\nb) Certificate of airworthiness\nc) Airworthiness review certificate\nd) EASA Form-1\ne) Airplane logbook\nf) Appropriate papers for every crew member\ng) Technical logbook",
        answers: ["b, c, d, e, f, g", "a, b, c, e, f", "a, b, e, g", "d, f, g"],
        correct: 1,
        points: 1,
      },
      {
        num: 2,
        question: "Which area could be crossed with certain restrictions?",
        answers: [
          "No-fly zone",
          "Restricted area",
          "Prohibited area",
          "Dangerous area",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 3,
        question:
          "Where can the type of restriction for a restricted airspace be found?",
        answers: ["NOTAM", "AIC", "ICAO chart 1:500000", "AIP"],
        correct: 3,
        points: 1,
      },
      {
        num: 4,
        question:
          "What is the status of the rules and procedures created by the EASA?\n(e.g. Part-SFCL, Part-MED)",
        answers: [
          "They have the same status as ICAO Annexes",
          "Only after a ratification by individual EU member states they are legally binding",
          "They are not legally binding, they only serve as a guide",
          "They are part of the EU regulation and legally binding to all EU member states",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 5,
        question:
          'Which validity does the "Certificate of Airworthiness" have?',
        answers: ["6 months", "12 years", "Unlimited", "12 months"],
        correct: 2,
        points: 1,
      },
      {
        num: 6,
        question: 'What is the meaning of the abbreviation "ARC"?',
        answers: [
          "Airworthiness Recurring Control",
          "Airspace Rulemaking Committee",
          "Airspace Restriction Criteria",
          "Airworthiness Review Certificate",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 7,
        question:
          'The "Certificate of Airworthiness" is issued by the state...',
        answers: [
          "in which the aircraft is registered.",
          "of the residence of the owner.",
          "in which the aircraft is constructed.",
          "in which the airworthiness review is done.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 8,
        question:
          "A pilot license issued in accordance with ICAO Annex 1 is valid in...",
        answers: [
          "those countries that have accepted this license on application.",
          "the country where the license was issued.",
          "all ICAO countries.",
          "the country where the license was acquired.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 9,
        question: "What is the subject of ICAO Annex 1?",
        answers: [
          "Air traffic services",
          "Flight crew licensing",
          "Rules of the air",
          "Operation of aircraft",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 10,
        question:
          "What is the period of validity of a private pilot license (PPL)?",
        answers: ["Unlimited", "24 months", "48 months", "60 months"],
        correct: 0,
        points: 1,
      },
      {
        num: 11,
        question: "What is the minimum age to obtain a private pilot license?",
        answers: ["17 years", "21 years", "16 years", "18 years"],
        correct: 0,
        points: 1,
      },
      {
        num: 12,
        question:
          "What are the minimum requirements among others to acquire a rating for VFR night flights?",
        answers: [
          "At least 10 additional flight hours at night, three of them with a flight instructor with at least 1 hour cross-country flight plus 5 solo take-offs and full-stop landings",
          "At least 5 additional flight hours at night, four of them with a flight instructor with at least 1 hour cross-country flight plus 5 solo take-offs and full-stop landings",
          "At least 5 additional flight hours at night, three of them with a flight instructor with at least 1 hour cross-country flight plus 5 solo take-offs and full-stop landings",
          "At least 5 additional flight hours at night, three of them with a flight instructor with at least 1 hour cross-country flight plus 10 solo take-offs and full-stop landings",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 13,
        question:
          "The validity of a medical examination certificate class 2 for a 62 years old pilot is...",
        answers: ["60 Months.", "48 Months.", "24 Months.", "12 Months."],
        correct: 3,
        points: 1,
      },
      {
        num: 14,
        question:
          "In which way is a SEP (land) rating renewed if you do not meet the required flight time?",
        answers: [
          "A proficiency check with an examiner",
          "According to the flight experience there have to be several training flights under supervision of an ATO",
          "The ATO can renew the rating after a training flight with a flight instructor",
          "The required flight experience has to be accumulated under supervision of a flight instructor",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 15,
        question:
          "The possession of a european PPL(A) entitles the holder to...",
        answers: [
          "act as pilot on a commercial flight when there are at most 4 people on board.",
          "act as pilot in commercial and non-commercial flights with single engine aircraft.",
          "act as PIC, and only for flights in aircraft with a MTOW of maximum 2000 kg a compensation may be obtained.",
          "act as PIC on non-commercial flights without compensation as well as to receive compensation as a flight instrucor.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 16,
        question:
          "What is the minimum age to start a private pilot training at a flight school?",
        answers: ["16 years", "17 years", "21 years", "18 years"],
        correct: 0,
        points: 1,
      },
      {
        num: 17,
        question: 'What is the meaning of the abbreviation "SERA"?',
        answers: [
          "Selective Radar Altimeter",
          "Standardized European Rules of the Air",
          "Specialized Radar Approach",
          "Standard European Routes of the Air",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 18,
        question: 'What is the meaning of the abbreviation "TRA"?',
        answers: [
          "Temporary Reserved Airspace",
          "Temporary Radar Routing Area",
          "Transponder Area",
          "Terminal Area",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 19,
        question: "What has to be considered when entering an RMZ?",
        answers: [
          "To permanently monitor the radio and if possible to establish radio contact",
          "To obtain a clearance from the local aviation authority",
          "The transponder has to be switched on Mode C and squawk 7000",
          "To obtain a clearance to enter this area",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 20,
        question: "What condition has to be met during a Special VFR flight?",
        answers: [
          "Visual reference to the terrain",
          "Speed not above 115 kt IAS",
          "At least 500 m ground visibilty",
          "A minimum distance to clouds of 2000 m",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 21,
        question: "What has to be done before entering a TMZ?",
        answers: [
          "Switch on the transponder and activate mode A and C or mode S",
          "Request a clearance before entering",
          'Switch on the transponder, activate mode A, and squawk "IDENT"',
          "File a flight plan before the flight",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 22,
        question: 'What is the meaning of an area marked as "TMZ"?',
        answers: [
          "Transportation Management Zone",
          "Touring Motorglider Zone",
          "Traffic Management Zone",
          "Transponder Mandatory Zone",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 23,
        question: 'What is the meaning of the abbreviation "IFR"?',
        answers: [
          "Instrument Flight Rules",
          "Instrument Meteorological Conditions",
          "Bad Weather Flight Rules",
          "Commercial Flight Rules",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 24,
        question: 'A flight is called a "visual flight", if the...',
        answers: [
          "visibility in flight is more than 8 km.",
          "flight is conducted under visual flight rules.",
          "visibility in flight is more than 5 km.",
          "flight is conducted in visual meteorological conditions.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 25,
        question: 'What is the meaning of the abbreviation "VMC"?',
        answers: [
          "Instrument flight conditions",
          "Variable meteorological conditions",
          "Visual meteorological conditions",
          "Visual flight rules",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 26,
        question:
          "Two engine-driven aircraft are flying on crossing courses at the same altitude.\nWhich one has to divert?",
        answers: [
          "The lighter one has to climb",
          "Both have to divert to the right",
          "The heavier one has to climb",
          "Both have to divert to the left",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 27,
        question:
          "Two aeroplanes are flying on crossing tracks.\nWhich one has to divert?",
        answers: [
          "The aircraft which flies from left to right has the right of priority",
          "Both have to divert to the right",
          "The aircraft which flies from right to left has the right of priority",
          "Both have to divert to the left",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 28,
        question:
          'In airspace "D" a Boeing 737 and a Cessna 152 are flying on crossing courses.\nWhich aeroplane has to divert?',
        answers: [
          "The Cessna 152, because IFR and commercial flights have priority",
          "The air traffic control (ATC) has to decide which one has to divert",
          "The aeroplane flying from right to left has priority, the other one has to divert",
          "The Boeing 737, because an airliner has greater power reserves",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 29,
        question:
          "Which of the following options is NOT a sufficient reason to fly below the required minimum height?",
        answers: [
          "Take-off or landing at an aerodrome",
          "An approach without landing at an aerodrome",
          "Bad weather conditions in the vicinity of an aerodrome",
          "The drop of towed objects at an aerodrome",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 30,
        question:
          "A single-engine piston and a turboprop aeroplane are approaching each other opposite at the same altitude.\nWhich aeroplane has to change its track to avoid a collision?",
        answers: [
          "The turboprop aircraft has to give way to the single-engine piston aircraft",
          "Both aircraft have to alter their tracks to the left",
          "Both aircraft have to alter their tracks to the right",
          "The single-engine piston aircraft has to give way to the turboprop aircraft",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 31,
        question:
          "Which distances to clouds have to be maintained during a VFR flight in airpaces C, D and E?",
        answers: [
          "1500 m horizontally, 1000 ft vertically",
          "1500 m horizontally, 1000 m vertically",
          "1000 m horizontally, 1500 ft vertically",
          "1000 m horizontally, 300 m vertically",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 32,
        question:
          "The minimum flight visibility at 5000 ft MSL in airspace B for VFR flights is...",
        answers: ["8000 m.", "3000 m.", "1500 m.", "5000 m."],
        correct: 3,
        points: 1,
      },
      {
        num: 33,
        question:
          'What is the minimum flight visibility in airspace "C" for an aircraft operating under VFR at 5000 ft MSL?',
        answers: ["8000 m", "1500 m", "3000 m", "5000 m"],
        correct: 3,
        points: 1,
      },
      {
        num: 34,
        question:
          'What is the minimum flight visibility in airspace "E" for an aircraft operating under VFR at FL75?',
        answers: ["8000 m", "1500 m", "3000 m", "5000 m"],
        correct: 3,
        points: 1,
      },
      {
        num: 35,
        question:
          'What is the minimum flight visibility in airspace "C" for an aircraft operating under VFR at FL110?',
        answers: ["5000 m", "1500 m", "3000 m", "8000 m"],
        correct: 3,
        points: 1,
      },
      {
        num: 36,
        question:
          'What is the minimum flight visibility in airspace "C" for an aircraft operating under VFR at FL125?',
        answers: ["5000 m", "3000 m", "1500 m", "8000 m"],
        correct: 3,
        points: 1,
      },
      {
        num: 37,
        question:
          'What are the minimum distances to clouds for a VFR flight in airspace "B"?',
        answers: [
          "Horizontally 1.500 m, vertically 1.000 m",
          "Horizontally 1.500 m, vertically 300 m",
          "Horizontally 1.000 m, vertically 1.500 ft",
          "Horizontally 1.000 m, vertically 300 m",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 38,
        question:
          'What is the minimum flight visibility in airspace "C" below FL 100 for an aircraft operating under VFR?',
        answers: ["5 km", "10 km", "1.5 km", "8 km"],
        correct: 0,
        points: 1,
      },
      {
        num: 39,
        question:
          'What is the minimum flight visibility in airspace "C" at and above FL 100 for an aircraft operating under VFR?',
        answers: ["1.5 km", "5 km", "10 km", "8 km"],
        correct: 3,
        points: 1,
      },
      {
        num: 40,
        question: 'The term "ceiling" is defined as the...',
        answers: [
          "altitude of the base of the lowest layer of clouds covering more than half of the sky below 20000 ft.",
          "height of the base of the lowest layer of clouds covering more than half of the sky below 10000 ft.",
          "height of the base of the highest layer of clouds covering more than half of the sky below 20000 ft.",
          "height of the base of the lowest layer of clouds covering more than half of the sky below 20000 ft.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 41,
        question:
          "Being intercepted by a military aircraft at daytime, what is the meaning of the following signal:\nAlternating movement of the ailerons, normally left of the intercepted aircraft, followed by a smooth turn to the left?",
        answers: [
          "Prepare for a safety landing, you have entered a prohibited area",
          "You are entering a restricted area, leave the airspace immediately",
          "Follow me",
          "You are clear of any restricted or reserved airspaces, you can continue on your heading",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 42,
        question:
          "Being intercepted by a military aircraft at daytime, what is the meaning of the following signal:\nA sudden heading change of 90 degrees or more and a pull-up of the aircraft without crossing the track of the intercepted aircraft?",
        answers: [
          "You are entering a restricted area, leave the airspace immediately",
          "You may continue your flight",
          "Follow me, i will bring you to the next suitable airfield",
          "Prepare for a safety landing, you have entered a prohibited area",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 43,
        question: "The altimeter is switched from local QNH to 1013.25 hPa...",
        answers: [
          "at the decision height.",
          "at 4000 ft.",
          "when climbing above the transition altitude.",
          "when descending below FL 100.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 44,
        question:
          "During a flight at FL 80, the altimeter setting has to be...",
        answers: ["1030.25 hPa.", "1013.25 hPa.", "local QFE.", "local QNH."],
        correct: 1,
        points: 1,
      },
      {
        num: 45,
        question: "What is the purpose of the semi-circular rule?",
        answers: [
          "To avoid collisions by reducing the probability of opposing traffic at the same altitude",
          "To fly without a filed flight plan in prescribed zones published in the AIP",
          "To allow safe climbing or descending in a holding pattern",
          "To avoid collisions by suspending turning manoeuvres",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 46,
        question:
          "Which of the following options are possible SSR-Transponder modes?",
        answers: ["A, C, S", "A, C, R", "B, C, V", "B, R, S"],
        correct: 0,
        points: 1,
      },
      {
        num: 47,
        question:
          "A transponder with the ability to send the current pressure level is a...",
        answers: [
          "pressure-decoder.",
          "mode C or S transponder.",
          "mode A transponder.",
          'transponder approved for airspace "B".',
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 48,
        question:
          "Which transponder code indicates a loss of radio communication?",
        answers: ["2000", "7700", "7000", "7600"],
        correct: 3,
        points: 1,
      },
      {
        num: 49,
        question:
          "Which transponder code should be set during a radio failure without any request?",
        answers: ["7600", "7700", "7000", "7500"],
        correct: 0,
        points: 1,
      },
      {
        num: 50,
        question:
          "Which transponder code has to be set unrequested during an emergency?",
        answers: ["7600", "7000", "7700", "7500"],
        correct: 2,
        points: 1,
      },
      {
        num: 51,
        question:
          "Which air traffic service is responsible for the safe conduct of flights?",
        answers: [
          "ALR (alerting service)",
          "FIS (flight information service)",
          "ATC (air traffic control)",
          "AIS (aeronautical information service)",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 52,
        question: "Air traffic control service is conducted by which services?",
        answers: [
          "TWR (aerodrome control service)\nAPP (approach control service)\nACC (area control service)",
          "APP (approach control service)\nACC (area control service)\nFIS (flight information service)",
          "ALR (alerting service)\nSAR (search and rescue service)\nTWR (aerodrome control service)",
          "FIS (flight information service)\nAIS (aeronautical information service)\nAFS (aeronautical fixed telecommunication service)",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 53,
        question:
          'Which answer is correct with regard to separation in airspace "E"?',
        answers: [
          "IFR traffic is separated only from VFR traffic",
          "VFR traffic is separated from VFR and IFR traffic",
          "VFR traffic is separated only from IFR traffic",
          "VFR traffic is not separated from any other traffic",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 54,
        question:
          "Which air traffic services can be expected within an FIR (flight information region)?",
        answers: [
          "ATC (air traffic control)\nFIS (flight information service)",
          "FIS (flight information service)\nALR (alerting service)",
          "AIS (aeronautical information service)\nSAR (search and rescue)",
          "ATC (air traffic control)\nAIS (aeronautical information service)",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 55,
        question: "A pilot can contact FIS (flight information service)...",
        answers: [
          "via telephone.",
          "via internet.",
          "by a personal visit.",
          "via radio communication.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 56,
        question:
          "What is the correct phrase with respect to wake turbulence to indicate that a light aircraft is following an aircraft of a higher wake turbulence category?",
        answers: [
          "Danger jet blast",
          "Be careful wake winds",
          "Attention propwash",
          "Caution wake turbulence",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 57,
        question:
          "Which of the following options states a correct position report?",
        answers: [
          'DEABC reaching "N"',
          'DEABC over "N" in FL 2500 ft',
          'DEABC, "N", 2500 ft',
          'DEABC over "N" at 35',
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 58,
        question: 'What is the meaning of the abbreviation "AIREP"?',
        answers: [
          "Aircraft report",
          "Aeronautical instrument requirement report",
          "Automatic identification report",
          "Aeronautical information report",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 59,
        question:
          "What information is provided in the general part (GEN) of the AIP?",
        answers: [
          "Table of content, classification of airfields with corresponding maps, approach charts, taxi charts, restricted and dangerous airspaces",
          "Map icons, list of radio nav aids, time for sunrise and sunset, airport fees, air traffic control fees",
          "Warnings for aviation, ATS airspaces and routes, restricted and dangerous airspaces",
          "Access restrictions for airfields, passenger controls, requirements for pilots, license samples and validity periods",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 60,
        question:
          "Which are the different parts of the Aeronautical Information Publication (AIP)?",
        answers: [
          "GEN\nMET\nRAC",
          "GEN\nCOM\nMET",
          "GEN\nAGA\nCOM",
          "GEN\nENR\nAD",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 61,
        question: 'What information is provided in the part "AD" of the AIP?',
        answers: [
          "Warnings for aviation, ATS airspaces and routes, restricted and dangerous airspaces.",
          "Table of content, classification of airfields with corresponding maps, approach charts, taxi charts",
          "Map icons, list of radio nav aids, time for sunrise and sunset, airport fees, air traffic control fees",
          "Access restrictions for airfields, passenger controls, requirements for pilots, license samples and validity periods",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 62,
        question:
          "The shown NOTAM is valid until...\nA1024/13 A) LOWW B) 1305211200 C) 1305211400 E) STOCKERAU VOR STO 113.00 UNSERVICEABLE.",
        answers: [
          "21/05/2013 14:00 UTC.",
          "13/10/2013 00:00 UTC.",
          "13/05/2013 12:00 UTC.",
          "21/05/2014 13:00 UTC.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 63,
        question:
          "A Pre-Flight Information Bulletin (PIB) is a presentation of current...",
        answers: [
          "ICAO information of operational significance prepared after the flight.",
          "AIP information of operational significance prepared prior to flight.",
          "AIC information of operational significance prepared after the flight.",
          "NOTAM information of operational significance prepared prior to flight.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 64,
        question: 'The term "aerodrome elevation" is defined as...',
        answers: [
          "the highest point of the landing area.",
          "the lowest point of the landing area.",
          "the highest point of the apron.",
          "the average value of the height of the manoeuvring area.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 65,
        question: 'The term "runway" is defined as a...',
        answers: [
          "round area on an aerodrome prepared for the landing and take-off of aircraft.",
          "rectangular area on a land aerodrome prepared for the landing and take-off of aircraft.",
          "rectangular area on a land or water aerodrome prepared for the landing and take-off of aircraft.",
          "rectangular area on a land aerodrome prepared for the landing and take-off of helicopters.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 66,
        question:
          'Which statement is correct with regard to the term "taxi holding point"?',
        answers: [
          "A taxi holding point is an area where the aircraft must stop unless further permission to proceed is given",
          "A taxi holding point is only to be observed for IFR traffic if instrument weather conditions are prevailing",
          "A taxi holding point is the point at which the aircraft must stop unless further permission to proceed is given",
          "A taxi holding point is designed to indicate the beginning of the safety area and may be crossed when a taxi clearance is given",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 67,
        question: 'What does the reported runway condition "WET" mean?',
        answers: [
          "Some water patches are visible",
          "The surface of the runway is soaked, but there are no significant patches of standing water",
          "A large part of the surface is flooded",
          "The runway is clear of water, ice, and snow",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 68,
        question: 'What does the reported runway condition "DAMP" mean?',
        answers: [
          "Wet surface, but no significant puddles are visible",
          "Change of colour on the surface due to moisture",
          "The runway is clear of water, ice, and snow",
          "A large part of the surface is flooded",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 69,
        question:
          'What does "WATER PATCHES" mean regarding the reported runway condition?',
        answers: [
          "A large part of the surface is flooded",
          "Patches of standing water are visible",
          "Wet surface, but no significant patches are visible",
          "The runway is clear of water, ice, and snow",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 70,
        question:
          "How can a wind direction indicator be marked for better visibility?",
        answers: [
          "The wind direction indicator may be mounted on top of the control tower.",
          "The wind direction indicator could be made from green materials.",
          "The wind direction indicator could be surrounded by a white circle.",
          "The wind direction indicator could be located on a big black surface.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 71,
        question:
          "What is the meaning of a flashing red light signal at a controlled aerodrome directed to an aircraft in flight?",
        answers: [
          "Airport unsafe, do not land",
          "Return for landing, followed by steady green at the appropriate time",
          "Cleared to land",
          "Give way to other aircraft and continue circling",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 72,
        question:
          "What is the meaning of a flashing green light signal at a controlled aerodrome directed to an aircraft in flight?",
        answers: [
          "Return for landing, followed by steady green at the appropriate time",
          "Cleared to land",
          "Give way to other aircraft and continue circling",
          "Airport unsafe, do not land",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 73,
        question:
          "What is the meaning of a steady green light signal at a controlled aerodrome directed to an aircraft in flight?",
        answers: [
          "Give way to other aircraft and continue circling",
          "Cleared to land",
          "Return for landing, followed by steady green at the appropriate time",
          "Airport unsafe, do not land",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 74,
        question:
          "What is the meaning of a flashing white light signal at a controlled aerodrome directed to an aircraft on ground?",
        answers: [
          "Cleared to taxi",
          "Clear the taxiway / runway",
          "Cleared for take-off",
          "Return to starting point",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 75,
        question:
          "What is the meaning of a flashing red light signal at a controlled aerodrome directed to an aircraft on ground?",
        answers: [
          "Cleared for take-off",
          "Cleared to taxi",
          "Immediately taxi clear of runway in use",
          "Return to starting point",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 76,
        question:
          "What is the meaning of a flashing green light signal at a controlled aerodrome directed to an aircraft on ground?",
        answers: [
          "Cleared to taxi",
          "Return to starting point",
          "Land at this airport and proceed to the apron",
          "Cleared for take-off",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 77,
        question: "Of what shape is a landing direction indicator?",
        answers: ["L", "A straight arrow", "An angled arrow", "T"],
        correct: 3,
        points: 1,
      },
      {
        num: 78,
        question: "What is the purpose of the signal square at an aerodrome?",
        answers: [
          "It contains special symbols to indicate the conditions at the aerodrome visually to over-flying aircraft",
          "It is an illuminated area on which search and rescue and fire fighting vehicles are placed",
          "Aircraft taxi to this square to get light signals for taxi and take-off clearance",
          "It is a specially marked area to pick up or drop towing objects",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 79,
        question:
          "In which way should a pilot confirm received light signals in flight?",
        answers: [
          "Apply some changes of RPM",
          "Apply some changes of the rudder",
          "Rock the wings (in the daytime)",
          "Apply some fast pitch changes",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 80,
        question:
          "What is the meaning of a steady red light signal at a controlled aerodrome directed to an aircraft on ground?",
        answers: [
          "Land at this airport and proceed to apron",
          "Clear the taxiway / runway",
          "Return to starting point",
          "Stop",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 81,
        question: "How are two parallel runways designated?",
        answers: [
          'The left runway gets the suffix "L", the right runway "R"',
          'The left runway gets the suffix "L", the right runway remains unchanged',
          'The left runway gets the suffix "-1", the right runway "-2"',
          "The left runway remains unchanged, the right runway designator is increased by 1",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 82,
        question:
          "According to ICAO Anex 14, which is the colour marking of a runway?",
        answers: ["Blue", "White", "Yellow", "Green"],
        correct: 1,
        points: 1,
      },
      {
        num: 83,
        question:
          "What is indicated by a pattern of longitudinal stripes of uniform dimensions disposed symmetrically about the centerline of a runway?",
        answers: [
          "At this point the glide path of an ILS hits the runway",
          "Do not touch down before them",
          "Do not touch down behind them",
          "A ground roll could be started from this position",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 84,
        question:
          "Which runway designators are correct for 2 parallel runways?",
        answers: [
          '"06L" and "06R"',
          '"18" and "18-2"',
          '"26" and "26R"',
          '"24" and "25"',
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 85,
        question: 'What is a "PAPI" (Precision Approach Path Indicator)?',
        answers: [
          "A visual aid that provides guidance information to help a tower controller acquire and maintain the correct approach to an aerodrome or an airport",
          "A visual aid that provides guidance information to help a pilot acquire and maintain the correct glidepath to an aerodrome or an airport",
          "An instrumental aid that provides guidance information to help a pilot acquire and maintain the correct approach to an aerodrome or an airport",
          "A visual aid that provides guidance information to help a pilot acquire and maintain the correct departure track when departing from an aerodrome or an airport",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 86,
        question: "An aerodrome beacon (ABN) is a...",
        answers: [
          "rotating beacon installed at an airport or aerodrome to indicate its location to aircraft pilots from the ground.",
          "rotating beacon installed at the beginning of the final approach to indicate its location to aircraft pilots from the air.",
          "rotating beacon installed at an airport or aerodrome to indicate its location to aircraft pilots from the air.",
          "fixed beacon installed at an airport or aerodrome to indicate its location to aircraft pilots from the air.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 87,
        question: "Which is the colour of runway edge lights?",
        answers: ["Red", "White", "Blue", "Green"],
        correct: 1,
        points: 1,
      },
      {
        num: 88,
        question: "Which is the colour of runway end lights?",
        answers: ["Red", "White", "Blue", "Green"],
        correct: 0,
        points: 1,
      },
      {
        num: 89,
        question: "What is shown on the printed sign?",
        answers: [
          "Point A on a taxiway",
          "Part A of the runway",
          "Taxiway A",
          "Parking position A",
        ],
        correct: 2,
        points: 1,
        figure: "ALW-019",
      },
      {
        num: 90,
        question: "What is shown on the printed sign?",
        answers: [
          "2500 m in the stated direction till reaching the parking area",
          "From this intersection the available runway length is 2500 m in the stated direction",
          "The complete length of the runway in the stated direction is 2500 m",
          "2500 m in the stated direction till reaching the departure point of the runway",
        ],
        correct: 1,
        points: 1,
        figure: "ALW-020",
      },
      {
        num: 91,
        question: "What is the meaning of this sign at an aerodrome?",
        answers: [
          "Landing prohibited for a longer period",
          "Glider flying is in progress",
          "Caution, manoeuvring area is poor",
          "After take-off and before landing all turns have to be made to the right",
        ],
        correct: 1,
        points: 1,
        figure: "ALW-011",
      },
      {
        num: 92,
        question:
          "What is the meaning of the illustrated ground signal as shown in the signal area of an aerodrome?",
        answers: [
          "Caution, manoeuvring area is poor",
          "Prohibition on landing for a longer period",
          "After take-off and before landing all turns have to be made to the right",
          "Ground movement restricted to hard surfaces",
        ],
        correct: 2,
        points: 1,
        figure: "ALW-012",
      },
      {
        num: 93,
        question: 'What is the meaning of "DETRESFA"?',
        answers: [
          "Uncertainty phase",
          "Rescue phase",
          "Distress phase",
          "Alerting phase",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 94,
        question: "Who provides search and rescue service?",
        answers: [
          "Only military organisations",
          "International approved organisations",
          "Only civil organisations",
          "Both military and civil organisations",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 95,
        question:
          "How can a pilot confirm a search and rescue signal on ground in flight?",
        answers: [
          "Rock the wings",
          "Push the rudder in both directions multiple times",
          "Fly in a parabolic flight path multiple times",
          "Deploy and retract the landing flaps multiple times",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 96,
        question:
          "With respect to aircraft accident and incident investigation, what are the three categories regarding aircraft occurrences?",
        answers: [
          "Event\nCrash\nDisaster",
          "Happening\nEvent\nSerious event",
          "Incident\nSerious incident\nAccident",
          "Event\nSerious event\nAccident",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 97,
        question:
          "What is the primary purpose of an aircraft accident investigation?",
        answers: [
          "To Determine the guilty party and draw legal consequences",
          "To clarify questions of liability within the meaning of compensation for passengers",
          "To identify the reasons and work out safety recommendations",
          "To work for the public prosecutor and help to follow-up flight accidents",
        ],
        correct: 2,
        points: 1,
      },
    ],
  },
  {
    num: 20,
    group: "Human Performance and Limitations",
    questions: [
      {
        num: 1,
        question: "The majority of aviation accidents are caused by...",
        answers: [
          "technical failure.",
          "meteorological influences.",
          "geographical influences.",
          "human failure.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 2,
        question: 'The "swiss cheese model" can be used to explain the...',
        answers: [
          "error chain.",
          "procedure for an emergency landing.",
          "optimal problem solution.",
          "state of readiness of a pilot.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 3,
        question:
          "What is the percentage of oxygen in the atmosphere at 6000 ft?",
        answers: ["21 %", "12 %", "78 %", "18.9 %"],
        correct: 0,
        points: 1,
      },
      {
        num: 4,
        question: "What is the percentage of nitrogen in the atmosphere?",
        answers: ["1 %", "21 %", "78 %", "0.1 %"],
        correct: 2,
        points: 1,
      },
      {
        num: 5,
        question:
          "At which altitude is the atmospheric pressure approximately half the MSL value (1013 hPa)?",
        answers: ["18000 ft", "5000 ft", "10000 ft", "22000 ft"],
        correct: 0,
        points: 1,
      },
      {
        num: 6,
        question:
          "Air consists of oxygen, nitrogen and other gases. What is the approximate percentage of other gases?",
        answers: ["0.1 %", "78 %", "1 %", "21 %"],
        correct: 2,
        points: 1,
      },
      {
        num: 7,
        question: "Carbon monoxide poisoning can be caused by...",
        answers: ["little sleep.", "alcohol.", "smoking.", "unhealthy food."],
        correct: 2,
        points: 1,
      },
      {
        num: 8,
        question: 'What does the term "Red-out" mean?',
        answers: [
          '"Red vision" during negative g-loads',
          "Rash during decompression sickness",
          "Anaemia caused by an injury",
          "Falsified colour perception during sunrise and sunset",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 9,
        question: "Carbon monoxide poisoning can be caused by...",
        answers: [
          "fuel or hydraulic fluids.",
          "generator failure.",
          "cracks in the heat exchanger.",
          "Pitot icing.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 10,
        question: "Which of the following is NOT a symptom of hyperventilaton?",
        answers: [
          "Cyanose",
          "Tingling",
          "Spasm",
          "Disturbance of consciousness",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 11,
        question: "Which of the following symptoms may indicate hypoxia?",
        answers: [
          "Joint pain in knees and feet",
          "Muscle cramps in the upper body area",
          "Blue discolouration of lips and fingernails",
          "Blue marks all over the body",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 12,
        question: "Which of the human senses is most influenced by hypoxia?",
        answers: [
          "The oltfactory perception (smell)",
          "The tactile perception (sense of touch)",
          "The auditory perception (hearing)",
          "The visual perception (vision)",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 13,
        question:
          "From which altitude on does the body usually react to the decreasing atmospheric pressure?",
        answers: ["2000 feet", "7000 feet", "10000 feet", "12000 feet"],
        correct: 1,
        points: 1,
      },
      {
        num: 14,
        question:
          "Which altitude marks the lower limit where the the body is unable to completely compensate the effects of the low atmospheric pressure?",
        answers: ["12000 feet", "22000 feet", "5000 feet", "7000 feet"],
        correct: 0,
        points: 1,
      },
      {
        num: 15,
        question: "What is the function of the red blood cells (erythrocytes)?",
        answers: [
          "Oxygen transport",
          "Blood coagulation",
          "Immune defense",
          "Blood sugar regulation",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 16,
        question:
          "Which of the following is responsible for the blood coagulation?",
        answers: [
          "Red blood cells (erythrocytes)",
          "Capillaries of the arteries",
          "White blood cells (leucocytes)",
          "Blood plates (thrombocytes)",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 17,
        question: "What is the function of the white blood cells (leucocytes)?",
        answers: [
          "Oxygen transport",
          "Blood coagulation",
          "Blood sugar regulation",
          "Immune defense",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 18,
        question: "What is the function of the blood platelets (thrombocytes)?",
        answers: [
          "Immune defense",
          "Blood coagulation",
          "Blood sugar regulation",
          "Oxygen transport",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 19,
        question: "Which of the following is NOT a risk factor for hypoxia?",
        answers: ["Smoking", "Menstruation", "Blood donation", "Diving"],
        correct: 3,
        points: 1,
      },
      {
        num: 20,
        question: "Anemic hypoxia can be caused by...",
        answers: [
          "carbon monoxide poisoning.",
          "low pressure.",
          "high altitudes.",
          "alcohol.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 21,
        question:
          "What is an appropriate reaction when a passenger during cruise flight suddenly feels uncomfortable?",
        answers: [
          "Adjust cabin temperature and prevent excessive bank",
          "Give additional oxygen and avoid low load factors",
          "Avoid conversation and choose a higher airspeed",
          "Switch on the heater blower and provide thermal blankets",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 22,
        question:
          "What ist the correct term for an involuntary and stereotypical reaction of an organism to the stimulation of a receptor?",
        answers: ["Reduction", "Virulence", "Coherence", "Reflex"],
        correct: 3,
        points: 1,
      },
      {
        num: 23,
        question:
          "What is the correct term for the system which, among others, controls breathing, digestion, and heart frequency?",
        answers: [
          "Critical nervous system",
          "Autonomic nervous system",
          "Automatical nervous system",
          "Compliant nervous system",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 24,
        question: "What is the parallax error?",
        answers: [
          "Long-sightedness due to aging especially during night",
          "Wrong interpretation of instruments caused by the angle of vision",
          "Misperception of speed during taxiing",
          "A decoding error in communication between pilots",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 25,
        question:
          "Which characteristic is important when choosing sunglasses used by pilots?",
        answers: [
          "Non-polarised",
          "No UV filter",
          "Curved sidepiece",
          "Unbreakable",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 26,
        question:
          "Which part of the visual system is responsible for colour vision?",
        answers: ["Cones", "Rods", "Macula", "Blind spot"],
        correct: 0,
        points: 1,
      },
      {
        num: 27,
        question:
          "The connection between middle ear and nose and throat region is called...",
        answers: ["eardrum.", "cochlea.", "inner ear.", "eustachian tube."],
        correct: 3,
        points: 1,
      },
      {
        num: 28,
        question:
          "In which situation is it NOT possible to achieve a pressure compensation between the middle ear and the environment?",
        answers: [
          "During a light and slow climb",
          "All windows are completely closed",
          "Breathing takes place using the mouth only",
          "The eustachien tube is blocked",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 29,
        question:
          "Wings level after a longer period of turning can lead to the impression of...",
        answers: [
          "starting a descent.",
          "starting a climb.",
          "steady turning in the same direction as before.",
          "turning into the opposite direction.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 30,
        question:
          "Which of the following options does NOT stimulate motion sickness (disorientation)?",
        answers: [
          "Head movements during turns",
          "Flying under the influence of alcohol",
          "Non-accelerated straight and level flight",
          "Turbulence in level flight",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 31,
        question:
          "An acceleration during a straight horizontal flight can lead to the illusion of...",
        answers: ["a descent.", "a climb.", "an inverted flight.", "a bank."],
        correct: 1,
        points: 1,
      },
      {
        num: 32,
        question:
          "A deceleration during a straight horizontal flight can lead to the illusion of...",
        answers: ["a climb.", "a descent.", "a bank.", "an inverted flight."],
        correct: 1,
        points: 1,
      },
      {
        num: 33,
        question:
          "Which optical illusion might be caused by a runway with an upslope during the approach?",
        answers: [
          "The pilot has the feeling that the approach is too fast and reduces the speed below the normal approach speed",
          "The pilot has the feeling that the approach is too high and therefore descents below the regular glide slope",
          "The pilot has the feeling that the approach is too slow and speeds up above the normal approach speed",
          "The pilot has the feeling that the approach is too low and therefore approaches the runway above the regular glide slope",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 34,
        question:
          "What impression may be caused when approaching a runway with an upslope?",
        answers: [
          "A landing beside the centerline",
          "A hard landing",
          "An undershoot",
          "An overshoot",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 35,
        question:
          "The occurence of a vertigo is most likely when moving the head...",
        answers: [
          "during a straight horizontal flight.",
          "during a turn.",
          "during a descent.",
          "during a climb.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 36,
        question: "A Grey-out is the result of...",
        answers: [
          "hypoxia.",
          "tiredness.",
          "positive g-forces.",
          "hyperventilation.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 37,
        question: "Visual illusions are mostly caused by...",
        answers: [
          "rapid eye movements.",
          "misinterpretation of the brain.",
          "colour blindness.",
          "binocular vision.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 38,
        question:
          "The average decrease of blood alcohol level for an adult in one hour is approximately...",
        answers: [
          "0.1 percent.",
          "0.01 percent.",
          "0.03 percent.",
          "0.3 percent.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 39,
        question: "Which answer states a risk factor for diabetes?",
        answers: [
          "Overweight",
          "Smoking",
          "Sleep deficiency",
          "Alcohol consumption",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 40,
        question: "A risk factor for decompression sickness is...",
        answers: [
          "smoking.",
          "sports.",
          "scuba diving prior to flight.",
          "100 % oxygen after decompression.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 41,
        question:
          "Which statement is correct with regard to the short-term memory?",
        answers: [
          "It can store 10 (±5) items for 30 to 60 seconds",
          "It can store 7 (±2) items for 10 to 20 seconds",
          "It can store 3 (±1) items for 5 to 10 seconds",
          "It can store 5 (±2) items for 1 to 2 minutes",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 42,
        question:
          "For what approximate time period can the short-time memory store information?",
        answers: [
          "35 to 50 seconds",
          "30 to 40 seconds",
          "3 to 7 seconds",
          "10 to 20 seconds",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 43,
        question: "What is a latent error?",
        answers: [
          "An error which is made by the pilot actively and consciously",
          "An error which only has consequences after landing",
          "An error which remains undetected in the system for a long time",
          "An error which has an immediate effect on the controls",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 44,
        question: 'What does the term "confirmation bias" mean?',
        answers: [
          "The feedback loop in a closed communication",
          "The preference to find arguments to proof the own mental model",
          "The bias to confirm each radio call",
          "The critical check of ambiguous situations in flight",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 45,
        question:
          "The ongoing process to monitor the current flight situation is called...",
        answers: [
          "constant flight check.",
          "situational thinking.",
          "anticipatory check procedure.",
          "situational awareness.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 46,
        question:
          "Regarding the communication model, how can the use of the same code during radio communication be ensured?",
        answers: [
          "By the use of radio phraseology",
          "By using radios certified for aviation use only",
          "By the use of proper headsets",
          "By a particular frequency allocation",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 47,
        question: "In what different ways can a risk be handled appropriately?",
        answers: [
          "Extrude, avoid, palliate, transfer",
          "Ignore, accept, transfer, extrude",
          "Avoid, reduce, transfer, accept",
          "Avoid, ignore, palliate, reduce",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 48,
        question:
          "Under which circumstances is it more likely to accept higher risks?",
        answers: [
          "If there is not enough information available",
          "During check flights due to a high level of nervousness",
          "During flight planning when excellent weather is forecast",
          "Due to group-dynamic effects",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 49,
        question: 'What is the meaning of "risky shift"?',
        answers: [
          "Crossing of rudder and ailerons on short final",
          "The tendency to accept higher risks in groups",
          "Spontaneous change of landing direction when the runway has an upslope",
          "Seat adjustment in flight",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 50,
        question: "Which dangerous attitudes are often combined?",
        answers: [
          "Macho and invulnerability",
          "Impulsivity and carefulness",
          "Invulnerability and self-abandonment",
          "Self-abandonment and macho",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 51,
        question: "What is an indication for a macho attitude?",
        answers: [
          "Careful walkaround procedure",
          "Comprehensive risk assessment when faced with unfamiliar situations",
          "Risky flight maneuvers to impress spectators on ground",
          "Quick resignation in complex and critical situations",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 52,
        question: "Which factor can lead to human error?",
        answers: [
          "Double check of relevant actions",
          "Proper use of checklists",
          "To be doubtful if something looks unclear or ambiguous",
          "The bias to see what we expect to see",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 53,
        question:
          "What is the best combination of traits with respect to the individual attitude and behaviour for a pilot?",
        answers: [
          "Introverted - unstable",
          "Introverted - stable",
          "Extroverted - unstable",
          "Extroverted - stable",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 54,
        question: "Complacency is a risk due to...",
        answers: [
          "the high number of mistakes normally made by humans.",
          "increased cockpit automation.",
          "the high error rate of technical systems.",
          "better training options for young pilots.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 55,
        question:
          "The ideal level of arousal is at which point in the diagram?\nP = Performance\nA = Arousal / Stress",
        answers: ["Point A", "Point D", "Point C", "Point B"],
        correct: 3,
        points: 1,
        figure: "HPL-002",
      },
      {
        num: 56,
        question:
          "At which point in the diagram will a pilot find himself to be overstrained?\nP = Perfromance\nA = Arousal / Stress",
        answers: ["Point D", "Point B", "Point C", "Point A"],
        correct: 0,
        points: 1,
        figure: "HPL-002",
      },
      {
        num: 57,
        question:
          "Which of the following qualities are influenced by stress?\n1. Attention\n2. Concentration\n3. Responsiveness\n4. Memory",
        answers: ["2, 4", "1", "1, 2, 3, 4", "1, 2, 3"],
        correct: 2,
        points: 1,
      },
      {
        num: 58,
        question: "Which answer is correct concerning stress?",
        answers: [
          "Stress can occur if there seems to be no solution for a given problem",
          "Training and experience have no influence on the occurence of stress",
          "Stress and its different symptoms are irrelevant for flight safety",
          "Everybody reacts to stress in the same manner",
        ],
        correct: 0,
        points: 1,
      },
    ],
  },
  {
    num: 30,
    group: "Meteorology",
    questions: [
      {
        num: 1,
        question:
          "What clouds and weather may result from an humid and instable air mass, that is pushed against a chain of mountains by the predominant wind and forced to rise?",
        answers: [
          "Embedded CB with thunderstorms and showers of hail and/or rain.",
          "Thin Altostratus and Cirrostratus clouds with light and steady precipitation.",
          "Overcast low stratus (high fog) with no precipitation.",
          "Smooth, unstructured NS cloud with light drizzle or snow (during winter).",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 2,
        question:
          "What type of fog emerges if humid and almost saturated air, is forced to rise upslope of hills or shallow mountains by the prevailling wind?",
        answers: [
          "Orographic fog",
          "Steaming fog",
          "Radiation fog",
          "Advection fog",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 3,
        question:
          'What situation is called "over-development" in a weather report?',
        answers: [
          "Vertical development of Cumulus clouds to rain showers",
          "Widespreading of Cumulus clouds below an inversion layer",
          "Change from blue thermals to cloudy thermals during the afternoon",
          "Development of a thermal low to a storm depression",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 4,
        question: 'What is the gas composition of "air"?',
        answers: [
          "Oxygen 21 %\nWater vapour 78 %\nNoble gases / carbon dioxide 1 %",
          "Oxygen 78 %\nWater vapour 21 %\nNitrogen 1 %",
          "Nitrogen 21 %\nOxygen 78 %\nNoble gases / carbon dioxide 1 %",
          "Oxygen 21 %\nNitrogen 78 %\nNoble gases / carbon dioxide 1 %",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 5,
        question:
          "Weather phenomena are most common to be found in which atmospheric layer?",
        answers: ["Stratosphere", "Tropopause", "Thermosphere", "Troposphere"],
        correct: 3,
        points: 1,
      },
      {
        num: 6,
        question:
          'What is the mass of a "cube of air" with the edges 1 m long, at MSL according ISA?',
        answers: ["1,225 kg", "0,01225 kg", "0,1225 kg", "12,25 kg"],
        correct: 0,
        points: 1,
      },
      {
        num: 7,
        question:
          "At what rate does the temperature change with increasing height according to ISA (ICAO Standard Atmosphere) within the troposphere?",
        answers: [
          "Decreases by 2° C / 1000 ft",
          "Increases by 2° C / 1000 ft",
          "Decreases by 2° C / 100 m",
          "Increases by 2° C / 100 m",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 8,
        question:
          "What is the mean height of the tropopause according to ISA (ICAO Standard Atmosphere)?",
        answers: ["18000 ft", "11000 ft", "11000 m", "36000 m"],
        correct: 2,
        points: 1,
      },
      {
        num: 9,
        question: 'The term "tropopause" is defined as...',
        answers: [
          "the layer above the troposphere showing an increasing temperature.",
          "the boundary area between the mesosphere and the stratosphere.",
          "the boundary area between the troposphere and the stratosphere.",
          "the height above which the temperature starts to decrease.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 10,
        question:
          "Temperatures will be given by meteorological aviation services in Europe in which unit?",
        answers: [
          "Degrees Centigrade (° C)",
          "Kelvin",
          "Gpdam",
          "Degrees Fahrenheit",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 11,
        question: 'What is meant by "inversion layer"?',
        answers: [
          "An atmospheric layer where temperature decreases with increasing height",
          "A boundary area between two other layers within the atmosphere",
          "An atmospheric layer with constant temperature with increasing height",
          "An atmospheric layer where temperature increases with increasing height",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 12,
        question: 'What is meant by "isothermal layer"?',
        answers: [
          "An atmospheric layer where temperature increases with increasing height",
          "An atmospheric layer where temperature decreases with increasing height",
          "A boundary area between two other layers within the atmosphere",
          "An atmospheric layer with constant temperature with increasing height",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 13,
        question:
          "The temperature lapse rate with increasing height within the troposphere according ISA is...",
        answers: [
          "0,65° C / 100 m.",
          "3° C / 100 m.",
          "1° C / 100 m.",
          "0,6° C / 100 m.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 14,
        question:
          "Which process may result in an inversion layer at about 5000 ft (1500 m) height?",
        answers: [
          "Intensive sunlight insolation during a warm summer day",
          "Widespread descending air within a high pressure area",
          "Ground cooling by radiation during the night",
          "Advection of cool air in the upper troposphere",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 15,
        question: "An inversion layer close to the ground can be caused by...",
        answers: [
          "ground cooling during the night.",
          "large-scale lifting of air.",
          "intensifying and gusting winds.",
          "thickening of clouds in medium layers.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 16,
        question: "What is the ISA standard pressure at FL 180 (5500 m)?",
        answers: ["250 hPa", "300 hPa", "1013.25 hPa", "500 hPa"],
        correct: 3,
        points: 1,
      },
      {
        num: 17,
        question:
          "The pressure which is measured at a ground station and reduced to mean sea level (MSL) by means of the actual atmospheric conditions is called...",
        answers: ["QNH.", "QNE.", "QFE.", "QFF."],
        correct: 3,
        points: 1,
      },
      {
        num: 18,
        question: "Which processes result in decreasing air density?",
        answers: [
          "Decreasing temperature, increasing pressure",
          "Increasing temperature, decreasing pressure",
          "Increasing temperature, increasing pressure",
          "Decreasing temperature, decreasing pressure",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 19,
        question: "The pressure at MSL in ISA conditions is...",
        answers: ["113.25 hPa.", "15 hPa.", "1013.25 hPa.", "1123 hPa."],
        correct: 2,
        points: 1,
      },
      {
        num: 20,
        question:
          "The height of the tropopause of the International Standard Atmosphere (ISA) is at...",
        answers: ["5500 ft.", "11000 ft.", "36000 ft.", "48000 ft."],
        correct: 2,
        points: 1,
      },
      {
        num: 21,
        question: "The barometric altimeter indicates height above...",
        answers: [
          "a selected reference pressure level.",
          "mean sea level.",
          "standard pressure 1013.25 hPa.",
          "ground.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 22,
        question: "The altimeter can be checked on the ground by setting...",
        answers: [
          "QFE and comparing the indication with the airfield elevation.",
          "QNH and comparing the indication with the airfield elevation.",
          "QNE and checking that the indication shows zero on the ground.",
          "QFF and comparing the indication with the airfield elevation.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 23,
        question: "The barometric altimeter with QFE setting indicates...",
        answers: [
          "height above the pressure level at airfield elevation.",
          "true altitude above MSL.",
          "height above MSL.",
          "height above standard pressure 1013.25 hPa.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 24,
        question: "The barometric altimeter with QNH setting indicates...",
        answers: [
          "height above standard pressure 1013.25 hPa.",
          "height above the pressure level at airfield elevation.",
          "true altitude above MSL.",
          "height above MSL.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 25,
        question:
          "Given the following information, what is the true altitude?\n(rounded to the nearest 50 ft)\nQNH: 983 hPa\nAltitude: FL 85\nOutside Air Temperature: ISA - 10°",
        answers: ["7900 ft", "9400 ft", "7300 ft", "7600 ft"],
        correct: 2,
        points: 1,
      },
      {
        num: 26,
        question:
          "How can wind speed and wind direction be derived from surface weather charts?",
        answers: [
          "By alignment and distance of isobaric lines",
          "By alignment and distance of hypsometric lines",
          "By alignment of lines of warm- and cold fronts.",
          "By annotations from the text part of the chart",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 27,
        question: 'Which force causes "wind"?',
        answers: [
          "Centrifugal force",
          "Thermal force",
          "Coriolis force",
          "Pressure gradient force",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 28,
        question:
          "Above the friction layer, with a prevailing pressure gradient, the wind direction is...",
        answers: [
          "perpendicular to the isohypses.",
          "at an angle of 30° to the isobars towards low pressure.",
          "parallel to the isobars.",
          "perpendicular to the isobars.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 29,
        question:
          "Which of the stated surfaces will reduce the wind speed most due to ground friction?",
        answers: [
          "Flat land, deserted land, no vegetation",
          "Oceanic areas",
          "Mountainous areas, vegetation cover",
          "Flat land, lots of vegetation cover",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 30,
        question: "The movement of air flowing together is called...",
        answers: ["convergence.", "divergence.", "soncordence.", "subsidence."],
        correct: 0,
        points: 1,
      },
      {
        num: 31,
        question: "The movement of air flowing apart is called...",
        answers: ["convergence.", "concordence.", "divergence.", "subsidence."],
        correct: 2,
        points: 1,
      },
      {
        num: 32,
        question:
          "What weather development will result from convergence at ground level?",
        answers: [
          "Descending air and cloud formation",
          "Ascending air and cloud dissipation",
          "Descending air and cloud dissipation",
          "Ascending air and cloud formation",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 33,
        question:
          "When air masses meet each other head on, how is this referred to and what air movements will follow?",
        answers: [
          "Convergence resulting in sinking air",
          "Divergence resulting in sinking air",
          "Convergence resulting in air being lifted",
          "Divergence resulting in air being lifted",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 34,
        question:
          "What are the air masses that Central Europe is mainly influenced by?",
        answers: [
          "Arctic and polar cold air",
          "Polar cold air and tropical warm air",
          "Equatorial and tropical warm air",
          "Tropical and arctic cold air",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 35,
        question:
          "With regard to global circulation within the atmosphere, where does polar cold air meets subtropical warm air?",
        answers: [
          "At the equator",
          "At the geographic poles",
          "At the subtropical high pressure belt",
          "At the polar front",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 36,
        question: "Winds blowing uphill are defined as...",
        answers: [
          "katabatic winds.",
          "anabatic winds.",
          "convergent winds.",
          "subsident winds.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 37,
        question: "Winds blowing downhill are defined as...",
        answers: [
          "anabatic winds.",
          "katabatic winds.",
          "convergent winds.",
          "subsident winds.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 38,
        question: "Air descending behind a mountain range is defined as...",
        answers: [
          "katabatic wind.",
          "convergent wind.",
          "anabatic wind.",
          "divergent wind.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 39,
        question: '"Foehn" conditions usually develop with...',
        answers: [
          "instability, widespread air blown against a mountain ridge.",
          "stability, widespread air blown against a mountain ridge.",
          "instability, high pressure area with calm wind.",
          "stability, high pressure area with calm wind.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 40,
        question:
          "What type of turbulence is typically found close to the ground on the lee side during Foehn conditions?",
        answers: [
          "Inversion turbulence",
          "Turbulence in rotors",
          "Clear-air turbulence (CAT)",
          "Thermal turbulence",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 41,
        question: "Light turbulence always has to be expected...",
        answers: [
          "above cumulus clouds due to thermal convection.",
          "below stratiform clouds in medium layers.",
          "when entering inversions.",
          "below cumulus clouds due to thermal convection.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 42,
        question: "Moderate to severe turbulence has to be expected...",
        answers: [
          "with the appearance of extended low stratus clouds (high fog).",
          "overhead unbroken cloud layers.",
          "below thick cloud layers on the windward side of a mountain range.",
          "on the lee side of a mountain range when rotor clouds are present.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 43,
        question:
          "Which answer contains every state of water found in the atmosphere?",
        answers: [
          "Liquid and solid",
          "Liquid, solid, and gaseous",
          "Gaseous and liquid",
          "Liquid",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 44,
        question:
          "How do dew point and relative humidity change with decreasing temperature?",
        answers: [
          "Dew point remains constant, relative humidity decreases",
          "Dew point decreases, relative humidity increases",
          "Dew point increases, relative humidity decreases",
          "Dew point remains constant, relative humidity increases",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 45,
        question:
          "How do spread and relative humidity change with increasing temperature?",
        answers: [
          "Spread increases, relative humidity decreases",
          "Spread remains constant, relative humidity decreases",
          "Spread increases, relative humidity increases",
          "Spread remains constant, relative humidity increases",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 46,
        question: 'The "spread" is defined as...',
        answers: [
          "relation of actual to maximum possible humidity of air.",
          "maximum amount of water vapour that can be contained in air.",
          "difference between dew point and condensation point.",
          "difference between actual temperature and dew point.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 47,
        question:
          "With other factors remaining constant, decreasing temperature results in...",
        answers: [
          "decreasing spread and decreasing relative humidity.",
          "increasing spread and decreasing relative humidity.",
          "increasing spread and increasing relative humidity.",
          "decreasing spread and increasing relative humidity.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 48,
        question:
          "What process causes latent heat being released into the upper troposphere?",
        answers: [
          "Descending air across widespread areas",
          "Stabilisation of inflowing air masses",
          "Cloud forming due to condensation",
          "Evaporation over widespread water areas",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 49,
        question: "The saturated adiabatic lapse rate is...",
        answers: [
          "lower than the dry adiabatic lapse rate.",
          "proportional to the dry adiabatic lapse rate.",
          "higher than the dry adiabatic lapse rate.",
          "equal to the dry adiabatic lapse rate.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 50,
        question: "The dry adiabatic lapse rate has a value of...",
        answers: [
          "1,0° C / 100 m.",
          "2° / 1000 ft.",
          "0,6° C / 100 m.",
          "0,65° C / 100 m.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 51,
        question:
          "The saturated adiabatic lapse rate should be assumed with a mean value of:",
        answers: [
          "1,0° C / 100 m.",
          "0° C / 100 m.",
          "0,6° C / 100 m.",
          "2° C / 1000 ft.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 52,
        question:
          "What weather conditions may be expected during conditionally unstable conditions?",
        answers: [
          "Layered clouds up to high levels, prolonged rain or snow",
          "Shallow cumulus clouds with base at medium levels",
          "Sky clear of clouds, sunshine, low winds",
          "Towering cumulus, isolated showers of rain or thunderstorms",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 53,
        question:
          "Which conditions are likely for the formation of advection fog?",
        answers: [
          "Cold, humid air moves over a warm ocean",
          "Warm, humid air moves over a cold surface",
          "Warm, humid air cools during a cloudy night",
          "Humidity evaporates from warm, humid ground into cold air",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 54,
        question: "Clouds are basically distinguished by what types?",
        answers: [
          "Stratiform and ice clouds",
          "Layered and lifted clouds",
          "Cumulus and stratiform clouds",
          "Thunderstorm and shower clouds",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 55,
        question: "Clouds in high layers are referred to as...",
        answers: ["Nimbo-.", "Strato-.", "Alto-.", "Cirro-."],
        correct: 3,
        points: 1,
      },
      {
        num: 56,
        question:
          'What weather phenomenon designated by "2" has to be expected on the lee side during "Foehn" conditions?',
        answers: [
          "Altocumulus Castellanus",
          "Nimbostratus",
          "Cumulonimbus",
          "Altocumulus lenticularis",
        ],
        correct: 3,
        points: 1,
        figure: "MET-001",
      },
      {
        num: 57,
        question: "What cloud type does the picture show?",
        answers: ["Stratus", "Cumulus", "Altus", "Cirrus"],
        correct: 1,
        points: 1,
        figure: "MET-002",
      },
      {
        num: 58,
        question: "What cloud type does the picture show?",
        answers: ["Altocumulus", "Cumulus", "Stratus", "Cirrus"],
        correct: 3,
        points: 1,
        figure: "MET-004",
      },
      {
        num: 59,
        question: "What factor may affect the top of cumulus clouds?",
        answers: [
          "Relative humidity",
          "The spread",
          "The presence of an inversion layer",
          "The absolute humidity",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 60,
        question: "What factors may indicate a tendency to fog formation?",
        answers: [
          "Low pressure, increasing temperature",
          "Low spread, decreasing temperature",
          "Low spread, increasing temperature",
          "Strong winds, decreasing temperature",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 61,
        question:
          'What condition may prevent the formation of "radiation fog"?',
        answers: [
          "Overcast cloud cover",
          "Calm wind",
          "Low spread",
          "Clear night, no clouds",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 62,
        question: 'What process results in the formation of "advection fog"?',
        answers: [
          "Cold, moist air is being moved across warm ground areas",
          "Warm, moist air is moved across cold ground areas",
          "Cold, moist air mixes with warm, moist air",
          "Prolonged radiation during nights clear of clouds",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 63,
        question:
          'What process results in the formation of "orographic fog" ("hill fog")?',
        answers: [
          "Prolonged radiation during nights clear of clouds",
          "Warm, moist air is moved across a hill or a mountain range",
          "Cold, moist air mixes with warm, moist air",
          "Evaporation from warm, moist ground area into very cold air",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 64,
        question:
          "What factors are required for the formation of precipitation in clouds?",
        answers: [
          "High humidity and high temperatures",
          "The presence of an inversion layer",
          "Calm winds and intensive sunlight insolation",
          "Moderate to strong updrafts",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 65,
        question:
          "The formation of medium to large precipitation particles requires...",
        answers: [
          "a high cloud base.",
          "strong wind.",
          "an inversion layer.",
          "strong updrafts.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 66,
        question: "Which type of cloud is associated with prolonged rain?",
        answers: [
          "Cumulonimbus",
          "Cirrostratus",
          "Nimbostratus",
          "Altocumulus",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 67,
        question:
          "Regarding the type of cloud, precipitation is classified as...",
        answers: [
          "light and heavy precipitation.",
          "showers of snow and rain.",
          "prolonged rain and continuous rain.",
          "rain and showers of rain.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 68,
        question:
          "How is an air mass described when moving to Central Europe via the Russian continent during winter?",
        answers: [
          "Maritime tropical air",
          "Continental tropical air",
          "Continental polar air",
          "Maritime polar air",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 69,
        question: "The character of an air mass is given by what properties?",
        answers: [
          "Temperatures at origin and present region",
          "Wind speed and tropopause height",
          "Region of origin and track during movement",
          "Environmental lapse rate at origin",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 70,
        question: "The symbol labeled (1) as shown in the picture is a / an...",
        answers: ["front aloft.", "occlusion.", "warm front.", "cold front."],
        correct: 3,
        points: 1,
        figure: "MET-005",
      },
      {
        num: 71,
        question: "The symbol labeled (2) as shown in the picture is a / an...",
        answers: ["front aloft.", "cold front.", "warm front.", "occlusion."],
        correct: 2,
        points: 1,
        figure: "MET-005",
      },
      {
        num: 72,
        question: "The symbol labeled (3) as shown in the picture is a / an...",
        answers: ["front aloft.", "warm front.", "cold front.", "occlusion."],
        correct: 3,
        points: 1,
        figure: "MET-005",
      },
      {
        num: 73,
        question:
          "What cloud sequence can typically be observed during the passage of a warm front?",
        answers: [
          "In coastal areas during daytime wind from the coast and forming of cumulus clouds, dissipation of clouds during evening and night",
          "Wind becoming calm, dissipation of clouds and warming during summer; formation of extended high fog layers during winter",
          "Squall line with showers of rain and thunderstorms (Cb), gusting wind followed by cumulus clouds with isolated showers of rain",
          "Cirrus, thickening altostratus and altocumulus clouds, lowering cloud base with rain, nimbostratus",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 74,
        question:
          "What clouds and weather can typically be observed during the passage of a cold front?",
        answers: [
          "In coastal areas during daytime wind from the coast and forming of cumulus clouds, dissipation of clouds during evening and night",
          "Strongly developed cumulus clouds (Cb) with showers of rain and thunderstorms, gusting wind followed by cumulus clouds with isolated showers of rain",
          "Cirrus, thickening altostratus and altocumulus clouds, lowering cloud base with rain, nimbostratus",
          "Wind becoming calm, dissipation of clouds and warming during summer; formation of extended high fog layers during winter",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 75,
        question:
          "What visual flight conditions can be expected within the warm sector of a polar front low during summer time?",
        answers: [
          "Visibilty less than 1000 m, cloud-covered ground",
          "Good visibility, some isolated high clouds",
          "Moderate to good visibility, scattered clouds",
          "Moderate visibility, heavy showers and thunderstorms",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 76,
        question:
          "What visual flight conditions can be expected after the passage of a cold front?",
        answers: [
          "Scattered cloud layers, visbility more than 5 km, formation of shallow cumulus clouds",
          "Good visiblity, formation of cumulus clouds with showers of rain or snow",
          "Medium visibility with lowering cloud bases, onset of prolonged precipitation",
          "Poor visibility, formation of overcast or ground-covering stratus clouds, snow",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 77,
        question:
          "A boundary between a cold polar air mass and a warm subtropical air mass showing no horizontal displacement is called...",
        answers: [
          "cold front.",
          "warm front.",
          "occluded front.",
          "stationary front.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 78,
        question:
          "What is the usual direction of movement of a polar front low?",
        answers: [
          "To the northwest during winter, to the southwest during summer",
          "To the northeast during winter, to the southeast during summer",
          "Parallel to the warm front line to the south",
          "Parallel to the the warm-sector isobars",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 79,
        question:
          "What pressure pattern can be observed during the passage of a polar front low?",
        answers: [
          "Rising pressure in front of the warm front, constant pressure within the warm sector, rising pressure behind the cold front",
          "Falling pressure in front of the warm front, constant pressure within the warm sector, rising pressure behind the cold front",
          "Falling pressure in front of the warm front, constant pressure within the warm sector, falling pressure behind the cold front",
          "Rising pressure in front of the warm front, rising pressure within the warm sector, falling pressure behind the cold front",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 80,
        question:
          "What pressure pattern can be observed when a cold front is passing?",
        answers: [
          "Continually decreasing pressure",
          "Shortly decreasing, thereafter increasing pressure",
          "Continually increasing pressure",
          "Constant pressure pattern",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 81,
        question:
          "What change of wind direction can be expected during the passage of a polar front low in Central Europe?",
        answers: [
          "Backing wind during passage of the warm front, veering wind during passage of the cold front",
          "Backing wind during passage of the warm front, backing wind during passage of the cold front",
          "Veering wind during passage of the warm front, veering wind during passage of the cold front",
          "Veering wind during passage of the warm front, backing wind during passage of the cold front",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 82,
        question:
          "Extensive high pressure areas can be found throughout the year ...",
        answers: [
          "in areeas showing extensive lifting processes.",
          "in mid latitudes along the polar front",
          "over oceanic areas at latitues around 30°N/S.",
          "in tropical areas, close to the equator.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 83,
        question:
          "What cloud type can typically be observed across widespread high pressure areas during summer?",
        answers: [
          "Overcast low stratus",
          "Scattered Cu clouds",
          "Overcast Ns clouds",
          "Squall lines and thunderstorms",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 84,
        question:
          "What pressure pattern may result from cold-air inflow in high tropospheric layers?",
        answers: [
          "Formation of a low in the upper troposphere",
          "Formation of a high in the upper troposphere",
          "Formation of a large ground low",
          "Alternating pressure",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 85,
        question:
          "Cold air inflow in high tropospheric layers may result in...",
        answers: [
          "showers and thunderstorms.",
          "stabilisation and calm weather.",
          "frontal weather.",
          "calm weather and cloud dissipation.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 86,
        question:
          "How does inflowing cold air affect the shape and vertical distance between pressure layers?",
        answers: [
          "Increasing vertical distance, raise in height (high pressure)",
          "Decreasing vertical distance, raise in height (high pressure)",
          "Decrease in vertical distance, lowering in height (low pressure)",
          "Increase in vertical distance, lowering in height (low pressure)",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 87,
        question:
          "What weather phenomena have to be expected around an upper-level trough?",
        answers: [
          "Calm wind, forming of shallow cumulus clouds",
          "Calm weather, formation of lifted fog layers",
          "Formation of high stratus clouds, ground-covering cloud bases",
          "Development of showers and thunderstorms (Cb)",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 88,
        question:
          "What frontal line divides subtropical air from polar cold air, in particular across Central Europe?",
        answers: ["Occlusion", "Cold front", "Polar front", "Warm front"],
        correct: 2,
        points: 1,
      },
      {
        num: 89,
        question:
          "What weather conditions can be expected in high pressure areas during summer?",
        answers: [
          "Changing weather with passing of frontal lines",
          "Squall lines and thunderstorms",
          "Calm winds and widespread areas with high fog",
          "Calm weather and cloud dissipation, few high Cu",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 90,
        question:
          "What weather conditions in Central Europe are typically found in high pressure areas during summer?",
        answers: [
          "Large isobar spacing with calm winds, formation of local wind systems",
          "Large isobar spacing with strong prevailing westerly winds",
          "Small isobar spacing with calm winds, formation of local wind systems",
          "Small isobar spacing with strong prevailing northerly winds",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 91,
        question:
          "What weather conditions can be expected in high pressure areas during winter?",
        answers: [
          "Changing weather with passing of frontal lines",
          "Calm weather and cloud dissipation, few high Cu",
          "Calm winds and widespread areas with high fog",
          "Squall lines and thunderstorms",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 92,
        question:
          "What wind conditions can be expected in areas showing large distances between isobars?",
        answers: [
          "Formation of local wind systems with strong prevailing westerly winds",
          "Strong prevailing easterly winds with rapid backing",
          "Strong prevailing westerly winds with rapid veering",
          "Variable winds, formation of local wind systems",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 93,
        question:
          'What weather conditions can be expected during "Foehn" on the windward side of a mountain range?',
        answers: [
          "Dissipating clouds with unusual warming, accompanied by strong, gusty winds",
          "Scattered cumulus clouds with showers and thunderstorms",
          "Layered clouds, mountains obscured, poor visibility, moderate or heavy rain",
          "Calm wind and forming of high stratus clouds (high fog)",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 94,
        question:
          "Which of the stated wind phenomena will increase in speed since its path is narrowed by mountains?",
        answers: ["Bora", "Mistral", "Scirocco", "Passat"],
        correct: 1,
        points: 1,
      },
      {
        num: 95,
        question:
          "What is the name of the the cold, katabatic wind phenomena blowing from northeast into the Adriatic Sea?",
        answers: ["Scirocco", "Mistral", "Bora", "Passat"],
        correct: 2,
        points: 1,
      },
      {
        num: 96,
        question:
          "Which of the following conditions are most favourable for ice accretion?",
        answers: [
          "Temperatures between 0° C and -12° C, presence of supercooled water droplets (clouds)",
          "Temperaturs below 0° C, strong wind, sky clear of clouds",
          "Temperatures between +10° C and -30° C, presence of hail (clouds)",
          "Temperatures between -20° C and -40° C, presence of ice crystals (Ci clouds)",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 97,
        question:
          "What temperatures are most dangerous with respect to airframe icing?",
        answers: [
          "+5° to -10° C",
          "0° to -12° C",
          "-20° to -40° C",
          "+20° to -5° C",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 98,
        question:
          "Which type of ice forms by very small water droplets and ice crystals hitting the front surfaces of an aircraft?",
        answers: ["Clear ice", "Mixed ice", "Hoar frost", "Rime ice"],
        correct: 3,
        points: 1,
      },
      {
        num: 99,
        question:
          "Which type of ice forms by large, supercooled droplets hitting the front surfaces of an aircraft?",
        answers: ["Hoar frost", "Clear ice", "Rime ice", "Mixed ice"],
        correct: 1,
        points: 1,
      },
      {
        num: 100,
        question:
          "What situation may result in the occurrence of severe wind shear?",
        answers: [
          "Flying ahead of a warm front with visible Ci clouds",
          "Cross-country flying below Cu clouds with about 4 octas coverage",
          "During final approach, 30 min after a heavy shower has passed the airfield",
          "When a shower is visible close to the airfield",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 101,
        question:
          "What conditions are favourable for the formation of thunderstorms?",
        answers: [
          "Warm humid air, conditionally unstable environmental lapse rate",
          "Calm winds and cold air, overcast cloud cover with St or As.",
          "Clear night over land, cold air and patches of fog",
          "Warm and dry air, strong inversion layer",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 102,
        question:
          "What conditions are mandatory for the formation of thermal thunderstorms?",
        answers: [
          "Conditionally unstable atmosphere, low temperature and low humidity",
          "Absolutely stable atmosphere, high temperature and high humidity",
          "Absolutely stable atmosphere, high temperature and low humidity",
          "Conditionally unstable atmosphere, high temperature and high humidity",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 103,
        question:
          "With regard to thunderstorms, strong up- and downdrafts appear during the...",
        answers: [
          "initial stage.",
          "dissipating stage.",
          "mature stage.",
          "thunderstorm stage.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 104,
        question: "Which stage of a thunderstorm is dominated by updrafts?",
        answers: [
          "Dissipating stage",
          "Upwind stage",
          "Mature stage",
          "Cumulus stage",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 105,
        question:
          "What danger is most immenent when an aircraft is hit by lightning?",
        answers: [
          "Rapid cabin depressurization and smoke in the cabin",
          "Surface overheat and damage to exposed aircraft parts",
          "Explosion of electrical equipment in the cockpit",
          "Disturbed radio communication, static noise signals",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 106,
        question:
          "Heavy downdrafts and strong wind shear close to the ground can be expected...",
        answers: [
          "during cold, clear nights with the formation of radiation fog.",
          "near the rainfall areas of heavy showers or thunderstorms.",
          "during approach to an airfield at the coast with a strong sea breeze.",
          "during warm summer days with high, flatted Cu clouds.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 107,
        question:
          "What phenomenon is caused by cold air downdrafts with precipitation from a fully developed thunderstorm cloud?",
        answers: [
          "Electrical discharge",
          "Anvil-head top of Cb cloud",
          "Gust front",
          "Freezing Rain",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 108,
        question:
          "What has to be considered when taking off in a ground inversion?",
        answers: [
          "Climb should be performed with the lowest possible speed and maximum power",
          "Due to low temperatures close to the ground, icing has to be expected",
          "During climb, a sudden decrease in speed and climb performance has to be expected",
          "During the climb, a sudden increase in speed and climb performance has to be expected",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 109,
        question:
          "What danger is most imminent during an approach to an airfield situated in a valley, with strong wind aloft blowing perpendicular to the mountain ridge?",
        answers: [
          "Reduced visibilty, maybe loss of sight to the airfield during final approach",
          "Formation of medium to heavy clear ice on all aircraft surfaces",
          "Heavy downdrafts within rainfall areas below thunderstorm clouds",
          "Wind shear during descent, wind direction may change by 180°",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 110,
        question:
          "What kind of reduction in visibility is not very sensitive to changes in temperature?",
        answers: [
          "Haze (HZ)",
          "Patches of fog (BCFG)",
          "Radiation fog (FG)",
          "Mist (BR)",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 111,
        question:
          "Information about pressure patterns and frontal situation can be found in which chart?",
        answers: [
          "wind chart.",
          "surface weather chart.",
          "Significant Weather Chart (SWC).",
          "hypsometric chart.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 112,
        question:
          "Which weather chart shows the actual air pressure as in MSL along with pressure centers and fronts?",
        answers: [
          "Hypsometric chart",
          "Surface weather chart",
          "Prognostic chart",
          "Wind chart",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 113,
        question: "What information can be obtained from satallite images?",
        answers: [
          "Temperature and dew point of environmental air",
          "Turbulence and icing",
          "Flight visibility, ground visibility, and ground contact",
          "Overview of cloud covers and front lines",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 114,
        question: "What chart shows areas of precipitation?",
        answers: ["GAFOR", "Wind chart", "Satellite picture", "Radar picture"],
        correct: 3,
        points: 1,
      },
      {
        num: 115,
        question:
          "What information is NOT found on Low-Level Significant Weather Charts (LLSWC)?",
        answers: [
          "Information about icing conditions",
          "Radar echos of precipitation",
          "Information about turbulence areas",
          "Front lines and frontal displacements",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 116,
        question:
          "Measured pressure distribution in MSL and corresponding frontal systems are displayed by the...",
        answers: [
          "prognostic chart.",
          "Significant Weather Chart (SWC).",
          "surface weather chart.",
          "hypsometric chart.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 117,
        question: 'In a METAR, "heavy rain" is designated by the identifier...',
        answers: ["RA.", "+SHRA.", "SHRA.", "+RA."],
        correct: 3,
        points: 1,
      },
      {
        num: 118,
        question:
          'In a METAR, "(moderate) showers of rain" are designated by the identifier...',
        answers: ["+RA.", "SHRA.", "+TSRA.", "TS."],
        correct: 1,
        points: 1,
      },
      {
        num: 119,
        question:
          "What information can be found in the ATIS, but not in a METAR?",
        answers: [
          "Information about current weather, for example types of precipitation",
          "Operational information such as runway in use and transition level",
          "Information about mean wind speeds, maximum speeds in gusts if applicable",
          "Approach information, such as ground visibility and cloud base",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 120,
        question:
          "Weather and operational information about the destination aerodrome can be obtained during the flight by...",
        answers: ["VOLMET.", "PIREP.", "ATIS.", "SIGMET."],
        correct: 2,
        points: 1,
      },
      {
        num: 121,
        question: "SIGMET warnings are issued for...",
        answers: [
          "specific routings.",
          "airports.",
          "FIRs / UIRs.",
          "countries.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 122,
        question: "An inversion is a layer ...",
        answers: [
          "with increasing pressure with increasing height.",
          "with decreasing temperature with increasing height.",
          "with constant temperature with increasing height.",
          "with increasing temperature with increasing height.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 123,
        question:
          "What can be expected for the prevailling wind with isobars on a surface weather chart showing large distances?",
        answers: [
          "Strong pressure gradients resulting in strong prevailling wind",
          "Strong pressure gradients resulting in low prevailling wind",
          "Low pressure gradients resulting in strong prevailling wind",
          "Low pressure gradients resulting in low prevailling wind",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 124,
        question: "What is referred to as mountain wind?",
        answers: [
          "Wind blowing uphill from the valley during daytime.",
          "Wind blowing uphill from the valley during the night.",
          "Wind blowing down the mountain side during the night",
          "Wind blowing down the mountain side during daytime.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 125,
        question:
          'Under which conditions "back side weather" ("Rückseitenwetter") can be expected?',
        answers: [
          "before passing of an occlusion",
          "During Foehn at the lee side",
          "After passing of a warm front",
          "After passing of a cold front",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 126,
        question: "What wind is reported as 225/15 ?",
        answers: [
          "north-east wind with 15 kt",
          "south-west wind with 15 km/h",
          "north-east wind with 15 km/h",
          "south-west wind with 15 kt",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 127,
        question:
          "How does air temperatur change in ISA from MSL to approx. 10.000 m height?",
        answers: [
          "from +20° to -40°C",
          "from +30° to -40°C",
          "from -15° to 50°C",
          "from +15° to -50°C",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 128,
        question:
          'What weather is likely to be experienced during "Foehn" in the Bavarian area close to the alps?',
        answers: [
          "High pressure area overhead Biskaya and low pressure area in Eastern Europe",
          "Cold, humid downhill wind on the lee side of the alps, flat pressure pattern",
          "Nimbostratus cloud in the northern alps, rotor clouds at the windward side, warm and dry wind",
          "Nimbostratus cloud in the southern alps, rotor clouds at the lee side, warm and dry wind",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 129,
        question: "Mountain side updrafts can be intensified by ...",
        answers: [
          "Solar irradiation on the windward side",
          "Solar irradiation on the lee side",
          "By warming of upper atmospheric layers",
          "thermal radiation of the windward side during the night",
        ],
        correct: 0,
        points: 1,
      },
    ],
  },
  {
    num: 40,
    group: "Communication",
    questions: [
      {
        num: 1,
        question: "In which situations should a pilot use blind transmissions?",
        answers: [
          "When no radio communication can be established with the appropriate aeronautical station, but when evidence exists that transmissions are received at that ground unit",
          "When a pilot has flown into cloud or fog unintentionally and therefore would like to request navigational assistance from a ground unit",
          "When a transmission containing important navigational or technical information is to be sent to several stations at the same time",
          "When the traffic situation at an airport allows the transmission of information which does not need to be acknowledged by the ground station",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 2,
        question:
          "Leaving a control frequency (except when reaching the final parking position)...",
        answers: [
          "is not mandatory to be reported.",
          "must be approved twice.",
          "must be approved.",
          "must be reported.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 3,
        question: 'Which abbreviation is used for the term "abeam"?',
        answers: ["ABM", "ABB", "ABA", "ABE"],
        correct: 0,
        points: 1,
      },
      {
        num: 4,
        question:
          'Which abbreviation is used for the term "visual flight rules"?',
        answers: ["VFS", "VFR", "VRU", "VMC"],
        correct: 1,
        points: 1,
      },
      {
        num: 5,
        question: 'Which abbreviation is used for the term "obstacle"?',
        answers: ["OBTC", "OBST", "OST", "OBS"],
        correct: 1,
        points: 1,
      },
      {
        num: 6,
        question: 'What does the abbreviation "FIS" stand for?',
        answers: [
          "Flashing information service",
          "Flight information system",
          "Flashing information system",
          "Flight information service",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 7,
        question: 'What does the abbreviaton "FIR" stand for?',
        answers: [
          "Flight integrity receiver",
          "Flow information radar",
          "Flight information region",
          "Flow integrity required",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 8,
        question: 'What does the abbreviation "H24" stand for?',
        answers: [
          "Sunrise to sunset",
          "24 h service",
          "No specific opening times",
          "Sunset to sunrise",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 9,
        question: 'What does the abbreviation "HX" stand for?',
        answers: [
          "No specific opening hours",
          "Sunrise to sunset",
          "Sunset to sunrise",
          "24 h service",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 10,
        question:
          "The altimeter has to be set to what value in order to show zero on ground?",
        answers: ["QNH", "QNE", "QTE", "QFE"],
        correct: 3,
        points: 1,
      },
      {
        num: 11,
        question:
          "Which altitude is displayed on the altimeter when set to a specific QNH?",
        answers: [
          "Altitude in relation to the 1013.25 hPa datum",
          "Altitude in relation to mean sea level",
          "Altitude in relation to the air pressure at the reference airfield",
          "Altitude in relation to the highest elevation within 10 km",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 12,
        question:
          "Which altitude is displayed on the altimeter when set to a specific QFE?",
        answers: [
          "Altitude in relation to the 1013.25 hPa datum",
          "Altitude in relation to the air pressure at the reference airfield",
          "Altitude in relation to the highest elevation within 10 km",
          "Altitude in relation to mean sea level",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 13,
        question: 'What does the abbreviation "QDR" stand for?',
        answers: [
          "True bearing from the station",
          "Magnetic bearing from the station",
          "Magnetic bearing to the station",
          "True bearing to the station",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 14,
        question: 'What does the abbreviation "QUJ" stand for?',
        answers: [
          "True bearing from the station",
          "True bearing to the station",
          "Magnetic bearing to the station",
          "Magnetic bearing from the station",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 15,
        question: 'What does the abbreviation "QTE" stand for?',
        answers: [
          "Magnetic bearing to the station",
          "Magnetic bearing from the station",
          "True bearing from the station",
          "True bearing to the station",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 16,
        question:
          "Which Q-code is used for the magnetic bearing from the station?",
        answers: ["QUJ", "QTE", "QDM", "QDR"],
        correct: 3,
        points: 1,
      },
      {
        num: 17,
        question: "Which Q-code is used for the true bearing from the station?",
        answers: ["QDR", "QUJ", "QDM", "QTE"],
        correct: 3,
        points: 1,
      },
      {
        num: 18,
        question: "Which Q-code is used for the true bearing to the station?",
        answers: ["QDR", "QDM", "QTE", "QUJ"],
        correct: 3,
        points: 1,
      },
      {
        num: 19,
        question:
          "Which of the listed radiotelephony messages has a higher priority than a flight safety message?",
        answers: [
          "Communication related to direction finding",
          "Flight regularity message",
          "Meteorological message",
          "Aircraft position report message",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 20,
        question:
          "What is the correct term for a message used for air traffic control?",
        answers: [
          "Flight regularity message",
          "Meteorological message",
          "Message related to direction finding",
          "Flight safety message",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 21,
        question: "Distress messages are messages...",
        answers: [
          "concerning the safety of an aircraft, a watercraft or some other vehicle or person in sight.",
          "sent by a pilot or an aircraft operating agency which have an imminent meaning for aircraft in flight.",
          "concerning aircraft and their passengers which face a grave and imminent threat and require immediate assistance.",
          "concerning the operation or maintenance of facilities which are important for the safety and regularity of flight operations.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 22,
        question: "Urgency messages are messages...",
        answers: [
          "concerning aircraft and their passengers which face a grave and imminent threat and require immediate assistance.",
          "concerning the safety of an aircraft, a watercraft or some other vehicle or person in sight.",
          "concerning the operation or maintenance of facilities essential for the safety or regularity of aircraft operation.",
          "sent by a pilot or an aircraft operating agency which have an imminent meaning for aircraft in flight.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 23,
        question: "Regularity messages are messages...",
        answers: [
          "concerning the safety of an aircraft, a watercraft or some other vehicle or person in sight.",
          "concerning aircraft and their passengers which face a grave and imminent threat and require immediate assistance.",
          "sent by an aircraft operating agency or an aircraft of immediate concern to an aircraft in flight.",
          "concerning the operation or maintenance of facilities essential for the safety or regularity of aircraft operation.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 24,
        question: "Which of the following messages has the highest priority?",
        answers: [
          "Turn left",
          "Request QDM",
          "QNH 1013",
          "Wind 300 degrees, 5 knots",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 25,
        question: "What is the correct way to transmit the call sign HB-YKM?",
        answers: [
          "Home Bravo Yuliett Kilo Mike",
          "Hotel Bravo Yankee Kilo Mike",
          "Hotel Bravo Yuliett Kilo Mikro",
          "Home Bravo Yankee Kilo Mikro",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 26,
        question: "What is the correct way to transmit the call sign OE-JVK?",
        answers: [
          "Omega Echo Jankee Victor Kilo",
          "Oscar Echo Jankee Victor Kilogramm",
          "Oscar Echo Juliett Victor Kilo",
          "Omega Echo Juliett Victor Kilogramm",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 27,
        question: "An altitude of 4500 ft is transmitted as...",
        answers: [
          "four tousand five hundred.",
          "four five tousand.",
          "four tousand five zero zero.",
          "four five zero zero.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 28,
        question: "A heading of 285 degrees is correctly transmitted as...",
        answers: [
          "two eight five.",
          "two eight five hundred.",
          "two hundred eight five.",
          "two hundred eighty-five.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 29,
        question: "A frequency of 119.500 MHz is correctly transmitted as...",
        answers: [
          "one one niner tousand decimal five zero.",
          "one one niner decimal five.",
          "one one niner decimal five zero.",
          "one one niner decimal five zero zero.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 30,
        question:
          'The directional information "12 o\'clock" is correctly transmitted as...',
        answers: [
          "One two.",
          "One two o'clock.",
          "One two hundred.",
          "Twelve o'clock.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 31,
        question: "Times are transmitted as...",
        answers: ["UTC.", "standard time.", "local time.", "time zone time."],
        correct: 0,
        points: 1,
      },
      {
        num: 32,
        question:
          "If there is any doubt about ambiguity, a time of 1620 is to be transmitted as...",
        answers: [
          "sixteen twenty.",
          "two zero.",
          "one tousand six hundred two zero.",
          "one six two zero.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 33,
        question: 'What is the meaning of the phrase "Roger"?',
        answers: [
          "Permission for proposed action is granted",
          "I understand your message and will comply with it",
          "I have received all of your last transmission",
          "An error has been made in this transmission. The correct version is...",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 34,
        question: 'What is the meaning of the phrase "Correction"?',
        answers: [
          "Permission for proposed action is granted",
          "An error has been made in this transmission. The correct version is...",
          "I have received all of your last transmission",
          "I understand your message and will comply with it",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 35,
        question: 'What is the meaning of the phrase "Approved"?',
        answers: [
          "I have received all of your last transmission",
          "I understand your message and will comply with it",
          "Permission for proposed action is granted",
          "An error has been made in this transmission. The correct version is...",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 36,
        question:
          "Which phrase does a pilot use when he / she wants to check the readability of his / her transmission?",
        answers: [
          "What is the communication like?",
          "How do you read?",
          "Request readability",
          "You read me five",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 37,
        question:
          "Which phrase is used by a pilot when he wants to fly through controlled airspace?",
        answers: ["Apply", "Want", "Would like", "Request"],
        correct: 3,
        points: 1,
      },
      {
        num: 38,
        question:
          'What phrase is used by a pilot if a transmission is to be answered with "yes"?',
        answers: ["Roger", "Yes", "Affirm", "Affirmative"],
        correct: 2,
        points: 1,
      },
      {
        num: 39,
        question:
          'What phrase is used by a pilot if a transmission is to be answered with "no"?',
        answers: ["Negative", "Not", "Finish", "No"],
        correct: 0,
        points: 1,
      },
      {
        num: 40,
        question:
          "Which phrase is to be used when a pilot wants the tower to know that he is ready for take-off?",
        answers: [
          "Ready for start-up",
          "Ready for departure",
          "Request take-off",
          "Ready",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 41,
        question:
          "What phrase is used by a pilot to inform the tower about a go-around?",
        answers: [
          "No landing",
          "Pulling up",
          "Going around",
          "Approach canceled",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 42,
        question: "What is the call sign of the aerodrome control?",
        answers: ["Tower", "Airfield", "Ground", "Control"],
        correct: 0,
        points: 1,
      },
      {
        num: 43,
        question: "What is the call sign of the surface movement control?",
        answers: ["Tower", "Earth", "Ground", "Control"],
        correct: 2,
        points: 1,
      },
      {
        num: 44,
        question: "What is the call sign of the flight information service?",
        answers: ["Info", "Advice", "Flight information", "Information"],
        correct: 3,
        points: 1,
      },
      {
        num: 45,
        question: "What is the correct abbreviation of the call sign D-EAZF?",
        answers: ["DEA", "AZF", "DZF", "DEF"],
        correct: 2,
        points: 1,
      },
      {
        num: 46,
        question:
          "In what case is the pilot allowed to abbreviate the call sign of his aircraft?",
        answers: [
          "After the ground station has used the abbreviation",
          "Within controlled airspace",
          "If there is little traffic in the traffic circuit",
          "After passing the first reporting point",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 47,
        question:
          "What is the correct way of using the aircraft call sign at first contact?",
        answers: [
          "Using the first two characters only",
          "Using the last two characters only",
          "Using the first three characters only",
          "Using all characters",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 48,
        question:
          "What is the correct way of establishing radio communication between D-EAZF and Dusseldorf Tower?",
        answers: [
          "Dusseldorf Tower over",
          "DEAZF is calling Dusseldorf Tower",
          "Dusseldorf Tower D-EAZF",
          "Tower from D-EAZF",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 49,
        question:
          'What is the correct way of acknowledging the instruction "Call Hamburg Tower on 121.275"?',
        answers: [
          "Call 121.275",
          "Call tower",
          "121.275",
          "Call tower on 121.275",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 50,
        question: "What does a readability of 1 indicate?",
        answers: [
          "The transmission is perfectly readable",
          "The transmission is unreadable",
          "The transmission is readable but with difficulty",
          "The transmission is readable now and then",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 51,
        question: "What does a readability of 2 indicate?",
        answers: [
          "The transmission is readable now and then",
          "The transmission is readable but with difficulty",
          "The transmission is perfectly readable",
          "The transmission is unreadable",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 52,
        question: "What does a readability of 3 indicate?",
        answers: [
          "The transmission is readable now and then",
          "The transmission is perfectly readable",
          "The transmission is readable but with difficulty",
          "The transmission is unreadable",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 53,
        question: "What does a readability of 5 indicate?",
        answers: [
          "The transmission is readable now and then",
          "The transmission is readable but with difficulty",
          "The transmission is unreadable",
          "The transmission is perfectly readable",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 54,
        question:
          "Which information from a ground station does not require readback?",
        answers: ["Runway in use", "Wind", "SSR-Code", "Altitude"],
        correct: 1,
        points: 1,
      },
      {
        num: 55,
        question:
          "Which information from a ground station does not require readback?",
        answers: [
          "Altimeter setting",
          "Traffic information",
          "Taxi instructions",
          "Heading",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 56,
        question:
          'What is the correct way of acknowledging the instruction "DZF after lift-off climb straight ahead until 2500 feet before turning right heading 220 degrees, wind 090 degrees, 5 knots, runway 12, cleared for take-off"?',
        answers: [
          "DZF after lift-off climb straight ahead 2500 feet, wilco, heading 220 degrees, 090 degrees, 5 knots, cleared for take-off",
          "DZF after lift-off climb straight ahead 2500 feet, then turn right heading 220, runway 12, cleared for take-off",
          "DZF after lift-off climb straight ahead 2500 feet, then turn right heading 220, 090 degrees, 5 knots",
          "DZF after lift-off climb straight ahead 2500 feet, then turn right heading 220, 090 degrees, 5 knots, cleared for take-off",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 57,
        question:
          'What is the correct way of acknowledging the instruction "Next report PAH"?',
        answers: ["Positive", "Roger", "Report PAH", "Wilco"],
        correct: 3,
        points: 1,
      },
      {
        num: 58,
        question:
          'What is the correct way of acknowledging the instruction "Squawk 4321, Call Bremen Radar on 131.325"?',
        answers: [
          "Roger",
          "Squawk 4321, wilco",
          "Squawk 4321, 131.325",
          "Wilco",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 59,
        question:
          'What is the correct way of acknowledging "You are now entering airspace Delta"?',
        answers: ["Entering", "Roger", "Wilco", "Airspace Delta"],
        correct: 1,
        points: 1,
      },
      {
        num: 60,
        question:
          'What does a cloud coverage of "FEW" mean in a METAR weather report?',
        answers: [
          "8 eighths",
          "1 to 2 eighths",
          "5 to 7 eighths",
          "3 to 4 eighths",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 61,
        question:
          'What does a cloud coverage of "SCT" mean in a METAR weather report?',
        answers: [
          "1 to 2 eighths",
          "8 eighths",
          "3 to 4 eighths",
          "5 to 7 eighths",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 62,
        question:
          'What does a cloud coverage of "BKN" mean in a METAR weather report?',
        answers: [
          "3 to 4 eighths",
          "8 eighths",
          "5 to 7 eighths",
          "1 to 2 eighths",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 63,
        question:
          "Given a visibility of 12 km, what is the correct way to transmit this visibility?",
        answers: [
          "One-two kilometers.",
          "Twelve kilometers.",
          "One-zero kilometers or more.",
          "One-zero kilometers.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 64,
        question: "In what case is visibility transmitted in meters?",
        answers: [
          "Up to 5 km",
          "Greater than 10 km",
          "Greater than 5 km",
          "Up to 10 km",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 65,
        question: "In what cases is visibility transmitted in kilometers?",
        answers: [
          "Up to 10 km",
          "Greater than 5 km",
          "Up to 5 km",
          "Greater than 10 km",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 66,
        question: "What information is broadcasted on a VOLMET frequency?",
        answers: [
          "Navigational information",
          "NOTAMS",
          "Current information",
          "Meteorological information",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 67,
        question:
          "Which navigation facility may be used for broadcasting the ATIS?",
        answers: ["GPS", "DME", "NDB", "VOR"],
        correct: 3,
        points: 1,
      },
      {
        num: 68,
        question:
          "How can you obtain meteorological information concerning airports during a crosscountry flight?",
        answers: ["VOLMET", "GAMET", "METAR", "AIRMET"],
        correct: 0,
        points: 1,
      },
      {
        num: 69,
        question: "Which transponder code indicates a radio failure?",
        answers: ["7700", "7600", "7500", "7000"],
        correct: 1,
        points: 1,
      },
      {
        num: 70,
        question: "What is the correct phrase to begin a blind transmission?",
        answers: ["Blind", "Transmitting blind", "No reception", "Listen"],
        correct: 1,
        points: 1,
      },
      {
        num: 71,
        question: "On what frequency shall a blind transmission be made?",
        answers: [
          "On the appropriate FIS frequency",
          "On a radar frequency of the lower airspace",
          "On the current frequency",
          "On a tower frequency",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 72,
        question: "How often shall a blind transmission be made?",
        answers: ["Two times", "Three times", "Four times", "One time"],
        correct: 3,
        points: 1,
      },
      {
        num: 73,
        question:
          "In what situation is it appropriate to set the transponder code 7600?",
        answers: [
          "Hijacking",
          "Emergency",
          "Flight into clouds",
          "Loss of radio",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 74,
        question:
          "What is the correct course of action when experiencing a radio failure in class D airspace?",
        answers: [
          "The flight has to be continued above 5000 feet complying with VFR flight rules or the airspace has to be left by the shortest route",
          "The flight has to be continued according to the last clearance complying with VFR rules or the airspace has to be left by the shortest route",
          "The flight has to be continued above 5000 feet complying with VFR flight rules or the airspace has to be left using a standard routing",
          "The flight has to be continued according to the last clearance complying with VFR flight rules or the airspace has to be left using a standard routing",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 75,
        question:
          "Under what conditions may class D airspace be entered with a radio failure?",
        answers: [
          "Approval has been granted before",
          "There are other aircraft in the aerodrome circuit",
          "It ist the aerodrome of departure",
          "It is the destination aerodrome",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 76,
        question:
          "Which phrase is to be repeated three times before transmitting an urgency message?",
        answers: ["Help", "Urgent", "Pan Pan", "Mayday"],
        correct: 2,
        points: 1,
      },
      {
        num: 77,
        question: "Urgency messages are defined as...",
        answers: [
          "messages concerning urgent spare parts which are needed for a continuation of flight and which need to be ordered in advance.",
          "messages concerning the safety of an aircraft, a watercraft or some other vehicle or person in sight.",
          "information concerning the apron personell and which imply an imminent danger to landing aircraft.",
          "messages concerning aircraft and their passengers which face a grave and imminent threat and require immediate assistance.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 78,
        question: "Distress messages contain...",
        answers: [
          "information concerning urgent spare parts which are required for a continuation of flight and which have to be ordered in advance.",
          "information concerning the apron personell and which imply an imminent danger to landing aircraft.",
          "information concerning the safety of an aircraft, a watercraft or some other vehicle or person in sight.",
          "information concerning aircraft and their passengers which face a grave and imminent threat and require immediate assistance.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 79,
        question:
          "What is the correct frequency for an initial distress message?",
        answers: [
          "Emergency frequency",
          "Current frequency",
          "FIS frequency",
          "Radar frequency",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 80,
        question:
          "What kind of information should be included in an urgency message?",
        answers: [
          "Nature of problem or observation, important information for support, departure aerodrome, information about position, heading and altitude",
          "Intended routing, important information for support, intentions of the pilot, information about position, departure aerodrome, heading and altitude",
          "Nature of problem or observation, important information for support, intentions of the pilot, information about position, heading and altitude",
          "Intended routing, important information for support, intentions of the pilot, departure aerodrome, destination aerodrome, heading and altitude",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 81,
        question: "The correct transponder code for emergencies is...",
        answers: ["7700.", "7000.", "7600.", "7500."],
        correct: 0,
        points: 1,
      },
      {
        num: 82,
        question:
          "Which of the following frequencies is designated for VHF voice communication?",
        answers: ["118.75 kHz", "327.25 MHz", "327.25 kHz", "118.75 MHz"],
        correct: 3,
        points: 1,
      },
      {
        num: 83,
        question:
          "Which of the following frequencies is designated for VHF voice communication?",
        answers: ["120.50 MHz", "115.15 MHz", "108.80 MHz", "117.30 kHz"],
        correct: 0,
        points: 1,
      },
      {
        num: 84,
        question:
          "What is the correct designation of the frequency band from 118.000 to 136.975 MHz used for voice communication?",
        answers: ["LF", "VHF", "MF", "HF"],
        correct: 1,
        points: 1,
      },
      {
        num: 85,
        question:
          "Which of the following factors affects the reception of VHF transmissions?",
        answers: [
          "Altitude",
          "Shoreline effect",
          "Twilight error",
          "Height of ionosphere",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 86,
        question:
          "What is the approximate speed of electromagnetic wave propagation?",
        answers: ["123000 m/s", "300000 km/s", "123000 km/s", "300000 m/s"],
        correct: 1,
        points: 1,
      },
      {
        num: 87,
        question: "An ATIS is valid for...",
        answers: ["10 minutes.", "45 minutes.", "60 minutes.", "30 minutes."],
        correct: 3,
        points: 1,
      },
    ],
  },
  {
    num: 51,
    group: "Principles of Flight",
    questions: [
      {
        num: 1,
        question:
          "Compared to trailing edge flaps, leading edge devices like Slots...",
        answers: [
          "reduce the critical angle of attack at a given speed.",
          "allow higher speeds at take-off and landing.",
          "produce less drag while allowing a higher angle of attack.",
          "increase the camber and allow a lower angle of attack.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 2,
        question:
          "Stabilization around the lateral axis during cruise is achieved by the...",
        answers: [
          "horizontal stabilizer.",
          "airlerons.",
          "wing flaps.",
          "vertical rudder.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 3,
        question:
          "Flying with speeds higher than the never-exceed-speed (vNE) may result in...",
        answers: [
          "too high total pressure resulting in an unusable airspeed indicator.",
          "flutter and mechanically damaging the wings.",
          "an increased lift-to-drag ratio and a better glide angle.",
          "reduced drag with increased control forces.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 4,
        question: "What effects typically result from propeller icing?",
        answers: [
          "Reduced power output, decreasing RPM.",
          "Increased power output, decreasing RPM.",
          "Increased power output, increasing RPM.",
          "Reduced power output, increasing RPM.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 5,
        question:
          "During a straight and steady climb, which force acts addionally, and in the same direction as the drag force, resulting in more power required for climb than for horizontal flight?",
        answers: [
          "A component of the weight force along the rearward flight path.",
          "The vertical component of the weight force.",
          "A component of the thrust along the rearward flightpath.",
          "A component of the lift force along the forward flightpath.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 6,
        question: "The static pressure of gases work...",
        answers: [
          "only vertical to the flow direction.",
          "only in the direction of the total pressure.",
          "in all directions.",
          "only in flow direction.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 7,
        question:
          "Bernoulli's equation for frictionless, incompressible gases states that...",
        answers: [
          "static pressure = total pressure + dynamic pressure.",
          "total pressure = dynamic pressure - static pressure.",
          "dynamic pressure = total pressure + static pressure.",
          "total pressure = dynamic pressure + static pressure.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 8,
        question:
          "If surrounded by airflow (v>0), any arbitrarily shaped body produces...",
        answers: [
          "lift without drag.",
          "drag and lift.",
          "constant drag at any speed.",
          "drag.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 9,
        question:
          "All aerodynamic forces can be considered to act on a single point. This point is called...",
        answers: [
          "center of gravity.",
          "center of pressure.",
          "lift point.",
          "transition point.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 10,
        question:
          "The center of pressure is the theoretical point of origin of...",
        answers: [
          "only the resulting total drag.",
          "all aerodynamic forces of the profile.",
          "gravity forces of the profile.",
          "gravity and aerodynamic forces.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 11,
        question: "Number 2 in the drawing corresponds to the...",
        answers: [
          "chord line.",
          "chord.",
          "angle of attack.",
          "profile thickness.",
        ],
        correct: 1,
        points: 1,
        figure: "PFA-010",
      },
      {
        num: 12,
        question: "Number 3 in the drawing corresponds to the...",
        answers: ["chord.", "thickness.", "camber line.", "chord line."],
        correct: 2,
        points: 1,
        figure: "PFA-010",
      },
      {
        num: 13,
        question: "The angle of attack is the angle between...",
        answers: [
          "the undisturbed airflow and the longitudinal axis of an aeroplane.",
          "the chord line and the longitudinal axis of an aeroplane.",
          "the chord line and the oncoming airflow.",
          "the wing and the fuselage of an aeroplane.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 14,
        question:
          "The ratio of span and mean chord length is referred to as...",
        answers: [
          "trapezium shape.",
          "tapering.",
          "aspect ratio.",
          "wing sweep.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 15,
        question: "Which point on the aerofoil is represented by number 3?",
        answers: [
          "Separation point",
          "Center of pressure",
          "Stagnation point",
          "Transition point",
        ],
        correct: 3,
        points: 1,
        figure: "PFA-009",
      },
      {
        num: 16,
        question: "Which point on the aerofoil is represented by number 4?",
        answers: [
          "Center of pressure",
          "Separation point",
          "Transition point",
          "Stagnation point",
        ],
        correct: 1,
        points: 1,
        figure: "PFA-009",
      },
      {
        num: 17,
        question:
          "Wing tip vortex development begins during which phase of flight?",
        answers: [
          "As soon as the aircraft starts moving",
          "While setting take-off power during take-off run",
          "While setting flaps to lower position",
          "When lift is being generated during rotation",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 18,
        question: "Which point on the aerofoil is represented by number 1?",
        answers: [
          "Transition point",
          "Stagnation point",
          "Center of pressure",
          "Separation point",
        ],
        correct: 1,
        points: 1,
        figure: "PFA-009",
      },
      {
        num: 19,
        question: "What pattern can be found at the stagnation point?",
        answers: [
          "The boundary layer starts separating on the upper surface of the profile",
          "The laminar boundary layer changes into a turbulent boundary layer",
          "All aerodynamic forces can be considered as attacking at this single point",
          "Streamlines are divided into airflow above and below the profile",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 20,
        question:
          "What pressure pattern can be observed at a lift-generating wing profile at positive angle of attack?",
        answers: [
          "Low pressure is created above, higher pressure below the profile",
          "High pressure is created above, lower pressure below the profile",
          "Pressure above remains unchanged, higher pressure is created below the profile",
          "Pressure below remains unchanged, lower pressure is created above the profile",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 21,
        question:
          "The position of the the center of pressure at a positively shaped profile...",
        answers: [
          "moves to the leading edge while the angle of attack becomes smaller.",
          "is located at approximately 25% of the chord, measured from the leading edge.",
          "moves to the trailing edge while the angle of attack becomes smaller.",
          "does not move since it is independent of the angle of attack.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 22,
        question:
          "In which way does the position of the center of pressure move at a positively shaped profile with increasing angle of attack?",
        answers: [
          "It moves backward until reaching the critical angle of attack",
          "It moves forward first, then backward",
          "It moves forward until reaching the critical angle of attack",
          "It moves to the wing tips",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 23,
        question: "Which statement about lift and angle of attack is correct?",
        answers: [
          "Too large angles of attack can lead to an exponential increase in lift",
          "Increasing the angle of attack results in less lift being generated by the aerofoil",
          "Increasing the angle of attack too far may result in a loss of lift and an airflow separation",
          "Decreasing the angle of attack results in more drag being generated by the aerofoil",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 24,
        question:
          "Which statement about the airflow around an aerofoil is correct if the angle of attack increases?",
        answers: [
          "The stagnation point moves down",
          "The center of pressure moves down",
          "The center of pressure moves up",
          "The stagnation point moves up",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 25,
        question:
          "Which statement about the airflow around an aerofoil is correct if the angle of attack decreases?",
        answers: [
          "The center of pressure moves aft",
          "The stagnation point remains constant",
          "The stagnation point moves down",
          "The center of pressure moves forward",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 26,
        question:
          "The angle (alpha) shown in the figure is referred to as...\nDoF: direction of airflow",
        answers: [
          "lift angle.",
          "angle of incidence.",
          "angle of inclination.",
          "angle of attack.",
        ],
        correct: 3,
        points: 1,
        figure: "PFA-003",
      },
      {
        num: 27,
        question:
          "In order to improve the stall characteristics of an aircraft, the wing is twisted outwards (the angle of incidence varies spanwise). This is known as...",
        answers: [
          "aerodynamic washout.",
          "arrow shape.",
          "V-form.",
          "geometric washout.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 28,
        question: "Which option states a benefit of wing washout?",
        answers: [
          "Structurally the wing is made more rigid against rotation",
          "With the washout the form drag reduces at high speeds",
          "Greater hardness because the wing can withstand more torsion forces",
          "At high angles of attack the effectiveness of the aileron is retained as long as possible",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 29,
        question: "Which statement concerning the angle of attack is correct?",
        answers: [
          "The angle of attack cannot be negative",
          "Increasing the angle of attack results in decreasing lift",
          "The angle of attack is constant throughout the flight",
          "A too large angle of attack may result in a loss of lift",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 30,
        question:
          "When increasing the airflow speed by a factor of 2 while keeping all other parameters constant, how does the parasite drag change approximately?",
        answers: [
          "It decreases by a factor of 2",
          "It increases by a factor of 2",
          "It decreases by a factor of 4",
          "It increases by a factor of 4",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 31,
        question: "The drag coefficient...",
        answers: [
          "increases with increasing airspeed.",
          "is proportional to the lift coefficient.",
          "cannot be lower than a non-negative, minimal value.",
          "may range from zero to an infinite positive value.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 32,
        question: "Pressure compensation on an wing occurs at the...",
        answers: [
          "wing roots.",
          "wing tips.",
          "trailing edge.",
          "leading edge.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 33,
        question:
          "Which of the following options is likely to produce large induced drag?",
        answers: [
          "Large aspect ratio",
          "Tapered wings",
          "Small aspect ratio",
          "Low lift coefficients",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 34,
        question:
          "Which parts of an aircraft mainly affect the generation of induced drag?",
        answers: [
          "the front part of the fuselage.",
          "the wing tips.",
          "the lower part of the gear.",
          "the outer part of the ailerons.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 35,
        question: "Where is interference drag generated?",
        answers: [
          "At the wing root",
          "At the ailerons",
          "At the the gear",
          "Near the wing tips",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 36,
        question: "Which curve represents the induced drag?",
        answers: ["1", "4", "2", "3"],
        correct: 0,
        points: 1,
        figure: "PFA-011",
      },
      {
        num: 37,
        question:
          "Pressure drag, interference drag and friction drag belong to the group of the...",
        answers: [
          "induced drag.",
          "parasite drag.",
          "main resistance.",
          "total drag.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 38,
        question: "What kind of drag is NOT part of the parasite drag?",
        answers: [
          "Interference drag",
          "Skin-friction drag",
          "Induced drag",
          "Form drag",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 39,
        question:
          "How do induced drag and parasite drag change with increasing airspeed during a horizontal and stable cruise flight?",
        answers: [
          "Induced drag decreases and parasite drag increases",
          "Parasite drag decreases and induced drag increases",
          "Parasite drag decreases and induced drag decreases",
          "Induced drag increases and parasite drag increases",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 40,
        question:
          "Which of the listed wing shapes has the lowest induced drag?",
        answers: [
          "Elliptical shape",
          "Double trapezoidal shape",
          "Rectangular shape",
          "Trapezoidal shape",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 41,
        question:
          "Which effect does a decreasing airspeed have on the induced drag during a horizontal and stable cruise flight?",
        answers: [
          "The induced drag will increase",
          "The induced drag will collapse",
          "The induced drag will remain constant",
          "The induced drag will slightly decrease",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 42,
        question:
          "Which statement about induced drag during the horizontal cruise flight is correct?",
        answers: [
          "Induced drag has a minimum at a certain speed and increases at higher as well as lower speeds",
          "Induced drag has a maximum at a certain speed and decreases at higher as well as lower speeds",
          "Induced drag increases with increasing airspeed",
          "Induced drag decreases with increasing airspeed",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 43,
        question:
          "In which mentioned situation is the total drag at its minimum?",
        answers: [
          "Induced drag is twice as much as parasite drag",
          "Parasite drag is twice as much as induced drag",
          "Parasite drag is equal to induced drag",
          "Induced drag is smaller than parasite drag",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 44,
        question: "Which kinds of drag contribute to total drag?",
        answers: [
          "Form drag, skin-friction drag, interference drag",
          "Interference drag and parasite drag",
          "Induced drag, form drag, skin-friction drag",
          "Induced drag and parasite drag",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 45,
        question:
          "How do lift and drag change when approaching a stall condition?",
        answers: [
          "Lift and drag decrease",
          "Lift and drag increase",
          "Lift decreases and drag increases",
          "Lift increases and drag decreases",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 46,
        question: "In case of a stall it is important to...",
        answers: [
          "increase the bank angle and reduce the speed.",
          "increase the angle of attack and increase the speed.",
          "decrease the angle of attack and increase the speed.",
          "increase the angle of attack and reduce the speed.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 47,
        question: "During a stall, the lift...",
        answers: [
          "decreases and drag increases.",
          "increases and drag decreases.",
          "increases and drag increases.",
          "decreases and drag decreases.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 48,
        question: "The critical angle of attack...",
        answers: [
          "changes with increasing weight.",
          "decreases with forward center of gravity position.",
          "is independent of the weight.",
          "increases with backward center of gravity position.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 49,
        question: "What leads to a decreased stall speed Vs (IAS)?",
        answers: [
          "Lower altitude",
          "Lower density",
          "Higher load factor",
          "Decreasing weight",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 50,
        question:
          "The stall warning will be activated just before reaching which speed?",
        answers: ["VNE", "VS", "VX", "VR"],
        correct: 1,
        points: 1,
      },
      {
        num: 51,
        question:
          "In motorplanes the stall warning is usually activated by a change of...",
        answers: [
          "the center of gravity.",
          "the transition point.",
          "the center of pressure.",
          "the stagnation point.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 52,
        question: "How should the pilot react to an engaged stall warning?",
        answers: [
          "Pull the elevator, increase power",
          "Pull the elevator, decrease power",
          "Push the elevator, increase power",
          "Raise the nose to decrease airspeed",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 53,
        question: "Which statement regarding a spin is correct?",
        answers: [
          "During recovery the ailerons should be kept neutral",
          "Only very old aeroplanes have a risk of spinning",
          "During recovery the ailerons should be crossed",
          "During the spin the speed constantly increases",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 54,
        question:
          "When extending the flaps for landing at constant angle of attack, in which way does the lift coefficient change far before reaching the maximum lift coefficient?",
        answers: [
          "It decreases",
          "It is not possible to define",
          "It increases",
          "It remains constant",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 55,
        question:
          "With regard to flaps, which of the following options provides a lift-increasing effect?",
        answers: [
          "Decreasing the angle of attack",
          "Increasing the aerofoil camber",
          "Decreasing the form drag",
          "Lowering the induced drag",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 56,
        question: "Which factor can be changed by deploying flaps for landing?",
        answers: [
          "The position of the center of gravity",
          "The effectiveness of the ailerons",
          "The twist effect of the engine",
          "The trim condition",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 57,
        question: "What is the principle of a Fowler flap?",
        answers: [
          "A profile-like flap is extended from the trailing edge of the wing",
          "A flap from the rear bottom side of the wing is folded down",
          "At high angles of attack a part of the leading edge lifts",
          "The rear part of the wing is folded down",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 58,
        question: "A take-off with flaps in take-off position causes...",
        answers: [
          "an increased rate of climb.",
          "an increased acceleration.",
          "a shortening of the take-off run.",
          "a decrease in drag.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 59,
        question:
          "Provided that no other procedure is described in the Aircraft Operating Handbook, after increasing the engine power in a go-around, the flaps may...",
        answers: [
          "not be operated up to the minimum safe altitude.",
          "be retracted to a middle position.",
          "be fully retracted without any delay.",
          "remain fully extended until reaching the traffic pattern.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 60,
        question:
          "How do lift and drag change when setting flaps to a lower position?",
        answers: [
          "Lift increases, drag decreases",
          "Lift increases, drag increases",
          "Lift decreases, drag decreases",
          "Lift decreases, drag increases",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 61,
        question:
          "The laminar boundary layer on the aerofoil is located between...",
        answers: [
          "the transition point and the separation point.",
          "the stagnation point and the transition point.",
          "the transition point and the center of pressure.",
          "the stagnation point and the center of pressure.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 62,
        question: "What types of boundary layers can be found on an aerofoil?",
        answers: [
          "Laminar layer at the leading wing areas, turbulent boundary layer at the trailing areas",
          "Turbulent boundary layer along the complete upper surface with separated airflow",
          "Turbulent layer at the leading wing areas, laminar boundary layer at the trailing areas",
          "Laminar boundary layer along the complete upper surface with non-separated airflow",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 63,
        question:
          "How does a laminar boundary layer differ from a turbulent boundary layer?",
        answers: [
          "The laminar boundary layer produces lift, the turbulent boundary layer produces drag",
          "The turbulent boundary layer is thicker and provides less skin-friction drag",
          "The laminar boundary layer is thinner and provides more skin-friction drag",
          "The turbulent boundary layer can follow the airfoil camber at higher angles of attack",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 64,
        question:
          "What structural item provides lateral stability to an airplane?",
        answers: [
          "Differential aileron deflection",
          "Wing dihedral",
          "Vertical tail",
          "Elevator",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 65,
        question: "Which statement describes a situation of static stability?",
        answers: [
          "An aircraft distorted by external impact will tend to an even more deflected position",
          "An aircraft distorted by external impact will return to the original position",
          "An aircraft distorted by external impact can return to its original position by rudder input",
          "An aircraft distorted by external impact will maintain the deflected position",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 66,
        question: "Which constructive feature is shown in the figure?\nL: Lift",
        answers: [
          "Longitudinal stability by wing dihedral",
          "Lateral stability by wing dihedral",
          "Differential aileron deflection",
          "Directional stability by lift generation",
        ],
        correct: 1,
        points: 1,
        figure: "PFA-006",
      },
      {
        num: 67,
        question:
          '"Longitudinal stability" is referred to as stability around which axis?',
        answers: [
          "Propeller axis",
          "Vertical axis",
          "Longitudinal axis",
          "Lateral axis",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 68,
        question:
          "Stability around which axis is mainly influenced by the center of gravity's longitudinal position?",
        answers: [
          "Vertical axis",
          "Longitudinal axis",
          "Gravity axis",
          "Lateral axis",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 69,
        question:
          "What structural item provides directional stability to an airplane?",
        answers: [
          "Differential aileron deflection",
          "Large vertical tail",
          "Wing dihedral",
          "Large elevator",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 70,
        question: "Rotation around the vertical axis is called...",
        answers: ["rolling.", "pitching.", "yawing.", "slipping."],
        correct: 2,
        points: 1,
      },
      {
        num: 71,
        question: "Rotation around the lateral axis is called...",
        answers: ["rolling.", "stalling.", "yawing.", "pitching."],
        correct: 3,
        points: 1,
      },
      {
        num: 72,
        question: "The critical angle of attack...",
        answers: [
          "increases with a front centre of gravity.",
          "decreases with a rear centre of gravity.",
          "is changed by different aircraft weights.",
          "is not changed by different aircraft weights.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 73,
        question:
          "In straight and level flight with constant performance of the engine, the angle of attack at the wing is...",
        answers: [
          "greater than in a climb.",
          "greater than at take-off.",
          "smaller than in a descent.",
          "smaller than in a climb.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 74,
        question:
          "What is the function of the horizontal tail (among other things)?",
        answers: [
          "To stabilise the aeroplane around the lateral axis",
          "To initiate a curve around the vertical axis",
          "To stabilise the aeroplane around the longitudinal axis",
          "To stabilise the aeroplane around the vertical axis",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 75,
        question: "The elevator deflection during take-off rotation...",
        answers: [
          "is increased for a front centre of gravity.",
          "is increased for a rear centre of gravity.",
          "is increased at high speeds.",
          "is independent of the speed.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 76,
        question: "The elevator moves an aeroplane around the...",
        answers: [
          "lateral axis.",
          "elevator axis.",
          "longitudinal axis.",
          "vertical axis.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 77,
        question:
          "What has to be considered with regard to the center of gravity position?",
        answers: [
          "The center of gravity's position can only be determined during flight.",
          "Only correct loading can assure a correct and safe center of gravity position.",
          "By moving the elevator trim tab, the center of gravity can be shifted into a correct position.",
          "By moving the aileron trim tab, the center of gravity can be shifted into a correct position.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 78,
        question:
          "Rudder deflections result in a turn of the aeroplane around the...",
        answers: [
          "rudder axis.",
          "lateral axis.",
          "vertical axis.",
          "longitudinal axis.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 79,
        question: "Deflecting the rudder to the left causes...",
        answers: [
          "pitching of the aircraft to the right.",
          "yawing of the aircraft to the right.",
          "yawing of the aircraft to the left.",
          "pitching of the aircraft to the left.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 80,
        question: "What is the advantage of differential aileron movement?",
        answers: [
          "The ratio of the drag coefficient to lift coefficient is increased",
          "The adverse yaw is higher",
          "The drag of the downwards deflected aileron is lowered and the adverse yaw is smaller",
          "The total lift remains constant during aileron deflection",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 81,
        question: "Which design feature can compensate for adverse yaw?",
        answers: [
          "Wing dihedral",
          "Full deflection of the aileron",
          "Aileron trim",
          "Differential aileron defletion",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 82,
        question: "Differential aileron deflection is used to...",
        answers: [
          "keep the adverse yaw low.",
          "avoid a stall at low angles of attack.",
          "increase the rate of descent.",
          "reduce wake turbulence.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 83,
        question:
          "The right aileron deflects upwards, the left downwards. How does the aircraft react?",
        answers: [
          "Rolling to the left, no yawing",
          "Rolling to the right, yawing to the right",
          "Rolling to the right, yawing to the left",
          "Rolling to the left, yawing to the right",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 84,
        question: "The aerodynamic rudder balance...",
        answers: [
          "improves the rudder effectiveness.",
          "reduces the control surfaces.",
          "reduces the control stick forces.",
          "delays the stall.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 85,
        question:
          "Which constructive feature has the purpose to reduce stearing forces?",
        answers: [
          "T-tail",
          "Vortex generators",
          "Differential aileron deflection",
          "Aerodynamic rudder balance",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 86,
        question: "What is the function of the static rudder balance?",
        answers: [
          "To trim the controls almost without any force",
          "To increase the control stick forces",
          "To limit the control stick forces",
          "To prevent control surface flutter",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 87,
        question:
          "During cruise flight with constant power setting, an aircraft shows a permanent tendency to raise the nose. How can this tendency be eliminated?",
        answers: [
          "By deflecting the elevator trim tab upwards",
          "By shifting the center of gravity backwards",
          "By elevator deflection upwards",
          "By deflecting the elevator trim tab downwards",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 88,
        question:
          "The trim tab at the elevator is defelected upwards. In which position is the corresponding indicator?",
        answers: [
          "Neutral position",
          "Nose-up position",
          "Nose-down position",
          "Laterally trimmed",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 89,
        question: 'What describes "wing loading"?',
        answers: [
          "Drag per wing area",
          "Drag per weight",
          "Wing area per weight",
          "Weight per wing area",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 90,
        question:
          "Through which factor listed below does the load factor increase during cruise flight?",
        answers: [
          "A forward centre of gravity",
          "Higher aeroplane weight",
          "An upward gust",
          "Lower air density",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 91,
        question:
          'Which statement regarding the "constant-speed propeller" is correct?',
        answers: [
          "The propeller keeps the airspeed constant",
          "The pitch of the propeller rises with higher speeds",
          "The RPM decreases with lower speeds",
          "The set RPM is kept constant by the motor power (MAP)",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 92,
        question:
          "The change in pitch at a propeller blade from the root to the tip ensures...",
        answers: [
          "that the most thrust is produced at the blade tip.",
          "that the most thrust is produced at the blade root.",
          "a nearly constant load by a constant effective angle of attack over the entire length of the blade.",
          "the largest possible angle of attack at the blade tip.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 93,
        question: "After an engine failure, the windmilling propeller...",
        answers: [
          "generates neither thrust nor drag.",
          "has a greater pitch in feathered position.",
          "generates drag rather than thrust.",
          "improves the properties of the glide.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 94,
        question:
          "During a descent at idle power with constant speed, the propeller lever is moved backwards. How do the propeller pitch and sink rate change?",
        answers: [
          "Propeller pitch is increased, sink rate is increased",
          "Propeller pitch is increased, sink rate is decreased",
          "Propeller pitch is decreased, sink rate is increased",
          "Propeller pitch is decreased, sink rate is decreased",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 95,
        question: "Point number 1 in the figure indicates which flight state?",
        answers: [
          "Stall",
          "Inverted flight",
          "Best gliding angle",
          "Slow flight",
        ],
        correct: 1,
        points: 1,
        figure: "PFA-008",
      },
      {
        num: 96,
        question: "Point number 5 in the figure indicates which flight state?",
        answers: [
          "Best gliding angle",
          "Slow flight",
          "Stall",
          "Inverted flight",
        ],
        correct: 1,
        points: 1,
        figure: "PFA-008",
      },
      {
        num: 97,
        question:
          "The bank in a two-minute turn (rate one turn) depends on the...",
        answers: ["weight.", "wind.", "load factor.", "TAS."],
        correct: 3,
        points: 1,
      },
      {
        num: 98,
        question:
          "In a co-ordinated turn, how is the relation between the load factor (n) and the stall speed (Vs)?",
        answers: [
          "n is smaller than 1, Vs is greater than in straight and level flight.",
          "n is greater than 1, Vs is greater than in straight and level flight.",
          "n is smaller than 1, Vs is smaller than in straight and level flight.",
          "n is greater than 1, Vs is smaller than in straight and level flight.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 99,
        question: "How is the balance of forces affected during a turn?",
        answers: [
          "Lift force must be increased to compensate for the sum of centrifugal and gravitational force",
          "The net force results from superposition of gravity and centripetal forces",
          "The horizontal component of the lift force during a turn is the centrifugal force",
          "A lower lift force compensates for a lower net force as compared to level flight",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 100,
        question:
          "The pressure compensation between wind upper and lower surface results in ...",
        answers: [
          "laminar airflow by wing tip vortices.",
          "profile drag by wing tip vortices.",
          "induced drag by wing tip vortices.",
          "lift by wing tip vortices.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 101,
        question: 'What is meant by "ground effect"?',
        answers: [
          "Increase of lift and increase of induced drag close to the ground",
          "Decrease of lift and increase of induced drag close to the ground",
          "Decrease of lift and decrease of induced drag close to the ground",
          "Increase of lift and decrease of induced drag close to the ground",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 102,
        question: "What is the diffeence between spin and spiral dive?",
        answers: [
          "Spin: stall at outer wing, speed constant;\nSpiral dive: airflow at both wings, speed increasing rapidly",
          "Spin: stall at inner wing, speed constant;\nSpiral dive: airflow at both wings, speed increasing rapidly",
          "Spin: stall at outer wing, speed increasing rapidly;\nSpiral dive: airflow at both wings, speed constant",
          "Spin: stall at inner wing, speed increasing rapidly;\nSpiral dive: airflow at both wings, speed constant",
        ],
        correct: 1,
        points: 1,
      },
    ],
  },
  {
    num: 60,
    group: "Operational Procedures",
    questions: [
      {
        num: 1,
        question: 'The term "flight time" is defined as...',
        answers: [
          "the period from the start of the take-off run to the final touchdown when landing.",
          "the total time from the first aircraft movement until the moment it finally comes to rest at the end of the flight.",
          "the period from engine start for the purpose of taking off to leaving the aircraft after engine shutdown.",
          "the total time from the first take-off until the last landing in conjunction with one or more consecutive flights.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 2,
        question:
          "What should be considered regarding a scheduled flight over water, when land cannot be reached in case of an emergency landing?",
        answers: [
          "Transponder code 7600 has to be set during the whole flight",
          "Contact to the nearest ATC shall consist during the whole flight",
          "For all passengers there must be life jackets or lifeboats present",
          "The flight plan has to contain the exact waypoints",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 3,
        question:
          "Which of the items below may have an influence on the noise perceived by a person on the ground?\n1) Engine power setting\n2) Propeller revolutions per minute\n3) Position of the landing gear\n4) Flap position\n5) Flight track\n6) Height above ground\n7) Flight rules",
        answers: ["1, 2, 3, 4, 5, 6", "1, 5, 6", "1, 5, 6, 7", "3, 4, 5, 6, 7"],
        correct: 0,
        points: 1,
      },
      {
        num: 4,
        question:
          "How can the pilot of an an engine-driven aircraft minimise the noise emission during descent and approach?",
        answers: [
          "Normal approach with normal power setting, configuration prior to initiating descent, shortest possible arrival route",
          "Descent and approach in landing configuration while maintaining a descent angle of 3°, direct approach whenever possible",
          "Low approach with minimum power setting, late configuration and steep approach, adherence to established arrival routes",
          "High approach with minimum power setting, late descent, late configuration, adherence to established arrival routes",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 5,
        question:
          "When should turns at low altitudes above villages be avoided with regard to noise abatement procedures?",
        answers: [
          "In descent",
          "In climb",
          "During the approach",
          "In horizontal flight",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 6,
        question: "How should departures near villages be carried out?",
        answers: [
          "Slow with a low propeller rotation speed",
          "Low and fast between the villages",
          "Climb and changes in direction should be done as slow as possible",
          "Villages should be circumnavigated and crossed in a sufficient altitude",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 7,
        question: "Which kind of engine is susceptible to carburettor fire?",
        answers: [
          "Piston engines",
          "Turbine engines",
          "Electric motors",
          "Turboprop engines",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 8,
        question:
          "Why is it dangerous to pump the throttle for engine start in cold weather?",
        answers: [
          "It may cause a carburettor fire",
          "Carburettor icing can occur",
          "The engine might start with not enough power",
          "The oil will become diluted",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 9,
        question: "Which danger exists during engine start?",
        answers: [
          "Cable fire",
          "Carburettor fire",
          "Sparking",
          "Smoke emission",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 10,
        question: "May an engine, which previously was on fire, be restarted?",
        answers: [
          "No, the risk of a reignition of the fire would be too high",
          "Yes, if the aircraft is flying at a safe altitude",
          "Yes, but only on the ground for the purpose of taxiing",
          "Yes, but only if the cause of the fire was a carburettor fire during engine start",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 11,
        question: "Which gas is most dangerous during an engine fire?",
        answers: ["Carbon monoxide", "Nitrogen", "Oxygen", "Carbon dioxide"],
        correct: 0,
        points: 1,
      },
      {
        num: 12,
        question:
          "Smoke enters the cockpit during an engine fire.\nWhich actions have to be taken immediately?",
        answers: [
          "Switching off the master switch and the ignition",
          "Switching off the heating and the ventilating system",
          "Switching off the pitot and front window heating",
          "Switching off the avionics and the cabin lighting",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 13,
        question:
          "What should be the first action in case of a cable fire during a flight?",
        answers: [
          "Close the fuel valve",
          "Open the windows",
          "Turn off the master switch",
          "Open cabin ventialtion",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 14,
        question:
          "What extinguishing agent is the least suitable for an aircraft fire?",
        answers: ["Powder", "Halon", "Water", "Foam"],
        correct: 2,
        points: 1,
      },
      {
        num: 15,
        question:
          "In flight, a little smoke emerges from behind the instrument panel. An electrical fire is suspected.\nWhich action, with respect to the pilot's operating manual, should be taken?",
        answers: [
          "Turn off the heat",
          "Shut down the engine",
          "Turn off the master switch",
          "Use the fire extinguisher",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 16,
        question:
          "In flight, a little smoke emerges from behind the instrument panel. An engine fire is suspected.\nWhich action, with respect to the pilot's operating manual, should be taken?",
        answers: [
          "Turn off the heat",
          "Shut down the engine",
          "Use the fire extinguisher",
          "Turn off the master switch",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 17,
        question: "A wind shear is...",
        answers: [
          "a slow increase of the wind speed in altitudes above 13000 ft.",
          "a wind speed change of more than 15 kt.",
          "a vertical or horizontal change of wind speed and wind direction.",
          "a meteorological downslope wind phenomenon in the alps.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 18,
        question:
          "Which weather phenomenon is typically associated with wind shear?",
        answers: [
          "Fog.",
          "Invernal warm front.",
          "Thunderstorms.",
          "Stable high pressure areas.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 19,
        question: "When do you expect wind shear?",
        answers: [
          "During an inversion",
          "In calm wind in cold weather",
          "During a summer day with calm winds",
          "When passing a warm front",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 20,
        question:
          "During an approach the aeroplane experiences a windshear with a decreasing headwind.\nIf the pilot does not make any corrections, how do the approach path and the indicated airspeed (IAS) change?",
        answers: [
          "Path is higher, IAS decreases",
          "Path is lower, IAS increases",
          "Path is higher, IAS increases",
          "Path is lower, IAS decreases",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 21,
        question:
          "During an approach the aeroplane experiences a windshear with an increasing headwind.\nIf the pilot does not make any corrections, how do the approach path and the indicated airspeed (IAS) change?",
        answers: [
          "Path is higher, IAS decreases",
          "Path is lower, IAS increases",
          "Path is higher, IAS increases",
          "Path is lower, IAS decreases",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 22,
        question:
          "During an approach the aeroplane experiences a windshear with a decreasing tailwind.\nIf the pilot does not make any corrections, how do the approach path and the indicated airspeed (IAS) change?",
        answers: [
          "Path is higher, IAS decreases",
          "Path is higher, IAS increases",
          "Path is lower, IAS decreases",
          "Path is lower, IAS increases",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 23,
        question:
          "After take-off an aeroplane gets into a wind shear with decreasing headwind. As a result...",
        answers: [
          "the aeroplane flies above the estimated climb path.",
          "the true airspeed (TAS) will increase.",
          "the ground speed (GS) will decrease.",
          "the aeroplane flies below the estimated climb path.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 24,
        question: "How may windshear be recognised in flight?",
        answers: [
          "Sudden and apparently baseless change in altitude, airspeed, rate of climb or descent.",
          "Sudden and apparently baseless change in heading, turning rate, engine speed or oil temperature.",
          "Sudden and apparently baseless change of oilpressure, oil temperature, engine speed and altitude.",
          "Rather unexpected onset of drizzle associated with high stratus clouds following previously clear skies and calm conditions.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 25,
        question:
          "Which phenomenon may be expected at point 2 of the microburst?",
        answers: [
          "Constant rate of descent",
          "Decrease of descent rate",
          "Increase of airspeed",
          "Decrease of climb rate",
        ],
        correct: 3,
        points: 1,
        figure: "OPR-001",
      },
      {
        num: 26,
        question:
          "Which phenomenon may be expected at point 3 of the miroburst?",
        answers: [
          "Decrease in airspeed",
          "Constant rate of descent",
          "Increase in airspeed",
          "Decrease in climb rate",
        ],
        correct: 0,
        points: 1,
        figure: "OPR-001",
      },
      {
        num: 27,
        question: "How can a wind shear encounter in flight be avoided?",
        answers: [
          "Avoid take-off and landing during the passage of heavy showers or thunderstorms",
          "Avoid areas of precipitation, particularly during winter, and choose low flight altitudes",
          "Avoid take-offs and landings in mountainous terrain and stay in flat country whenever possible",
          "Avoid thermally active areas, particularly during summer, or stay below these areas",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 28,
        question:
          "What action should be taken when entering a windshear?\n1. Reduce speed\n2. Retract gear and flaps\n3. Maintain current configuration\n4. Adjust thrust",
        answers: ["2 and 4", "1 and 2", "1 and 3", "3 and 4"],
        correct: 3,
        points: 1,
      },
      {
        num: 29,
        question:
          "After lift-off, a much stronger than expected airspeed rise is experienced during the initial climb phase.\nWhat may be expected if the aeroplane entered a microburst?",
        answers: [
          "An increased climb rate and decreased airspeed",
          "An increased climb rate and airspeed",
          "A decreased climb rate and airspeed",
          "A decreased climb rate and increased airspeed",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 30,
        question:
          "Immediately after lift-off, a microburst is entered inadvertently.\nWhich action might avoid an unintentional descent?",
        answers: [
          "Set maximum power, maintain present aircraft configuration, climb at the best rate of climb speed",
          "Set maximum power, retract landing gear and flaps, pick up speed and perform a left or a right turn in an attempt to leave the area of the microburst on the shortest way",
          "Set maximum power, retract landing gear and flaps, increase pitch until attaining optimum climb speed",
          "Set maximum power, maintain present aircraft configuration, pick up speed in an attempt to leave the area of the microburst as fast as possible",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 31,
        question:
          "Wake turbulences develop during take-off just as the aeroplane...",
        answers: [
          "reaches an altitude of 15 ft.",
          "lifts off with the main gear.",
          "lifts off with the front gear.",
          "accelerates.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 32,
        question: "Wake turbulence is caused by...",
        answers: [
          "wind shear at the wingtip of an aerofoil.",
          "jet blast behind a turbine engine.",
          "turbulence at the downwind side of a mountain range.",
          "pressure compensation at the wingtip of an aerofoil.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 33,
        question: "Wake turbulence is particularly strong...",
        answers: [
          "when flying at high speeds.",
          "when flying at high altitudes.",
          "when flying at low speeds.",
          "when flying at low altitudes.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 34,
        question: "Wake turbulence is particularly strong...",
        answers: [
          "at a high aeroplane weight.",
          "when flying with high thrust.",
          "when flying with low thrust.",
          "at a low aeroplane weight.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 35,
        question: "The intensity of wake turbulence depends on...",
        answers: [
          "the propeller pitch.",
          "the temperature.",
          "the weight of the aeroplane.",
          "the altitude of the aeroplane.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 36,
        question:
          "Two aircraft of the same type, same grossweight and same configuration fly at different airspeeds.\nWhich aircraft will cause more severe wake turbulence?",
        answers: [
          "The aircraft flying at lower altitude.",
          "The aircraft flying at slower speed.",
          "The aircraft flying at higher speed.",
          "The aircraft flying at higher altitude.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 37,
        question:
          "Two aircraft of the same type, same gross weight and same flap configuration fly with different speeds and altitude.\nWhich aircraft will cause more wake turbulence?",
        answers: [
          "The aircraft flying at a higher altitude",
          "The aircraft flying at a lower altitude",
          "The aircraft flying at higher speed.",
          "The aircraft flying at lower speed.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 38,
        question:
          "With only a slight crosswind, what is the danger at take-off after the departure of a heavy aeroplane?",
        answers: [
          "Wake turbulence on or near the runway.",
          "Wake turbulence is amplified and distorted.",
          "Wake turbulence rotate faster and higher.",
          "Wake turbulence twisting transverse to the runway.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 39,
        question:
          'A light aircraft intends to land behind a commercial airliner belonging to wake turbulence category "medium" or "heavy" on a long runway.\nHow can the wake turbulence of the commercial aircraft be avoided?',
        answers: [
          "By making a steep approach and a long landing, touching down behind the touchdown point of the airliner's nose gear",
          "By making a steep approach and a very short landing. The light aircraft should be able to stop before reaching the airliner's touchdown point",
          "By making a shallow approach and a long landing, touching down behind the touchdown point of the airliner's nose gear",
          "By making a shallow approach and a very short landing. The light aircraft should be able to stop before reaching the airliner's touchdown point",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 40,
        question:
          "What should be observed when taxiing behind a commercial airliner?",
        answers: [
          "To avoid wake turbulence, a minimum distance of 700 m should be maintained",
          "To avoid the jet blast, a minimum distance of 600 m should be maintained",
          "To avoid wake turbulence, a minimum distance of 300 m should be maintained",
          "To avoid the jet blast, a minimum distance of 200 m should be maintained",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 41,
        question:
          "A technical fault requires an emergency off-field landing.\nWhich steps, with respect to the pilot's operating handbook, are necessary?",
        answers: [
          "Inform air traffic control and request technical support, check the pilot's operating handbook for a reference on the matter and execute an emergency landing.",
          "Reduce speed and configure the aircraft for landing in an attempt to gain time, declare an emergency, complete the applicable emergency procedure and execute an emergency landing.",
          "Locate a suitable landing area, plan the approach, complete the applicable emergency procedure, declare an emergency, start and concentrate on the approach in due time.",
          "Declare an emergency, complete the applicable emergency procedure, cover as much distance towards the airport as possible to be closer to the rescue forces.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 42,
        question:
          "Which method is appropriate to approach an unprepared landing field?",
        answers: [
          "Flat direct approach to ensure visual ground contact",
          "Steep approach to keep the noise level low",
          "Fast approach, performance check, and landing within a quick stop",
          "Terrain investigation to determine approach points and landing",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 43,
        question: "Which area is suitable for an off-field landing?",
        answers: [
          "Harvested cornfield",
          "Glade with long dry grass",
          "Plowed field",
          "Sports area in a village",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 44,
        question: "An emergency landing is a landing...",
        answers: [
          "conducted without power from the engine.",
          "conducted in an attempt to keep up safety regarding an aircraft and its occupants.",
          "conducted with the flaps retracted.",
          "conducted in response to circumstances forcing the aircraft to land.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 45,
        question:
          "A landing conducted in response to circumstances forcing the aircraft to land is a / an...",
        answers: [
          "precautionary landing.",
          "urgent landing.",
          "emergency landing.",
          "field or out landing.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 46,
        question: "A precautionary landing is a landing...",
        answers: [
          "conducted without power from the engine.",
          "conducted in response to circumstances forcing the aircraft to land.",
          "conducted in an attempt to sustain flight safety.",
          "conducted with the flaps retracted.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 47,
        question:
          "Which of the following landing areas is most suitable for an off-field landing?",
        answers: [
          "A lake with an undisturbed surface",
          "A light brown field with short crops",
          "A meadow without livestock",
          "A field with ripe waving crops",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 48,
        question:
          "How does the pilot prepare for a VFR flight over a large distance of water, when it is unlikely that land can be reached in case of an engine failure?",
        answers: [
          "File a flight plan including the exact way-points.",
          "Be prepared to fly with transponder only.",
          "Carry life vests or a life raft for all occupants.",
          "Maintain continuous radio contact with ATC.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 49,
        question:
          "Which items should a passenger briefing in case of an imminent emergency landing include?",
        answers: [
          "How to work the aircraft radio, emergency transponder squawk, telephone number of the competent search and rescue (SAR) center",
          "Blood type of the other aircraft occupants, location of the first-aid kit",
          "Nature of the emergency, intentions, safety position, evacuation routes, actions after landing",
          "How to read an emergency checklist, how to cut the engine, telephone number of the home airport",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 50,
        question:
          "After a precautionary landing the brakes and wheels are very hot.\nIn which way should the pilot approach them?",
        answers: [
          "From the right or left side",
          "At an angle of 45°",
          "From the front or back side",
          "From the front, right or left side",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 51,
        question:
          "What needs to be observed in conjunction with overheated brakes?",
        answers: [
          "The affected brakes need to be cooled down with halon",
          "The affected tyres may burst in axial direction",
          "The affected tyres may burst in radial direction or direction of rotation",
          "The wheel fairing shall be taken off to increase the cooldown",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 52,
        question:
          "In case of an emergency ditching, the life vests have to be inflated...",
        answers: [
          "before disembarking the aircraft.",
          "during disembarking the aircraft.",
          "after disembarking the aircraft at a safe distance of about 10 m.",
          "after disembarking the aircraft.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 53,
        question:
          "What are the effects of wet grass on the take-off and landing distance?",
        answers: [
          "Decrease of the take-off distance and increase of the landing distance",
          "Increase of the take-off distance and increase of the landing distance",
          "Decrease of the take-off distance and decrease of the landing distance",
          "Increase of the take-off distance and decrease of the landing distance",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 54,
        question:
          "Under which circumstances may a runway be considered to be contaminated?",
        answers: [
          "When more than 25 % of the runway surface area within the required length and width being used is covered by water, slush, snow or ice more than 3 mm deep",
          "When 75 % of the required runway length and width are covered by contaminants such as snow, frost, ice or sand",
          "When more than 50 % of the runway surface area within the required length and width being used is covered by water, slush, snow or ice more than 3 mm deep",
          "When more than 50 % of the required runway length and width are covered by contaminants such as snow, frost, ice or sand",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 55,
        question:
          'What minimum coverage with ice or snow must be given to call a runway "contaminated"?',
        answers: ["50 %", "25 %", "10 %", "75 %"],
        correct: 1,
        points: 1,
      },
      {
        num: 56,
        question: "Wet snow on a runway can lead to...",
        answers: [
          "an increase in lift.",
          "a decrease in lift.",
          "reduced rolling resistance during take-off.",
          "increased rolling resistance during take-off.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 57,
        question:
          "What danger does standing water on the runway pose to aircraft?",
        answers: [
          "Decreased rolling resistance during take-off",
          "An increase in lift",
          "Increased rolling resistance during take-off",
          "A decrease in lift",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 58,
        question:
          "Which danger exists after a heavy rain shower for a landing aircraft?",
        answers: [
          "Displacement of the tire slip marking",
          "Difficult flare due to glare",
          "Longer braking distance due to aquaplaning",
          "Decreased braking distance due to aquaplaning",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 59,
        question:
          "Which would be the correct reaction when hydroplaning is suspected upon landing?",
        answers: [
          "Add power and use flaps and spoilers to brake aerodynamically",
          "Apply maximum braking to reduce aircraft speed to less than hydroplaning speed, then continue normal ground roll",
          "Cross rudder and aileron controls in order to use the aircraft fuselage to brake aerodynamically",
          "If all wheels are in motion, brake moderately. Maintain directional control by aerodynamic means",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 60,
        question:
          "How should a landing on a contaminated runway be conducted if it proves to be inevitable?",
        answers: [
          "Approach with the minimum crosswind component possible, use minimum flaps, touch down softly with positive pitch and minimum speed, do not apply brakes",
          "Approach with the minimum crosswind component possible, use maximum flaps, touch down with negative pitch and minimum speed, brake carefully",
          "Approach with the minimum crosswind component possible, use maximum flaps, touch down firmly with minimum speed, brake carefully",
          "Approach with the minimum crosswind component possible, use minimum flaps, touch down softly with minimum speed, do not apply brakes",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 61,
        question:
          "During final approach, the glider pilot realizes a very bumpy surface on a selected offfield landing site.\nWhat technique may be recommended for landing?",
        answers: [
          "Touch down with minimum speed, compensate different ground levels with power lever",
          "Approach with increased speed, push elevetor upon first ground contact",
          "Touch down with minimum speed, keep elevator pulled until full stop",
          "Approach with increased speed, avoid using wheel brakes",
        ],
        correct: 2,
        points: 1,
      },
    ],
  },
  {
    num: 70,
    group: "Flight Performance and Planning",
    questions: [
      {
        num: 1,
        question:
          "A flight plan has been filed for a flight departing at an uncontrolled aerodrome.\nWhen has the actual take-off time been transmitted to ATC?",
        answers: [
          "Upon request from ATC.",
          "When landing is assured.",
          "Immediately after take-off.",
          "At deviation from expected off-block time by more than 15 min.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 2,
        question:
          "During a flight with a flight plan submitted, landing is conducted at an airfield other than the destination stated in the filed flight plan.\nWho has to be contacted by the pilot immediately?",
        answers: [
          "Local office for aereal supervision.",
          "The flight manager on duty.",
          "Aeronatical Information Service (AIS).",
          "The police department.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 3,
        question: "Exceeding the maximum allowed aircraft mass is...",
        answers: [
          "not permissible and essentially dangerous.",
          "only relevant if the excess is more than 10 %.",
          "compensated by the pilot's control inputs.",
          "exceptionally permissible to avoid delays.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 4,
        question: "The center of gravity has to be located...",
        answers: [
          "between the front and the rear C.G. limit.",
          "behind the rear C.G. limit.",
          "in front of the front C.G. limit.",
          "right of the lateral C. G. limit.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 5,
        question: "The result of a rear C.G. position is...",
        answers: [
          "an increased fuel consumption.",
          "a decrease of range.",
          "a decrease of stability.",
          "an increased stall speed. ",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 6,
        question:
          "An aircraft must be loaded and operated in such a way that the center of gravity (CG) stays within the approved limits during all phases of flight.\nThis is done to ensure...",
        answers: [
          "that the aircraft does not exceed the maximum permissible airspeed during a descent.",
          "that the aircraft does not stall.",
          "that the aircraft does not tip over on its tail while it is being loaded.",
          "both stability and controllability of the aircraft.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 7,
        question:
          "The result of a front C.G. position is:\n1. Increase in stability.\n2. Increase in fuel consumption.\n3. Increase in stall speed.\n4. Increase in range.",
        answers: ["2, 4", "1, 2", "1, 2, 3", "2, 3, 4"],
        correct: 2,
        points: 1,
      },
      {
        num: 8,
        question: "The basic empty mass of an aircraft includes...",
        answers: [
          "the total mass of the aeroplane ready for a specific type of operation excluding unusable fuel and traffic load. The mass includes items such as crew and crew baggage.",
          "the total mass of an aeroplane ready for a specific type of operation including the required fuel and crew, but excluding traffic load.",
          "the mass of the aeroplane plus standard items such as unusable fuel and other unusable liquids, lubricating oil in engine and auxiliary units, fire extinguishers, pyrotechnics, emergency oxygen equipment, supplementary electronic equipment.",
          "the total mass of the aeroplane ready for a specific type of operation including crew, navigation instruments and engine cowling.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 9,
        question:
          "The empty weight and the corresponding center of gravity (CG) of an aircraft are initially determined...",
        answers: [
          "by weighing.",
          "through data provided by the aircraft manufacturer.",
          "by calculation.",
          "for one aircraft of a type only, since all aircraft of the same type have the same mass and CG position.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 10,
        question: "The density of AVGAS 100LL at 15° C is...",
        answers: ["0.68 kg/l.", "1.0 kg/l.", "0.82 kg/l.", "0.72 kg/l."],
        correct: 3,
        points: 1,
      },
      {
        num: 11,
        question:
          "The conversion factor from kilogram [kg] into pounds [lb] is...",
        answers: [
          "kg x 2 = lb.",
          "kg x 2.205 = lb.",
          "kg / 2.205 = lb.",
          "kg x 0.454 = lb.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 12,
        question:
          "Baggage and cargo must be properly stowed and fastened, otherwise a shift of the cargo may cause...",
        answers: [
          "continuous attitudes which can be corrected by the pilot using the flight controls.",
          "structural damage, angle of attack stability, velocity stability.",
          "uncontrollable attitudes, structural damage, risk of injuries.",
          "calculable instability if the C.G. is shifting by less than 10 %.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 13,
        question: "Loads must be adequately secured in order to...",
        answers: [
          "carry extra fuel.",
          "allow steep turns.",
          "avoid any centre of gravity (C.G.) movements.",
          "prevent excessive 'g'-loading during the landing flare.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 14,
        question:
          "The total weight of an aeroplane is acting vertically through the...",
        answers: [
          "stagnation point.",
          "neutral point.",
          "center of pressure.",
          "center of gravity.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 15,
        question: 'The term "center of gravity" is defined as...',
        answers: [
          "the heaviest point on an aeroplane.",
          "half the distance between the neutral point and the datum line.",
          "another designation for the neutral point.",
          "the point at which the total mass of the aeroplane is considered to act.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 16,
        question: "The center of gravity (CG) defines...",
        answers: [
          "the point on the longitudinal axis or its extension from which the centers of gravity of all masses are referenced.",
          "the point through which the force of gravity is said to act on a mass.",
          "the distance from the datum to the position of a mass.",
          "the product of mass and balance arm.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 17,
        question: "During an unaccelerated flight...",
        answers: [
          "drag equals lift and thrust equals gravity.",
          "thrust equals the sum of drag and gravity.",
          "thrust equals lift and drag equals gravity.",
          "thrust equals drag and lift equals gravity.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 18,
        question:
          'The term "datum" with regard to a mass and balance calculation defines...',
        answers: [
          "the point on the lateral axis of an aeroplane or its extension from which the centers of gravity of all masses are referenced.",
          "the point on the vertical axis of an aeroplane or its extension from which the centers of gravity of all masses are referenced.",
          "the point on the longitudinal axis of an aeroplane or its extension from which the centers of gravity of all masses are referenced.",
          "the distance from the reference plane to the center of gravity of an aircraft.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 19,
        question:
          'The term "moment" with regard to a mass and balance calculation is referred to as...',
        answers: [
          "sum of a mass and a balance arm.",
          "quotient of a mass and a balance arm.",
          "difference of a mass and a balance arm.",
          "product of a mass and a balance arm.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 20,
        question:
          'The term "balance arm" in the context of a mass and balance calculation defines the...',
        answers: [
          "distance from the datum to the center of gravity of a mass.",
          "distance of a mass from the center of gravity.",
          "point on the longitudinal axis of an aeroplane or its extension from which the centers of gravity of all masses are referenced.",
          "point through which the force of gravity is said to act on a mass.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 21,
        question:
          "The distance between the center of gravity and the datum is called...",
        answers: ["span width.", "balance arm.", "lever.", "torque."],
        correct: 1,
        points: 1,
      },
      {
        num: 22,
        question: "The balance arm is the horizontal distance between...",
        answers: [
          "the front C.G. limit and the datum line.",
          "the C.G. of a mass and the rear C.G. limit.",
          "the C.G. of a mass and the datum line.",
          "the front C.G. limit and the rear C.G. limit.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 23,
        question:
          "The required data for a mass and balance calculation including masses and balance arms can be found in the...",
        answers: [
          "performance section of the pilot's operating handbook of this particular aircraft.",
          "mass and balance section of the pilot's operating handbook of this particular aircraft.",
          "documentation of the annual inspection.",
          "certificate of airworthiness.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 24,
        question:
          "When preparing to carry out the weighing procedure on an aircraft, which of the following is required?",
        answers: [
          "Drain all engine tank oil",
          "Remove service equipment",
          "Drain all useable fuel",
          "Remove the batteries",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 25,
        question:
          "Which section of the flight manual describes the basic empty mass of an aircraft?",
        answers: [
          "Normal procedures",
          "Limitations",
          "Performance",
          "Weight and balance",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 26,
        question: "The position of the center of gravity equals...",
        answers: ["147.5 in.", "145.7 in.", "142 in.", "137.5 in."],
        correct: 2,
        points: 1,
        figure: "PFP-052e",
      },
      {
        num: 27,
        question: "What mass equals 102 litres of Avgas 100LL?",
        answers: ["142 lbs", "74 lbs", "142 kg", "74 kg"],
        correct: 3,
        points: 1,
      },
      {
        num: 28,
        question:
          "Calculated take-off mass = 2300 lbs, calculated CG = 95.75 in, fuel burn = 170 lbs on station 87.00 in.\nWhere is the CG situated after the landing?",
        answers: ["97.39 in", "96.45 in", "94.11 in", "96.57 in"],
        correct: 1,
        points: 1,
      },
      {
        num: 29,
        question:
          "Given values:\nCalculated take-off mass = 746 kg\ncalculated CG = 37.1 cm\nfuel burn = 30.5 l on station 45 cm.\nWhere is the CG situated after the landing?",
        answers: ["37.2 cm", "37.5 cm", "36.3 cm", "36.9 cm"],
        correct: 3,
        points: 1,
      },
      {
        num: 30,
        question:
          "Calculated take-off mass = 1082 kg, calculated CG = 0.254 m, fuel burn = 55 l on station 0.40 m.\nWhere is the CG situated after the landing?",
        answers: ["24.6 cm", "25.2 cm", "25.4 cm", "24.8 cm"],
        correct: 3,
        points: 1,
      },
      {
        num: 31,
        question:
          "The position of the center of gravity (including fuel) equals...",
        answers: ["37.1 cm.", "0.401 m.", "37.3 cm.", "0.403 m."],
        correct: 0,
        points: 1,
        figure: "PFP-053e",
      },
      {
        num: 32,
        question:
          "For the purpose of a flight preparation, the pilot calculates a total take-off mass of 750 kg and a total moment of 625 mmkg.\nWhich cross marks the center of gravity (CG)?",
        answers: ["4", "2", "1", "3"],
        correct: 2,
        points: 1,
        figure: "PFP-003",
      },
      {
        num: 33,
        question:
          "For the purpose of a flight preparation the pilot calculates a total take-off mass of 725 kg and a total moment of 650 mmkg.\nWhich cross marks the center of gravity (CG)?",
        answers: ["1", "2", "4", "3"],
        correct: 3,
        points: 1,
        figure: "PFP-004",
      },
      {
        num: 34,
        question:
          "For the purpose of a flight preparation the pilot calculates a total take-off mass of 775 kg and a total moment of 700 mmkg.\nWhich cross marks the center of gravity (CG)?",
        answers: ["3", "2", "4", "1"],
        correct: 2,
        points: 1,
        figure: "PFP-005",
      },
      {
        num: 35,
        question:
          "Which is the most recently determined empty mass and the associated center of gravity (CG) arm from the aircraft documentation?",
        answers: [
          "5 kg; 1.3 m",
          "4 kg; 1.1 m",
          "498 kg; 280.59 m",
          "512 kg; 285.39 m",
        ],
        correct: 2,
        points: 1,
        figure: "PFP-006",
      },
      {
        num: 36,
        question:
          "How does the aircraft configuration influence take-off performance while all other parameters remaining constant?",
        answers: [
          "Aircraft B has a higher tyre pressure than aircraft A",
          "Aircraft A has a higher tyre pressure than aircraft B",
          "Aircraft B has a higher flap setting than aircraft A",
          "Aircraft A has a higher flap setting than aircraft B",
        ],
        correct: 3,
        points: 1,
        figure: "PFP-007",
      },
      {
        num: 37,
        question:
          "How does aircraft flap configuration influence the take-off performance?",
        answers: [
          "A higher flap setting decreases ground roll and increases lift-off speed and climb performance",
          "A higher flap setting decreases ground roll and lift-off speed and increases climb performance",
          "A higher flap setting increases ground roll, lift-off speed, and climb performance",
          "A higher flap setting decreases ground roll and lift-off speed, but also climb performance",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 38,
        question: "How does wind affect the take-off performance?",
        answers: [
          "Tailwind aids the aircraft in overcoming the initial drag at the commencement of the take-off roll. The take-off distance will decrease",
          "Tailwind reduces the relative wind on the airfoil. The take-off distance will increase",
          "Headwind causes an increased airflow around the wing. The take-off distance will increase",
          "Headwind imposes an increased drag on the aircraft. The take-off distance will increase",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 39,
        question:
          "It is possible that the surface wind speed at an airport is reduced due to friction. When a surface area with a minor tailwind condition is left during the initial climb, the pilot might expect...",
        answers: [
          "an increase in airspeed and rate of climb due to decreasing tailwind.",
          "a deacrease in airspeed and climb performance due to decreasing tailwind.",
          "an increase in airspeed and rate of climb due to increasing tailwind.",
          "a decrease in airspeed and rate of climb due to increasing tailwind.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 40,
        question: "Which factor shortens landing distance?",
        answers: [
          "High pressure altitude",
          "Strong head wind",
          "Heavy rain",
          "High density altitude",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 41,
        question:
          "Unless the aircraft is equipped and certified accordingly...",
        answers: [
          "flight into known or forecast icing conditions is only allowed as long as it is ensured that the aircraft can still be operated without performance degradation.",
          "flight into forecast icing conditions is prohibited. Should the aircraft enter an area of icing conditions inadvertantly, the flight may be continued as long as visual meteorological conditions are maintained.",
          "flight into known or forecast icing conditions is prohibited. Should the aircraft enter an area of icing conditions inadvertantly, it should be left without delay.",
          "flight into areas of precipitation is prohibited.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 42,
        question: "The speed Vx means...",
        answers: [
          "that a given altitude is reached within minimum distance.",
          "that a given altitude is reached within minimum flight time.",
          "maximum altitude gain per 10 % power.",
          "that a given altitude is reached with minimum fuel consumption.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 43,
        question: "The angle of descent is defined as...",
        answers: [
          "the ratio between the change in height and the horizontal distance travelled within the same time, expressed in percent [%].",
          "the angle between a horizontal plane and the actual flight path, expressed in degrees [°].",
          "the ratio between the change in height and the horizontal distance distance travelled within the same time, expressed in degrees [°].",
          "the angle between a horizontal plane and the actual flight path, expressed in percent [%].",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 44,
        question: 'The term "steady flight" is defined as...',
        answers: [
          "flight with a steady power setting without changing course.",
          "climb or descent with a constant climb or descent rate in calm weather conditions.",
          "unaccelerated flight. The four forces thrust, drag, lift, and weight are in equilibrium.",
          "flight in smooth air without turbulence and a perfectly trimmed aircraft.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 45,
        question: "The speed Vy is defined as...",
        answers: [
          "best speed of climb.",
          "best angle of climb.",
          "best distance of climb.",
          "best rate of climb.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 46,
        question: "The speed VFE is defined as...",
        answers: [
          "stalling or minimum steady flight speed with the flaps retracted.",
          "maximum flap extended speed.",
          "stalling or minimum steady flight speed with the flaps extended.",
          "maximum landing gear extended speed.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 47,
        question: "The speed VS0 is defined as...",
        answers: [
          "maximum landing gear extended speed.",
          "stalling speed or minimum steady flight speed obtained in a specific configuration.",
          "stalling speed or minimum steady flight speed in landing configuration.",
          "never-exceed speed.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 48,
        question:
          "The beginning of the green arc (2) indicates which airspeed?",
        answers: [
          "VS1: Stall speed with flaps up",
          "VS0: Stall speed in landing configuration",
          "VFE: Maximum flap extended speed",
          "VNO: Maximum speed for normal operations",
        ],
        correct: 0,
        points: 1,
        figure: "PFP-008",
      },
      {
        num: 49,
        question: "The end of the green arc (4) indicates which airspeed?",
        answers: [
          "VNO: Maximum speed for normal operations",
          "VNE: Never-exceed speed",
          "VFE: Maximum flap extended speed",
          "VS1: Stall speed with flaps up",
        ],
        correct: 0,
        points: 1,
        figure: "PFP-008",
      },
      {
        num: 50,
        question:
          "The red marking at the end of the yellow arc (5) indicates which airspeed?",
        answers: [
          "VNO: Maximum speed for normal operations",
          "VFE: Maximum flap extended speed",
          "VS1: Stall speed with flaps up",
          "VNE: Never-exceed speed",
        ],
        correct: 3,
        points: 1,
        figure: "PFP-008",
      },
      {
        num: 51,
        question:
          "Which climb speed may be used to optimize the rate of climb (e.g. to reach a desired altitude within minimum time)?",
        answers: [
          "Vy, the best angle of climb speed",
          "Vy, the best rate of climb speed",
          "Vx, the best rate of climb speed",
          "Vx, the best angle of climb speed",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 52,
        question:
          "For a take-off from runway 22 and a reported wind of 250°/10 kt, the longitudinal wind component equals...",
        answers: [
          "9 kt tailwind.",
          "5 kt tailwind.",
          "9 kt headwind.",
          "5 kt headwind.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 53,
        question:
          "Given the following conditions, the take-off distance equals...\nOutside air temperature: -20° C\nPressure Altitude: 5000 ft\nAeroplane mass: 750 kg\nHeadwind: 10 kt",
        answers: ["450 m.", "380 m.", "410 m.", "310 m."],
        correct: 1,
        points: 1,
        figure: "PFP-009",
      },
      {
        num: 54,
        question:
          "A pilot wants to take off on runway 36, the reported wind is 240 degrees, 12 knots.\nWhat is the value of the wind components acting on the aircraft on take-off and landing?",
        answers: [
          "Crosswind from the right 10.4 kt. Tailwind 6 kt.",
          "Crosswind from the left 10.4 kt. Tailwind 6 kt.",
          "Crosswind from the left 6 kt. Tailwind 10.4 kt.",
          "Crosswind from the right 6 kt. Headwind 10.4 kt.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 55,
        question:
          "What is the take-off distance at 750 kg take-off mass, standard (ISA) conditions at an elevation of 4000 ft with 5 kt tailwind?",
        answers: ["900 m", "320 m", "630 m", "480 m"],
        correct: 0,
        points: 1,
        figure: "PFP-009",
      },
      {
        num: 56,
        question:
          "What is the take-off distance at 705 kg take-off mass, OAT 20° C, QNH 1013 hPa at an elevation of 3500 ft with 5 kt tailwind?",
        answers: ["720 m", "880 m", "790 m", "820 m"],
        correct: 1,
        points: 1,
        figure: "PFP-009",
      },
      {
        num: 57,
        question:
          "A pilot wants to take off on runway 36, the reported wind is 240 degrees 12 knots.\nWhat are the wind components acting on the aircraft on take-off and landing?",
        answers: [
          "Crosswind from the right 10.4 kt. Tailwind 6 kt.",
          "Crosswind from the right 6 kt. Headwind 10.4 kt.",
          "Crosswind from the left 10.4 kt. Tailwind 6 kt.",
          "Crosswind from the left 6 kt. Tailwind 10.4 kt.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 58,
        question:
          "Given the following conditions, the fuel consumption equals...\nPressure altitude: 2000 ft\nTemperature: 31° C\nRPM: 2400",
        answers: ["19.5 l/h.", "19.1 l/h.", "21.7 l/h.", "22.8 l/h."],
        correct: 0,
        points: 1,
        figure: "PFP-012",
      },
      {
        num: 59,
        question:
          "Given the following conditions, the climb speed equals...\nOutside air temperature: -20° C\nPressure altitude: 10000 ft",
        answers: ["200 ft/min.", "350 ft/min.", "390 ft/min.", "450 ft/min."],
        correct: 2,
        points: 1,
        figure: "PFP-011",
      },
      {
        num: 60,
        question:
          "What range can be achieved at the following conditions?\nOutside air temperature: 6° C\nPressure Altitude: 6000 ft\nPower: 65 %",
        answers: ["457 NM", "503 NM", "444 NM", "482 NM"],
        correct: 3,
        points: 1,
        figure: "PFP-013",
      },
      {
        num: 61,
        question:
          "Given the following information, what range can be achieved?\nOutside air temperature: 22° C\nPressure altitude: 2000 ft\nPower: 55 %",
        answers: ["550 NM", "480 NM", "450 NM", "500 NM"],
        correct: 3,
        points: 1,
        figure: "PFP-013",
      },
      {
        num: 62,
        question:
          "Given the following conditions, the TAS equals...\nOutside air temperature: 10° C\nPressure altitude: 6000 ft\nPower: 65 %",
        answers: ["96 kt.", "92 kt.", "88 kt.", "100 kt."],
        correct: 1,
        points: 1,
        figure: "PFP-014",
      },
      {
        num: 63,
        question:
          "Given the following conditions, the TAS equals...\nOutside air temperature: -2° C\nPressure altitude: 8000 ft\nPower: 75 %",
        answers: ["104 kt.", "100 kt.", "95 kt.", "110 kt."],
        correct: 0,
        points: 1,
        figure: "PFP-014",
      },
      {
        num: 64,
        question:
          "Which maximum rate of climb can the aircraft reach at 9000 ft pressure altitude and OAT 12° C?",
        answers: ["200 ft/min", "250 ft/min", "350 ft/min", "300 ft/min"],
        correct: 0,
        points: 1,
        figure: "PFP-011",
      },
      {
        num: 65,
        question:
          "Which is the maximum rate of climb for the aircraft at 6500 ft pressure altitude and an OAT of 0° C?",
        answers: [
          "480 ft / min",
          "400 ft / min",
          "520 ft / min",
          "800 ft / min",
        ],
        correct: 0,
        points: 1,
        figure: "PFP-011",
      },
      {
        num: 66,
        question:
          "What is the true airspeed (TAS) [kt] and fuel consumption [l/h] for cruise flight with 60 % power in flight level 60 under the following conditions?\nTemperature: ISA - 20° C\nQNH: 980 hPa",
        answers: [
          "96 kt.\n19.1 l/h.",
          "95 kt.\n19.6 l/h.",
          "110 kt.\n25.1 l/h.",
          "95,75 kt.\n19.8 l/h.",
        ],
        correct: 1,
        points: 2,
        figure: "PFP-012",
      },
      {
        num: 67,
        question:
          "What is the true airspeed (TAS) [kt] and fuel consumption [l/h] for cruise flight with 70 % power in flight level 60 under the following conditions?\nTemperature: ISA - 20° C\nQNH: 980 hPa",
        answers: [
          "110 kt.\n23.9 l/h.",
          "100 kt.\n19.3 l/h.",
          "95 kt.\n19.6 l/h.",
          "105 kt.\n21.5 l/h.",
        ],
        correct: 0,
        points: 2,
        figure: "PFP-012",
      },
      {
        num: 68,
        question:
          "What is the fuel flow and the true airspeed for cruise flight with 60 % power in flight level 85 at an OAT of -25° C?",
        answers: [
          "Fuel flow: 17 l.\nTAS: 81 kt.",
          "Fuel flow: 17.5 l.\nTAS: 83 kt.",
          "Fuel flow: 20 l.\nTAS: 89 kt.",
          "Fuel flow: 18.5 l.\nTAS: 85 kt.",
        ],
        correct: 3,
        points: 1,
        figure: "PFP-014",
      },
      {
        num: 69,
        question:
          "At which airspeed do you climb to flight level (FL) 75 after a departure from an airfield which is located at a pressure altitude of 3000 ft with an initial mass of 3000 lbs?\nOAT at airfield: 25° C\nOAT in FL 75: 0° C",
        answers: ["90 kt", "120 kt", "110 kt", "100 kt"],
        correct: 2,
        points: 1,
        figure: "PFP-023",
      },
      {
        num: 70,
        question:
          "What is the required fuel to climb from FL 65 to FL 95 under the following conditions?\nAircraft mass: 3000 lb.\nOAT in FL 65: -5° C\nOAT in FL 95: -15° C",
        answers: ["1 GAL", "2 GAL", "6 GAL", "3 GAL"],
        correct: 0,
        points: 1,
        figure: "PFP-023",
      },
      {
        num: 71,
        question:
          "What is the required distance to climb from FL 65 to FL 95 under the following conditions:\nAircraft mass: 3000 lb.\nOAT in FL 65: -5° C\nOAT in FL 95: -15° C",
        answers: ["6 NM", "3 NM", "16 NM", "10 NM"],
        correct: 0,
        points: 1,
        figure: "PFP-023",
      },
      {
        num: 72,
        question:
          "What is the required distance to climb to flight level (FL) 75 after a departure from an airfield which is located at a pressure altitude of 3000 ft with an initial mass of 3000 lbs?\nOAT at airfield: 25° C\nOAT in FL 75: 0° C",
        answers: ["10 NM", "6 NM", "4 NM", "7 NM"],
        correct: 3,
        points: 1,
        figure: "PFP-023",
      },
      {
        num: 73,
        question: 'The term "maximum elevation figure" (MEF) is defined as...',
        answers: [
          "the highest elevation within an area covering 30 minutes of latitude and 30 minutes of longitude.",
          "the highest elevation within an area covering 30 minutes of latitude and 30 minutes of longitude plus a safety margin, rounded to the next higher 100 ft.",
          "the highest elevation within an area covering 30 minutes of latitude and 30 minutes of longitude plus a safety margin of 1000 ft (305 m), rounded to the next higher 100 ft.",
          "the highest elevation within an area covering 1 degree of latitude and 1 degree of longitude plus a safety margin, rounded to the next lower 100 ft.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 74,
        question:
          'What is the purpose of "interception lines" in visual navigation?',
        answers: [
          "To visualize the range limitation from the departure aerodrome",
          "They help to continue the flight when flight visibility drops below VFR minima",
          "To mark the next available en-route airport during the flight",
          "They are used as easily recognizable guidance upon a possible loss of orientation",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 75,
        question: "The VFR semicircular rules are based on the...",
        answers: [
          "true course (TC).",
          "magnetic heading (MH).",
          "magnetic course (MC).",
          "true heading (TH).",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 76,
        question:
          "What is the lowest possible VFR flight level if a true course of 181° is selected and a variation of 3° east exists?",
        answers: ["FL 050", "FL 060", "FL 055", "FL 065"],
        correct: 2,
        points: 1,
      },
      {
        num: 77,
        question: "The upper limit of LO R 16 equals...",
        answers: ["FL150.", "1 500 m MSL.", "1 500 ft MSL.", "1.500 ft GND. "],
        correct: 2,
        points: 1,
        figure: "PFP-056",
      },
      {
        num: 78,
        question: "The upper limit of LO R 4 equals...",
        answers: [
          "4.500 ft AGL.",
          "4.500 ft MSL.",
          "1.500 ft MSL.",
          "1.500 ft AGL.",
        ],
        correct: 1,
        points: 1,
        figure: "PFP-030",
      },
      {
        num: 79,
        question:
          "How much taxi fuel must be consumed before take-off to reduce the aircraft mass to the maximum take-off mass?\nMaximum ramp mass (MRM): 1150 kg\nActual ramp mass: 1148 kg\nMaximum take-off mass (MTOM): 1145 kg",
        answers: ["2 L", "3 L", "5 L", "4 L"],
        correct: 3,
        points: 1,
      },
      {
        num: 80,
        question:
          "Considering the following fuel data, how much trip fuel is required?\nFuel for start-up and taxi: 5 L\nFuel for take-off and climb: 12 L\nFuel for cruise flight: 25 L\nFuel for descent, approach and landing: 7 L\nFuel for taxi and parking: 3 L\nFuel to alternate: 13 L\nFinal reserve fuel: 10 L",
        answers: ["75 L", "52 L", "49 L", "44 L"],
        correct: 3,
        points: 1,
      },
      {
        num: 81,
        question:
          "According to the aeronautical chart, Friesach/Hirt (LOKH) has a 707 m grass runway. Prevailing runway is 17 due to a surface wind of 18010KT. The required landing distance for your aircraft under present conditions is 550 m. Considering the NOTAM below ,is it safe to plan LOKH as an alternate aerodrome?",
        answers: ["Don't know.", "Maybe.", "Yes.", "No."],
        correct: 3,
        points: 1,
        figure: "PFP-026",
      },
      {
        num: 82,
        question:
          "Up to which altitude is an overflight prohibited according to the NOTAM?",
        answers: [
          "Height 9500 ft",
          "Altitude 9500 ft MSL",
          "Altitude 9500 m MSL",
          "Flight Level 95",
        ],
        correct: 1,
        points: 1,
        figure: "PFP-024",
      },
      {
        num: 83,
        question:
          "The EOBT (estimated off-block time) is specified in the ATS flight plan as...",
        answers: [
          "Coordinated Universal Time (UTC).",
          "Standard Time (ST).",
          "Local Mean Time (LMT).",
          "Central European Time (CET).",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 84,
        question:
          "What is the nature of the flight shown in the given ATC flight plan?",
        answers: [
          "Night flight under visual flight rules.",
          "Flight under instrument flight rules.",
          "Traffic pattern under visual flight rules.",
          "Border crossing flight.",
        ],
        correct: 0,
        points: 1,
        figure: "PFP-051a",
      },
      {
        num: 85,
        question: "The specified speed in the ATS flight plan equals:",
        answers: ["1000 kt.", "100 m/h.", "100 km/h.", "100 kt."],
        correct: 3,
        points: 1,
        figure: "PFP-051",
      },
      {
        num: 86,
        question: "What must be considered for cross-border flights?",
        answers: [
          "Transmission of hazard reports",
          "Regular location messages",
          "Approved exceptions",
          "Requires flight plans",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 87,
        question: "During a flight, a flight plan can be filed at the...",
        answers: [
          "Flight Information Service (FIS).",
          "Search and Rescue Service (SAR).",
          "next airport operator en-route.",
          "Aeronautical Information Service (AIS).",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 88,
        question:
          "In comparison to the true airspeed in still air conditions, the TAS in a strong tailwind will be...",
        answers: [
          "the same for maximum range.",
          "significantly lower for maximum endurance.",
          "slightly lower for maximum range.",
          "slightly higher for maximum endurance.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 89,
        question:
          "What happens to the true airspeed at a constant indicated airspeed during a climb?",
        answers: [
          "It decreases",
          "It increases",
          "It remains constant below 5000 ft",
          "It remains constant above 5000 ft",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 90,
        question:
          "Given the following data:\nTake-Off fuel = 200 lbs\nAlternate fuel = 40 lbs\nFinal reserve fuel = 30 lbs\nAfter 25 minutes the remaining fuel is 120 lbs.\nAssuming that fuel flow will remain unchanged, the remaining time to the destination should not exceed:",
        answers: ["37.5 min", "20.0 min", "15.6 min", "59.4 min"],
        correct: 2,
        points: 2,
      },
      {
        num: 91,
        question:
          "Given the following data for a VFR flight:\nTake-off fuel: 180 kg including reserve fuel, which is 30% of take off fuel.\nAfter half of the distance the remaining fuel is 100 kg.\nAssume that cruise conditions will remain unchanged.\nDetermine the remaining fuel at the destination:",
        answers: ["80 kg", "40 kg", "10 kg", "20 kg"],
        correct: 3,
        points: 2,
      },
      {
        num: 92,
        question:
          "During a VFR flight the remaining usable fuel at a checkpoint is 80 USG.\nReserve fuel is 20 USG, remaining flight time according to flight plan is 2h 20min.\nWhat is the highest acceptable fuel flow (FF) for the rest of the trip?",
        answers: [
          "FF = 8.6 USG/h",
          "FF = 42.9 USG/h",
          "FF = 25.7 USG/h",
          "FF = 34.3 USG/h",
        ],
        correct: 2,
        points: 2,
      },
      {
        num: 93,
        question:
          "Planning a flight from EDWF (Leer Papenburg) to EDWH (Oldenburg Hatten), the following conditions apply:\nCruise level = FL 75\nTemperature = ISA\nCruise weight = 3400 lbs\nPower setting = 23.0 in. HG @ 2300 RPM\nDetermine True Airspeed (TAS) and Fuel Flow (FF):",
        answers: [
          "TAS = 145 kt\nFF = 71.1 GPH",
          "TAS = 160 kt\nFF = 12.3 GPH",
          "TAS = 160 kt\nFF = 11.9 GPH",
          "TAS = 145 kt\nFF = 11.9 GPH",
        ],
        correct: 2,
        points: 2,
        figure: "CAP-697",
      },
      {
        num: 94,
        question:
          "Planning a flight from EDWH (Oldenburg Hatten) to EDWF (Leer Papenburg), the following conditions apply:\nCruise level = FL 65\nTemperature = ISA+20\nCruise weight = 3400 lbs\nPower setting = 23.0 in. HG @ 2300 RPM\nWhat Indicated Airspeed (IAS) and Fuel Flow (FF) can be expected?",
        answers: [
          "IAS = 142 kt\nFF = 11.5 GPH",
          "IAS = 145 kt\nFF = 11.9 GPH",
          "IAS = 158kt\nFF = 11.5 GPH",
          "IAS = 150 kt\nFF = 12.3 GPH",
        ],
        correct: 0,
        points: 2,
        figure: "CAP-697",
      },
      {
        num: 95,
        question:
          'For planning a VFR flight, the following data are given:\nFlight time with planning "overhead-overhead" = 2h 43min\nPressure Altitude = 6.500 ft\nTemperature = ISA-20\nPower setting = 2300 RPM\nTaxi Fuel = 2 USG\nAdditional time for climb = 7 min,\nAdditional time for approach and landing = 10 min\nThe reserve fuel has to be 30% of trip fuel.\nDetermine the minimum block fuel:',
        answers: ["47.3 USG", "50.4 USG", "43.8 USG", "39.2 USG"],
        correct: 1,
        points: 2,
        figure: "CAP-697",
      },
      {
        num: 96,
        question:
          'For planning a VFR flight, the following data are given:\nFlight time with planning "overhead-overhead" = 2h 42min\nPressure Altitude = 7.500 ft\nTemperature = ISA\nPower setting = 2300 RPM\nTaxi Fuel = 2 USG\nAdditional time for climb = 8 min,\nAdditional time for approach and landing = 10 min\nThe reserve fuel has to be 30% of trip fuel.\nDetermine the minimum block fuel:',
        answers: ["51.8 USG", "37.7 USG", "46.4 USG", "48.4 USG"],
        correct: 3,
        points: 2,
        figure: "CAP-697",
      },
      {
        num: 97,
        question:
          "Given the following data for a VFR flight:\nTrip fuel = 70 US gallons\nContingency fuel = 5% of trip fuel\nAlternate and final reserve fuel = 20 US gallons\nUsable fuel at take-off = 95 US gallons\nAfter half of the distance you read that you have consumed 40 US gallons.\nAssume that fuel flow remains unchanged.\nWhich statement is correct?",
        answers: [
          "Upon landing 15.0 US gallons will remain in addition to alternate and final reserve fuel.",
          "Upon landing 5.0 US gallons will remain in addition to alternate and final reserve fuel.",
          "Upon landing, a total of 40.0 US gallons will remain.",
          "The remaining fuel is insufficient for a landing at destination with alternate and final reserve fuel remaining.",
        ],
        correct: 3,
        points: 2,
      },
      {
        num: 98,
        question:
          "Given the following data for a VFR flight:\nTrip fuel = 70 US gallons\nContingency fuel = 5% of trip fuel.\nAlternate and final reserve fuel = 20 US gallons\nUsable fuel at take-off = 90 US gallons\nAfter half of the distance you read that you have consumed 30 US gallons.\nAssume that fuel flow remains unchanged.\nWhich statement is correct?",
        answers: [
          "The remaining fuel is insufficient for a landing at destination with alternate and final reserve fuel remaining.",
          "Upon landing 30.0 US gallons will remain in addition to alternate and final reserve fuel.",
          "Upon landing 10.0 US gallons will remain in addition to alternate and final reserve fuel.",
          "Upon landing a total of 10.0 US gallons will remain.",
        ],
        correct: 2,
        points: 2,
      },
      {
        num: 99,
        question:
          "According ICAO, what symbol indicates a group of unlighted obstacles?",
        answers: ["C", "B", "A", "D"],
        correct: 0,
        points: 2,
        figure: "PFP-061",
      },
      {
        num: 100,
        question:
          "According ICAO, what symbol indicates a civil airport (not international airport) with paved runway?",
        answers: ["D", "A", "B", "C"],
        correct: 1,
        points: 2,
        figure: "PFP-062",
      },
      {
        num: 101,
        question:
          "According ICAO, what symbol indicates a general spot elevation?",
        answers: ["B", "C", "A", "D"],
        correct: 1,
        points: 2,
        figure: "PFP-063",
      },
    ],
  },
  {
    num: 80,
    group: "Aircraft General Knowledge",
    questions: [
      {
        num: 1,
        question:
          "The thickness of the wing is defined as the distance between the lower and the upper side of the wing at the...",
        answers: [
          "most inner part of the wing.",
          "thinnest part of the wing.",
          "thickest part of the wing.",
          "most outer part of the wing.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 2,
        question:
          "How is referred to a tubular steel construction with a non self-supporting skin?",
        answers: [
          "Semi-monocoque construction.",
          "Grid construction",
          "Honeycomb structure",
          "Monocoque construction",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 3,
        question:
          "Primary fuselage structures of wood or metal planes are usually made up by what components?",
        answers: [
          "Girders, rips and stringers",
          "Frames and stringer",
          "Rips, frames and covers",
          "Covers, stringers and forming parts",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 4,
        question: "Which wing configuration is shown in the attachment?",
        answers: [
          "Strut-braced high wing",
          "High wing",
          "Mid wing",
          "Low wing",
        ],
        correct: 0,
        points: 1,
        figure: "AGK-002",
      },
      {
        num: 5,
        question:
          "A construction made of frames and stringer with a supporting skin is called...",
        answers: [
          "Honeycomb structure.",
          "Grid construction.",
          "Wood- or mixed construction.",
          "Semi-monocoque construction.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 6,
        question: "Which tail assembly is shown in the attachment?",
        answers: [
          "V-tail",
          "T-tail",
          "Fuselage-mounted tail",
          "Cruciform tail",
        ],
        correct: 1,
        points: 1,
        figure: "AGK-003",
      },
      {
        num: 7,
        question: "What are the major components of an aircraft's tail?",
        answers: [
          "Ailerons and elevator",
          "Horizontal tail and vertical tail",
          "Rudder and ailerons",
          "Steering wheel and pedals",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 8,
        question: "The sandwich structure consists of two...",
        answers: [
          "thin layers and a light core material.",
          "thin layers and a heavy core material.",
          "thick layers and a heavy core material.",
          "thick layers and a light core material.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 9,
        question:
          "Which constructional elements give the wing its profile shape?",
        answers: ["Rips", "Planking", "Spar", "Tip"],
        correct: 0,
        points: 1,
      },
      {
        num: 10,
        question: 'The load factor "n" describes the relationship between...',
        answers: [
          "thrust and drag.",
          "drag and lift.",
          "lift and weight.",
          "weight and thrust.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 11,
        question: "Which are the advantages of sandwich structures?",
        answers: [
          "High temperature durability and low weight",
          "Low weight, high stiffness, high stability, and high strength",
          "Good formability and high temperature durability",
          "High strength and good formability",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 12,
        question: "Which of the stated materials shows the highest strength?",
        answers: [
          "Carbon fiber re-inforced plastic",
          "Aluminium",
          "Wood",
          "Magnesium",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 13,
        question:
          "What needs to be considered if permissible limitations were exceeded?",
        answers: [
          "The helicopter must be inspected by the pilot in command and if no defect is found, no appropriate entry in the aircraft technical log is necessary",
          "The helicopter must be inspected by at least two pilots licensed on the type, one of whom must be the pilot in command",
          "The helicopter must be inspected by a qualified engineer before the next flight",
          "The helicopter must be subjected to a duplicate inspected by two engineers",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 14,
        question: "Reasons for dents in the helicopter structure are...",
        answers: [
          "material defects or old colour.",
          "intense erosion or high wear.",
          "hard landing or excessive stress.",
          "excessive engine rpm and cylinder defects.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 15,
        question:
          "What kind of hydraulic oil is used in aeroplane systems today?",
        answers: ["Mineral oil", "Vegetable oil", "Bio-oil", "Synthetic oil"],
        correct: 3,
        points: 1,
      },
      {
        num: 16,
        question: "Number 1 on the gear designates the...",
        answers: ["fork.", "strut.", "torque link.", "inner shock absorber."],
        correct: 0,
        points: 1,
        figure: "AGK-005",
      },
      {
        num: 17,
        question: "The fuselage structure may be damaged by...",
        answers: [
          "exceeding the manoeuvering speed in heavy gusts.",
          "neutralizing stick forces according to actual flight state.",
          "stall after exceeding the maximum angle of attack.",
          "airspeed decreasing below a certain value.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 18,
        question: "Number 2 on the gear designates the...",
        answers: [
          "fork.",
          "torque link.",
          "fixed outer cylinder.",
          "movable inner cylinder.",
        ],
        correct: 3,
        points: 1,
        figure: "AGK-005",
      },
      {
        num: 19,
        question:
          "How is the nose or tail wheel usually controlled on small aircraft and motor gliders?",
        answers: [
          "By the steering wheel",
          "By the control column",
          "By weight movement",
          "By the pedals",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 20,
        question:
          "Where is the brake system installed to slow the aircraft on ground?",
        answers: [
          "On the tail wheel",
          "On the nose and main gear",
          "Only on the nose gear",
          "Only on the main gear",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 21,
        question:
          "What kind of control surface is connected with the nose wheel?",
        answers: ["Elevator", "Trim rudder", "Aileron", "Rudder"],
        correct: 3,
        points: 1,
      },
      {
        num: 22,
        question: "What is checked by the marking in the attachment?",
        answers: [
          "The tread wear of the tire",
          "The operating pressure of the carcass",
          "The correct postion of the tire relative to the rim",
          "The correct position of the outer isolation layers",
        ],
        correct: 2,
        points: 1,
        figure: "AGK-006",
      },
      {
        num: 23,
        question:
          "About how many axes does an aircraft move and how are these axes called?",
        answers: [
          "4; optical axis, imaginary axis, sagged axis, axis of evil",
          "4; vertical axis, lateral axis, longitudinal axis, axis of speed",
          "3; x-axis, y-axis, z-axis",
          "3; vertical axis, lateral axis, longitudinal axis",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 24,
        question:
          "A movement around the longitudinal axis is primarily initiated by the...",
        answers: ["rudder.", "ailerons.", "elevator.", "trim tab."],
        correct: 1,
        points: 1,
      },
      {
        num: 25,
        question:
          "How are the flight controls on a small single-engine piston aircraft normally controlled and actuated?",
        answers: [
          "Power-assisted through hydraulic pumps or electric motors",
          "Hydraulically through hydraulic pumps and actuators",
          "Manually through rods and control cables",
          "Electrically through fly-by-wire",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 26,
        question:
          "What are the primary and the secondary effects of a rudder input to the left?",
        answers: [
          "Primary: yaw to the left\nSecondary: roll to the right",
          "Primary: yaw to the left\nSecondary: roll to the left",
          "Primary: yaw to the right\nSecondary: roll to the left",
          "Primary: yaw to the right\nSecondary: roll to the right",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 27,
        question:
          "What is the effect of pulling the control yoke or stick backwards?",
        answers: [
          "The aircraft's tail will produce an increased downward force, causing the aircraft's nose to drop",
          "The aircraft's tail will produce an decreased upward force, causing the aircraft's nose to drop",
          "The aircraft's tail will produce an increased downward force, causing the aircraft's nose to rise",
          "The aircraft's tail will produce an increased upward force, causing the aircraft's nose to rise",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 28,
        question:
          "What happens to a helicopter during cruise when the stick is moved forward without other corrections?",
        answers: [
          "The speed increases and the sink rate increases",
          "The speed decreases and the sink rate increases",
          "The speed increases and the sink rate decreases",
          "The speed decreases and the sink rate decreases",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 29,
        question:
          "Which of the following options states all primary flight controls of an aircraft?",
        answers: [
          "All movable parts on the aircraft which aid in controlling the aircraft",
          "Flaps, slats, speedbrakes",
          "Elevator, rudder, aileron",
          "Elevator, rudder, aileron, trim tabs, high-lift wing devices, power controls",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 30,
        question: "What is the purpose of the secondary flight controls?",
        answers: [
          "To constitute a backup system for the primary flight controls",
          "To improve the performance characteristics of an aircraft and relieve the pilot of excessive control forces",
          "To improve the turn characteristics of an aircraft in the low speed regime during approach and landing",
          "To enable the pilot to control the aircraft's movements about its three axes",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 31,
        question: "What is the purpose of a ground adjustable trim tab?",
        answers: [
          "It is set on the ground to correct an out-of-trim condition arising from the mass and balance situation of an aircraft",
          "It is preset on the ground and further adjusted in flight to reduce the need to constantly re-trim the aircraft",
          "It is used to optimize the handling characteristics of an aircraft during ground operations",
          "It is a non-movable metal tab on a flight control which is adjusted on the ground to optimize the inflight characteristics of an aircraft",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 32,
        question:
          "The trim wheel or lever in the cockpit is moved aft by the pilot.\nWhat effect does this action have on the trim tab and on the elevator?",
        answers: [
          "The trim tab moves up, the elevator moves up",
          "The trim tab moves up, the elevator moves down",
          "The trim tab moves down, the elevator moves up",
          "The trim tab moves down, the elevator moves down",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 33,
        question:
          "When trimming an aircraft nose up, in which direction does the trim tab move?",
        answers: [
          "Depends on CG position",
          "It moves up",
          "In direction of rudder deflection",
          "It moves down",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 34,
        question:
          "How does a balance tab move in relation to the flight control surface that it is coupled with?",
        answers: [
          "In the opposite direction",
          "At an angle of 90°",
          "At an angle of 45°",
          "In the same direction",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 35,
        question: "The trim is used to...",
        answers: [
          "increase adverse yaw.",
          "move the centre of gravity.",
          "adapt the control force.",
          "lock control elements.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 36,
        question:
          "Which of the following are an aircraft's secondary flight controls?",
        answers: [
          "Elevator, rudder, aileron",
          "All movable parts on the aircraft which aid in controlling the aircraft",
          "Elevator, rudder, aileron, trim tabs, high-lift wing devices, power controls",
          "Wing flaps, leading edge devices, spoilers or speedbrakes, trim systems",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 37,
        question: "What has to be considered during refueling?",
        answers: [
          "Check the fuel content of the tank with a torch and remove fire protection",
          "No open fires, obey smoking ban and apply ground cables",
          "Refuel through a soaked rag and keep a fire extinguisher available",
          "Apply ground wires, turn on the main switch and magneto ignition",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 38,
        question: "The primer is...",
        answers: [
          "an auxiliary pump in the fuel system to facilitate engine starting.",
          "a mechanical switch in the cockpit to engage the turbocharger.",
          "a valve in the fuel control system for automatic mixture regulation.",
          "a nozzle in the Venturi tube of a carburettor for atomising the fuel.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 39,
        question: "What is the purpose of the tank ventilation?",
        answers: [
          "To prevent underpressure caused by fuel consumption",
          "To prevent water disposal during parking",
          "To distribute the fuel from one tank segment to the other during flight",
          "To prevent fuel spillage during refueling on the filler plug",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 40,
        question:
          "An aeroplane's current supply is carried out by the:\n1. Battery\n2. Generator\n3. Relay\n4. Circuit breaker",
        answers: ["1 and 4", "3 and 4", "2 and 3", "1 and 2"],
        correct: 3,
        points: 1,
      },
      {
        num: 41,
        question: "What is the unit for voltage?",
        answers: ["Ampere", "Watt", "Ohm", "Volt"],
        correct: 3,
        points: 1,
      },
      {
        num: 42,
        question: "What is the unit for electrical power?",
        answers: ["Ampere", "Ohm", "Watt", "Volt"],
        correct: 2,
        points: 1,
      },
      {
        num: 43,
        question: "What is the reason for static dischargers on aircraft?",
        answers: [
          "To ensure grounding during refueling",
          "To discharge static charging during flight",
          "To eliminate electrical interferences during intensive radio traffic",
          "To improve the quality of radio transmission in high altitudes",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 44,
        question:
          "What must be considered if the alternator fails in a helicopter?",
        answers: [
          "All instruments and warning systems will fail",
          "No change as long as the battery provides enough power",
          "The engine runs roughly and is prone to knocking",
          "Only high power consumers will failing",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 45,
        question:
          "When using direct current, electrically driven flight instruments are marked with...",
        answers: ['"EL".', '"DC".', '"AL".', '"CO".'],
        correct: 1,
        points: 1,
      },
      {
        num: 46,
        question:
          "What devices can be affected by a failure of the electrical system in a helicopter?",
        answers: [
          "Fuel quantity indication, radio equipment and altimeter",
          "Radio equipment, navigation equipment and magnetic compass",
          "Airspeed indicator, altimeter and artificial horizon",
          "Radio equipment, navigation equipment and gyros",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 47,
        question:
          "Which cylinder arragement is commonly used on small aircrafts and motor gliders?",
        answers: [
          "Horizontally opposed engine",
          "Radial engine",
          "In-line engine",
          "V-type engine",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 48,
        question:
          "Which part of the cycle of a four-stroke Otto engine can be seen in the attachment?",
        answers: [
          "Second stroke - compression",
          "Fourth stroke - exhaust",
          "First stroke - induction",
          "Third stroke - power",
        ],
        correct: 2,
        points: 1,
        figure: "AGK-007",
      },
      {
        num: 49,
        question:
          "Which part of the cycle of a four-stroke Otto engine can be seen in the attachment?",
        answers: [
          "Second stroke - compression",
          "Third stroke - power",
          "Fourth stroke - exhaust",
          "First stroke - induction",
        ],
        correct: 0,
        points: 1,
        figure: "AGK-008",
      },
      {
        num: 50,
        question:
          "What is likely to be the cause if the engine runs unusually rough while checking the magnetos?",
        answers: [
          "Starter is faulty",
          "Sparking plug is defective",
          "Short circuit on the ground cable",
          "Ignition switch is faulty",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 51,
        question:
          "The highest absorbtion of humidity in fuel can be observed in which situation?",
        answers: [
          "During parking on wet gras areas",
          "During parking on cold aprons",
          "Almost empty tanks",
          "Almost full tanks",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 52,
        question: "Where does the condensation water converge in the tank?",
        answers: [
          "It floats on the fuel",
          "Near the cap of the tank",
          "At the lowest position",
          "It is mixed with the fuel",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 53,
        question: "What does the octane rating or fuel grade describe?",
        answers: [
          "Anti-knock rating",
          "Flame front speed",
          "Ignition timing",
          "Combustion temperature",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 54,
        question: "Which colour does Avgas 100 LL have?",
        answers: ["Red", "Green", "Yellow", "Blue"],
        correct: 3,
        points: 1,
      },
      {
        num: 55,
        question:
          "What is the direct influence by switching on the carburettor heating on fixed propeller engines during engine run-up?",
        answers: [
          "RPM decreases",
          "Angle of attack decreases",
          "Angle of attack increases",
          "RPM increaes",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 56,
        question: "What is the major task of a carburettor?",
        answers: [
          "To pump fuel from the tanks into the cylinder",
          "To control the aircraft's speed through the throttle valve",
          "To provide additional fuel to cool the engine",
          "To produce an ignitable air/fuel mixture",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 57,
        question:
          "In which phase of flight must the carburettor heating be switched off although carburettor icing might be expected?",
        answers: [
          "During climb",
          "During cruise",
          "During taxi",
          "During take-off",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 58,
        question: "In which outside air temperature is icing most likely?",
        answers: [
          "Between -5° C and +20° C",
          "Between -10° C and +10° C",
          "Between -20° C and +5° C",
          "Between -15° C and 0° C",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 59,
        question:
          "What is the task of cooling fins on air-cooled engine cylinders?",
        answers: [
          "Quick heat transfer to the surrounding air flow by expanding the surface",
          "Cooling of the cylinder surrounding airflow and forwarding to hotter engine parts",
          "Leading the airflow to parts designated to be cooled",
          "Increasing the airflow thus improving cooling of cylinder parts",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 60,
        question: "Cylinder head temperature indication relates to...",
        answers: [
          "all Cylinders.",
          "a random cylinder.",
          "the critical cylinder.",
          "the average of all cylinders.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 61,
        question: "What happens during oil filter clocking?",
        answers: [
          "A bypass valve opens thus enabling the circulation to continue, debris will be filtered by an alternate filter",
          "The oil circulation will end after 15 minutes so that a proper engine run will not be guaranteed",
          "A bypass valve opens thus enabling the circulation to continue, debris will not be filtered",
          "The oil circulation will end after 30 minutes so that a proper engine run will not be guaranteed",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 62,
        question: "How should an Otto engine be shut down?",
        answers: [
          "By completely leaning the mixture",
          "By moving the propeller to the feathered position",
          "By closing the throttle lever completely",
          "By shutting down the generator",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 63,
        question: "Piston engines of helicopters have...",
        answers: [
          "one magneto ignition system.",
          "one electrical ignition system.",
          "two coupled ignition systems.",
          "two independent ignition systems.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 64,
        question: "How do you call fuel mixtures with a high amount of fuel?",
        answers: ["Lean", "Full", "Empty", "Rich"],
        correct: 3,
        points: 1,
      },
      {
        num: 65,
        question:
          "The angle indicated by arrow number 1 shows the propeller's...\nD: Direction of air flow\nC: Chord line\nR: Direction of rotation",
        answers: [
          "angle of incidence.",
          "angle of attack.",
          "geometric wing twist.",
          "aerodynamic wing twist.",
        ],
        correct: 0,
        points: 1,
        figure: "AGK-011",
      },
      {
        num: 66,
        question:
          "How should a power increase be executed on a constant-speed propeller, provided that no other procedure is described in the flight manual?",
        answers: [
          "1) Decrease manifold pressure\n2) Increase RPM",
          "1) Decrease RPM\n2) Increase manifold pressure",
          "1) Increase manifold pressure\n2) Increase RPM",
          "1) Increase RPM\n2) Increase manifold pressure",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 67,
        question:
          "How should a power decrease be executed on a constant-speed propeller, provided that no other procedure is described in the flight manual?",
        answers: [
          "1) Decrease RPM\n2) Decrease manifold pressure",
          "1) Decrease manifold pressure\n2) Increase RPM",
          "1) Decrease RPM\n2) Increase manifold pressure",
          "1) Decrease manifold pressure\n2) Decrease RPM",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 68,
        question:
          "With increasing altitude and unchanged mixture setting, the air/fuel mixture...",
        answers: [
          "becomes more lean.",
          "stays constant.",
          "becomes liquid.",
          "becomes richer.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 69,
        question:
          "With increasing altitude, the power of a carburettor engine...",
        answers: [
          "remains constant.",
          "first decreases, from 5000 ft on increases.",
          "increases.",
          "decreases.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 70,
        question:
          "During ignition check the ignition is switched to OFF for a short moment and subsequently back to BOTH.\nWhat happens to the RPM if the ignition system is properly grounded?",
        answers: [
          "The RPM increases once switched to OFF and resumes a value below the previous one when switched back to BOTH",
          "The RPM decreases once switched to OFF and resumes the previous value when switched back to BOTH",
          "The RPM increases once switched to OFF and resumes the previous value when switched back to BOTH",
          "The RPM decreases once switched to OFF and resumes a value below the previous one when switched back to BOTH",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 71,
        question: "Which gauges involve a measurement of temperature?",
        answers: [
          "Engine lubricant, exhaust gas, outside air, cabin air, directional gyro",
          "Engine lubricant, cylinder head, exhaust gas, suction, outside air, cabin air",
          "Engine lubricant, engine cooling fluid, cylinder head, exhaust gas, outside air, cabin air",
          "Engine oil, cylinder head, exhaust gas, altimeter, airspeed indicator, vertical speed indicator",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 72,
        question:
          "Fuel quantity in small aircraft is most commonly measured through a level sensor. The European Certification Specifications CS 23 require that...",
        answers: [
          "at least one fuel quantity indicator must be available to indicate the total amount of fuel abord an aircraft. This Indicator must be calibrated adequately to indicate the correct amount of fuel during all phases of flight.",
          "if the fuel indicator fails, the pilot must land at the next available airport.",
          "if no fuel quantity indicator is available to the pilot in flight, the pilot must check the fuel quantity before commencing the flight and recalculate the remaining fuel on board in regular intervals during the flight.",
          'a fuel quantity indicator must be available for each tank and the indicator must be calibrated to read "zero" during level flight when the quantity of fuel remaining in the tank is equal to the unusable fuel supply.',
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 73,
        question:
          "Which of the instruments listed below obtain their readings through pressure measurement?",
        answers: [
          "Oil pressure gauge, fuel pressure gauge, manifold pressure gauge, altimeter, vertical speed indicator, airspeed indicator, suction gauge",
          "Oil pressure gauge, fuel pressure gauge, fuel quantity gauge, manifold pressure gauge, differential pressure gauge, altimeter",
          "Airspeed indicator, vertical speed indicator, altimeter, directional gyro, turn and bank coordinator, oil pressure gauge, fuel pressure gauge",
          "Airspeed indicator, vertical speed indicator, altimeter, magnetic compass, oil pressure gauge, fuel pressure gauge",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 74,
        question: "The Pitot / static system is required to...",
        answers: [
          "prevent potential static buildup on the aircraft.",
          "correct the reading of the airspeed indicator to zero when the aircraft is static on the ground.",
          "measure total and static air pressure.",
          "prevent icing of the Pitot tube.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 75,
        question: "Which pressure is sensed by the Pitot tube?",
        answers: [
          "Cabin air pressure",
          "Total air pressure",
          "Dynamic air pressure",
          "Static air pressure",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 76,
        question: "QFE is the...",
        answers: [
          "barometric pressure at a reference datum, typically the runway threshold of an airfield.",
          "barometric pressure adjusted to sea level, using the international standard atmosphere (ISA).",
          "magnetic bearing to a station.",
          "altitude above the reference pressure level 1013.25 hPa.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 77,
        question: "QNE is the...",
        answers: [
          "barometric pressure at a reference datum, typically the runway threshold of an airfield.",
          "magnetic bearing to a station.",
          "barometric pressure adjusted to sea level, using the international standard atmosphere (ISA).",
          "altitude above the reference pressure level 1013.25 hPa.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 78,
        question: "Which is the purpose of the altimeter subscale?",
        answers: [
          "To set the reference level for the altitude decoder of the transponder",
          "To adjust the altimeter reading for non-standard temperature",
          "To reference the altimeter reading to a predetermined level such as mean sea level, aerodrome level or pressure level 1013.25 hPa",
          "To correct the altimeter reading for system errors",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 79,
        question:
          "In which way may an altimeter subscale which is set to an incorrect QNH lead to an incorrect altimeter reading?",
        answers: [
          "If the subscale is set to a lower than actual pressure, the indication is too high. This may lead to much closer proximity to the ground than intended",
          "If the subscale is set to a higher than actual pressure, the indication is too high. This may lead to much closer proximity to the ground than intended",
          "If the subscale is set to a lower than actual pressure, the indication is too low. This may lead to much closer proximity to the ground than intended",
          "If the subscale is set to a higher than actual pressure, the indication is too low. This may lead to much greater heights above the ground than intended",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 80,
        question: "Lower-than-standard temperature may lead to...",
        answers: [
          "a blockage of the Pitot tube by ice, freezing the altimeter indication to its present value.",
          "an altitude indication which is too low.",
          "an altitude indication which is too high.",
          "a correct altitude indication as long as the altimeter subscale is set to correct for non-standard temperature.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 81,
        question: "A flight level is a...",
        answers: [
          "pressure altitude.",
          "density altitude.",
          "altitude above ground.",
          "true altitude.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 82,
        question: "A true altitude is...",
        answers: [
          "a height above ground level corrected for non-standard pressure.",
          "a height above ground level corrected for non-standard temperature.",
          "a pressure altitude corrected for non-standard temperature.",
          "an altitude above mean sea level corrected for non-standard temperature.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 83,
        question:
          "During a flight in colder-than-ISA air the indicated altitude is...",
        answers: [
          "higher than the true altitude.",
          "lower than the true altitude.",
          "eqal to the true altitude.",
          "equal to the standard altitude.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 84,
        question:
          "During a flight in an air mass with a temperature equal to ISA and the QNH set correctly, the indicated altitude is...",
        answers: [
          "lower than the true altitude.",
          "equal to the true altitude.",
          "equal to the standard atmosphere.",
          "higher than the true altitude.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 85,
        question: "Which instrument can be affected by the hysteresis error?",
        answers: [
          "Vertical speed indicator",
          "Altimeter",
          "Direct reading compass",
          "Tachometer",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 86,
        question:
          "The measurement of altitude is based on the change of the...",
        answers: [
          "dynamic pressure.",
          "differential pressure.",
          "static pressure.",
          "total pressure.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 87,
        question:
          "Which of the following options states the working principle of a vertical speed indicator?",
        answers: [
          "Measuring the present static air pressure and comparing it to the static air pressure inside a reservoir",
          "Total air pressure is measured and compared to static pressure",
          "Measuring the vertical acceleration through the displacement of a gimbal-mounted mass",
          "Static air pressure is measured and compared against a vacuum",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 88,
        question:
          "The vertical speed indicator measures the difference of pressure between...",
        answers: [
          "the present total pressure and the total pressure of a previous moment.",
          "the present dynamic pressure and the dynamic pressure of a previous moment.",
          "the present dynamic pressure and the static pressure of a previous moment.",
          "the present static pressure and the static pressure of a previous moment.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 89,
        question: "Calibrated airspeed (CAS) equals...",
        answers: [
          "equivalent airspeed (EAS) corrected for altitude.",
          "indicated airspeed (IAS) corrected for instrument and position error.",
          "ground speed (GS) corrected for instrument and position error.",
          "true airspeed (TAS) corrected for wind.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 90,
        question:
          "At higher altitudes, true airspeed (TAS) tends to be higher than calibrated airspeed (CAS).\nA rough estimate of the TAS can be obtained by...",
        answers: [
          "subtracting 2 % of the CAS for every 1000 ft altitude.",
          "adding 10 % of the CAS for every 1000 ft altitude.",
          "subtracting 10 % of the CAS for every 1000 m altitude.",
          "adding 2 % of the CAS for every 1000 ft altitude.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 91,
        question:
          "An aircraft cruises on a heading of 180° with a true airspeed of 100 kt. The wind comes from 180° with 30 kt.\nNeglecting instrument and position errors, which will be the approximate reading of the airspeed indicator?",
        answers: ["100 kt", "130 kt", "30 kt", "70 kt"],
        correct: 0,
        points: 1,
      },
      {
        num: 92,
        question:
          "Which of the following factors could cause an erroneous airspeed indication?",
        answers: [
          "A circuit breaker was pulled",
          "The earth wire is still attached",
          "The boost pressure line is defective",
          "The aircraft is covered with adhesive foils",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 93,
        question:
          "Which of the following states the working principle of an airspeed indicator?",
        answers: [
          "Total air pressure is measured and compared against static air pressure.",
          "Total air pressure is measured by the static ports and converted into a speed indication by the airspeed indicator",
          "Dynamic air pressure is measured by the Pitot tube and converted into a speed indication by the airspeed indicator",
          "Static air pressure is measured and compared against a vacuum.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 94,
        question:
          "What values are usually marked with a red line on instrument displays?",
        answers: [
          "Operational limits",
          "Caution areas",
          "Recommended areas",
          "Operational areas",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 95,
        question:
          "What is necessary for the determination of speed (IAS) by the airspeed indicator?",
        answers: [
          "The difference between the total pressure and the dynamic pressure",
          "The difference between the standard pressure and the total pressure",
          "The difference betweeen the total pressure and the static presssure",
          "The difference between the dynamic pressure and the static pressure",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 96,
        question:
          "What is the meaning of the white arc on the airspeed indicator?",
        answers: [
          "Speed range in bumpy air",
          "Speed range in smooth air",
          "Speed range for extended flaps",
          "Speed range not to exceed",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 97,
        question:
          "What is the meaning of the red range on the airspeed indicator?",
        answers: [
          "Speed which must not be exceeded within bumpy air",
          "Speed which must not be exceeded with flaps extended",
          "Speed which must not be exceeded regardless of circumstances",
          "Speed which must not be exceeded in turns with more than 45° bank",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 98,
        question:
          "The compass error caused by the aircraft's magnetic field is called...",
        answers: ["variation.", "declination.", "inclination.", "deviation."],
        correct: 3,
        points: 1,
      },
      {
        num: 99,
        question:
          "The indication of a magnetic compass deviates from magnetic north direction due to what errors?",
        answers: [
          "Deviation, turning and acceleration errors",
          "Gravity and magnetism",
          "Inclination and declination of the earth's magnetic field",
          "Variation, turning and acceleration errors",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 100,
        question:
          "Which of the mentioned cockpit instruments is connected to the pitot tube?",
        answers: [
          "Vertical speed indicator",
          "Direct-reading compass",
          "Altimeter",
          "Airspeed indicator",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 101,
        question: "Which cockpit instruments are connected to the static port?",
        answers: [
          "Altimeter, vertical speed indicator, airspeed indicator",
          "Airspeed indicator, direct-reading compass, slip indicator",
          "Altimeter, slip indicator, navigational computer",
          "Airspeed indicator, altimeter, direct-reading compass",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 102,
        question:
          "An aircraft in the northern hemisphere intends to turn on the shortest way from a heading of 270° to a heading of 360°.\nAt approximately which indication of the magnetic compass should the turn be terminated?",
        answers: ["330°", "360°", "270°", "030°"],
        correct: 0,
        points: 1,
      },
      {
        num: 103,
        question:
          "An aircraft in the northern hemisphere intends to turn on the shortest way from a heading of 360° to a heading of 270°.\nAt approximately which indication of the magnetic compass should the turn be terminated?",
        answers: ["300°", "360°", "240°", "270°"],
        correct: 3,
        points: 1,
      },
      {
        num: 104,
        question: 'The term "static pressure" is defined as pressure...',
        answers: [
          "inside the airplane cabin.",
          "of undisturbed airflow.",
          "resulting from orderly flow of air particles.",
          "sensed by the pitot tube.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 105,
        question:
          "An aircraft in the northern hemisphere intends to turn on the shortest way from a heading of 030° to a heading of 180°.\nAt approximately which indicated magnetic heading should the turn be terminated?",
        answers: ["360°.", "150°.", "210°.", "180°."],
        correct: 2,
        points: 1,
      },
      {
        num: 106,
        question: "What does the dynamic pressure depend directly on?",
        answers: [
          "Air pressure and air temperature",
          "Air density and airflow speed squared",
          "Air density and lift coefficient",
          "Lift- and drag coefficient",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 107,
        question:
          "What is a cause for the dip error on the direct-reading compass?",
        answers: [
          "Deviation in the cockpit",
          "Acceleration of the airplane",
          "Inclination of earth's magnetic field lines",
          "Temperature variations",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 108,
        question: "What behaviour is shown by a rotating gyro in space?",
        answers: [
          "It moves in accordance with the body surrounding it",
          "It tends to maintain its position in space",
          "It swings from east to west like a pendulum",
          "It moves in circles with a stadily decreasing radius",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 109,
        question:
          "A gyro which is rotating in space responds to forces being applied to it by an evasive movement...",
        answers: [
          "at an angle of 45° to the force being applied.",
          "in a northern direction.",
          "at an angle of 90° to the force being applied.",
          "at an angle of 180° to the force being applied.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 110,
        question: "The bank angle of a 2-minutes circle depends on the...",
        answers: ["CAS.", "IAS.", "Ground speed.", "TAS."],
        correct: 3,
        points: 1,
      },
      {
        num: 111,
        question:
          "A turn and bank (or turn and slip) coordinator provides information regarding...",
        answers: [
          "the coordination of the turn and slip angle.",
          "the rate of turn and coordination, i.e. slip or skid, of the turn.",
          "the pitch and bank angle of the aircraft.",
          "the rate of turn and bank angle of the aircraft.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 112,
        question:
          "The airspeed indicator is unservicable. The airplane may only be operated...",
        answers: [
          "when the airspeed indicator is fully functional again.",
          "when a GPS with speed indication is used during flight.",
          "if no maintenance organisation is around.",
          "if only airfield patterns are flown.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 113,
        question:
          'Which light colour advises the pilot to the condition "corrective action may be required in the future"?',
        answers: ["Amber (orange)", "Red", "Blue", "Green"],
        correct: 0,
        points: 1,
      },
      {
        num: 114,
        question:
          "The Caution Area is marked on an airspeed indicator by what color?",
        answers: ["Green", "Red", "Yellow", "White"],
        correct: 2,
        points: 1,
      },
      {
        num: 115,
        question:
          "An attitude director indicator (ADI) combines the information provided by...",
        answers: [
          "the attitude indicator and the flight director.",
          "the directional gyro and the rate gyro.",
          "the attitude indicator and the relative bearing indicator.",
          "the attitude indicator and the radio magnetic indicator.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 116,
        question: "What does the abbreviation HSI stand for?",
        answers: [
          "Horizontal Situation Indicator",
          "Hybernating System Indication",
          "Horizontal Slip Indicator",
          "Horrifying Steep Inclination",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 117,
        question:
          "A horizontal situation indicator (HSI) combines the information provided by...",
        answers: [
          "the directional gyro and the flight director.",
          "the rate gyro and the slip indicator.",
          "the directional gyro and the VHF navigation receiver.",
          "the attitude indicator and the flight director.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 118,
        question:
          "What difference in altitude is shown by an altimeter, if the reference pressure scale setting is changed from 1000 hPa to 1010 hPa?",
        answers: [
          "80 m less than before",
          "Values depending on QNH",
          "Zero",
          "80 m more than before",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 119,
        question:
          "When is it necessary to adjust the pressure in the reference scale of an alitimeter?",
        answers: [
          "Before every flight and during cross country flights",
          "Every day before the first flight",
          "Once a month before flight operation",
          "After maintance has been finished",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 120,
        question:
          "The altimeter's reference scale is set to airfield pressure (QFE). What indication is shown during the flight?",
        answers: [
          "Pressure altitude",
          "Height above airfield",
          "Airfield elevation",
          "Altitude above MSL",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 121,
        question:
          "A vertical speed indicator connected to a too big equalizing tank results in...",
        answers: [
          "no indication",
          "mechanical overload",
          "indication too low",
          "indication too high",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 122,
        question:
          "A vertical speed indicator measures the difference between...",
        answers: [
          "instantaneous total pressure and previous total pressure.",
          "total pressure and static pressure.",
          "instantaneous static pressure and previous static pressure.",
          "dynamic pressure and total pressure.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 123,
        question: 'The term "inclination" is defined as...',
        answers: [
          "deviation induced by electrical fields.",
          "angle between airplane's longitudinal axis and true north.",
          "angle between earth's magnetic field lines and horizontal plane.",
          "angle between magnetic and true north.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 124,
        question: "What does the slip indicator show?",
        answers: [
          "Apparent vertical",
          "Rate of turn",
          "Vertical to horizon",
          "Airplane's bank",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 125,
        question:
          "The slip indicator's ball (apparent vertical) has moved to the right during a right turn. By what actions may the ball led back to middle position?",
        answers: [
          "Reduce bank, increase rate of turn",
          "Increase bank, increase rate of turn",
          "Reduce speed, increase bank",
          "Reduce rate of turn, reduce bank",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 126,
        question: "What is the purpose of winglets?",
        answers: [
          "Increase gliging performance at high speed.",
          "Increase of lift and turning manoeuvering capabilities.",
          "To increase efficiency of aspect ratio.",
          "Reduction of induced drag.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 127,
        question:
          "What engines are commonly used with Touring Motor Gliders (TMG)?",
        answers: [
          "4 Cylinder; 4 stroke",
          "4 Cylinder 2 stroke",
          "2 Cylinder Diesel",
          "2 plate Wankel",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 128,
        question: "What is the purpose of engine oil in piston engine?",
        answers: [
          "Cooling, lubrication of the engine and disposal of fine abrasion.",
          "Cooling of the engine and lubrication of propeller shaft.",
          "For nose reduction and bearing of the propeller shaft.",
          "Cooling, lubrication and increasing engine performance.",
        ],
        correct: 0,
        points: 1,
      },
    ],
  },
  {
    num: 90,
    group: "Navigation",
    questions: [
      {
        num: 1,
        question: "The rotational axis of the Earth runs through the...",
        answers: [
          "geographic North Pole and on the geographic South Pole.",
          "geographic North Pole and on the magnetic south pole.",
          "magnetic north pole and on the magnetic south pole.",
          "magnetic north pole and on the geographic South Pole.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 2,
        question:
          "Which statement is correct with regard to the polar axis of the Earth?",
        answers: [
          "The polar axis of the Earth crosses the geographic South Pole and the geographic North Pole and is perpendicular to the plane of the equator",
          "The polar axis of the Earth crosses the geographic South Pole and the geographic North Pole and is at an angle of 23.5° to the plane of the equator",
          "The polar axis of the Earth crosses the magnetic south pole and the magnetic north pole and is perpendicular to the plane of the equator",
          "The polar axis of the Earth crosses the magnetic south pole and the magnetic north pole and is at an angle of 66.5° to the plane of the equator",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 3,
        question:
          "Which approximate, geometrical form describes the shape of the Earth best for navigation systems?",
        answers: [
          "Sphere of ecliptical shape",
          "Ellipsoid",
          "Perfect sphere",
          "Flat plate",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 4,
        question: "Which statement about a rhumb line is correct?",
        answers: [
          "A rhumb line cuts each meridian at the same angle.",
          "The center of a complete cycle of a rhumb line is always the Earth's center.",
          "The shortest track between two points along the Earth's surface follows a rhumb line.",
          "A rhumb line is a great circle intersecting the the equator with 45° angle.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 5,
        question:
          "The shortest distance between two points on Earth is represented by a part of...",
        answers: [
          "a great circle.",
          "a small circle.",
          "a rhumb line.",
          "a parallel of latitude. ",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 6,
        question:
          "The circumference of the Earth at the equator is approximately...",
        answers: ["10800 km.", "40000 NM.", "12800 km.", "21600 NM."],
        correct: 3,
        points: 1,
        figure: "NAV-002",
      },
      {
        num: 7,
        question:
          "What is the difference in latitude between A (12°53'30''N) and B (07°34'30''S)?",
        answers: ["20°28'00''", "05,19°", "20,28°", "05°19'00''"],
        correct: 0,
        points: 1,
      },
      {
        num: 8,
        question: "Where are the two polar circles?",
        answers: [
          "At a latitude of 20.5°S and 20.5°N",
          "23.5° north and south of the poles",
          "23.5° north and south of the equator",
          "20.5° south of the poles",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 9,
        question:
          "What is the distance between the parallels of latitude 48°N and 49°N along a meridian line?",
        answers: ["1 NM", "60 NM", "111 NM", "10 NM"],
        correct: 1,
        points: 1,
      },
      {
        num: 10,
        question:
          "What distance corresponds to one degree difference in latitude along any degree of longitude?",
        answers: ["1 NM", "60 NM", "30 NM", "60 km"],
        correct: 1,
        points: 1,
      },
      {
        num: 11,
        question:
          "Point A on the Earth's surface lies exactly on the parallel of latitude of 47°50'27''N. Which point is exactly 240 NM north of A?",
        answers: ["43°50'27''N", "53°50'27''N", "49°50'27''N", "51°50'27'N'"],
        correct: 3,
        points: 1,
      },
      {
        num: 12,
        question:
          "What is the distance between the two parallels of longitude 150°E and 151°E along the equator?",
        answers: ["60 km", "111 NM", "60 NM", "1 NM"],
        correct: 2,
        points: 1,
      },
      {
        num: 13,
        question:
          "What is the great circle distance between two points A and B on the equator when the difference between the two associated meridians is exactly one degree of longitude?",
        answers: ["60 NM", "400 NM", "120 NM", "216 NM"],
        correct: 0,
        points: 1,
      },
      {
        num: 14,
        question:
          "Assume two arbitrary points A and B on the same parallel of latitude, but not on the equator. Point A is located on 010°E and point B on 020°E.\nThe rumb line distance between A and B is always...",
        answers: [
          "more than 600 NM.",
          "less than 600 NM.",
          "more than 300 NM.",
          "less than 300 NM.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 15,
        question:
          "What is the difference in time when the sun moves 20° of longitude?",
        answers: ["1:20 h", "0:20 h", "0:40 h", "1:00 h"],
        correct: 0,
        points: 1,
      },
      {
        num: 16,
        question:
          "What is the difference in time when the sun moves 10° of longitude?",
        answers: ["0:30 h", "0:04 h", "0:40 h", "1:00 h"],
        correct: 2,
        points: 1,
      },
      {
        num: 17,
        question:
          "The sun moves 10° of longitude. What is the difference in time?",
        answers: ["0.4 h", "1 h", "0.33 h", "0.66 h"],
        correct: 3,
        points: 1,
      },
      {
        num: 18,
        question:
          "With Central European Summer Time (CEST) given as UTC+2, what UTC time corresponds to 1600 CEST?",
        answers: ["1600 UTC.", "1500 UTC.", "1700 UTC.", "1400 UTC."],
        correct: 3,
        points: 1,
      },
      {
        num: 19,
        question: "UTC is...",
        answers: [
          "a zonal time.",
          "a local time in Central Europe.",
          "local mean time at a specific point on Earth.",
          "an obligatory time used in aviation.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 20,
        question:
          "With Central European Time (CET) given as UTC+1, what UTC time corresponds to 1700 CET?",
        answers: ["1600 UTC.", "1500 UTC.", "1700 UTC.", "1800 UTC."],
        correct: 0,
        points: 1,
      },
      {
        num: 21,
        question:
          "Vienna (LOWW) is located at 016° 34'E, Salzburg (LOWS) at 013° 00'E. The latitude of both positions can be considered as equal.\nWhat is the difference of sunrise and sunset times, expressed in UTC, between Wien and Salzburg?",
        answers: [
          "In Vienna the sunrise and sunset are about 14 minutes earlier than in Salzburg",
          "In Vienna the sunrise is 14 minutes earlier and sunset is 14 minutes later than in Salzburg",
          "In Vienna the sunrise and sunset are about 4 minutes later than in Salzburg",
          "In Vienna the sunrise is 4 minutes later and sunset is 4 minutes earlier than in Salzburg",
        ],
        correct: 0,
        points: 2,
      },
      {
        num: 22,
        question: "The term 'civil twilight' is defined as...",
        answers: [
          "the period of time before sunrise or after sunset where the midpoint of the sun disk is 6 degrees or less below the true horizon.",
          "the period of time before sunrise or after sunset where the midpoint of the sun disk is 6 degrees or less below the apparent horizon.",
          "the period of time before sunrise or after sunset where the midpoint of the sun disk is 12 degrees or less below the true horizon.",
          "the period of time before sunrise or after sunset where the midpoint of the sun disk is 12 degrees or less below the apparent horizon.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 23,
        question:
          "Given:\nWCA: -012°; TH: 125°; MC: 139°; DEV: 002°E\nWhat are: TC, MH und CH?",
        answers: [
          "TC: 113°.\nMH: 127°.\nCH: 129°.",
          "TC: 137°.\nMH: 127°.\nCH: 125°.",
          "TC: 137°.\nMH: 139°.\nCH: 125°.",
          "TC: 113°.\nMH: 139°.\nCH: 129°.",
        ],
        correct: 1,
        points: 2,
      },
      {
        num: 24,
        question:
          "Given:\nTC: 179°; WCA: -12°; VAR: 004° E; DEV: +002°\nWhat are MH and MC?",
        answers: [
          "MH: 167°.\nMC: 175°.",
          "MH: 167°.\nMC: 161°.",
          "MH: 163°.\nMC: 175°.",
          "MH: 163°.\nMC: 161°.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 25,
        question:
          "The angle between the true course and the true heading is called...",
        answers: ["WCA.", "deviation.", "variation.", "inclination."],
        correct: 0,
        points: 1,
      },
      {
        num: 26,
        question:
          "The angle between the magnetic course and the true course is called...",
        answers: ["deviation.", "WCA.", "variation.", "inclination."],
        correct: 2,
        points: 1,
      },
      {
        num: 27,
        question: "The term ‚magnetic course' (MC) is defined as...",
        answers: [
          "the angle between magnetic north and the course line.",
          "the angle between true north and the course line.",
          "the direction from an arbitrary point on Earth to the geographic North Pole.",
          "the direction from an arbitrary point on Earth to the magnetic north pole.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 28,
        question: "The term 'True Course' (TC) is defined as...",
        answers: [
          "tthe angle between magnetic north and the course line.",
          "the direction from an arbitrary point on Earth to the geographic North Pole.",
          "the angle between true north and the course line.",
          "the direction from an arbitrary point on Earth to the magnetic north pole.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 29,
        question:
          "Given:\nTC: 183°; WCA: +011°; MH: 198°; CH: 200°\nWhat are TH and VAR?",
        answers: [
          "TH: 194°.\nVAR: 004° W",
          "TH: 194°.\nVAR: 004° E",
          "TH: 172°.\nVAR: 004° W",
          "TH: 172°.\nVAR: 004° E",
        ],
        correct: 0,
        points: 2,
      },
      {
        num: 30,
        question:
          "Given:\nTC: 183°; WCA: +011°; MH: 198°; CH: 200°\nWhat are the TH and the DEV?",
        answers: [
          "TH: 172°.\nDEV: +002°.",
          "TH: 172°.\nDEV: -002°.",
          "TH: 194°.\nDEV: -002°.",
          "TH: 194°.\nDEV: +002°.",
        ],
        correct: 2,
        points: 2,
      },
      {
        num: 31,
        question:
          "Given:\nTC: 183°; WCA: +011°; MH: 198°; CH: 200°\nWhat are the VAR and the DEV?",
        answers: [
          "VAR: 004° W.\nDEV: +002°.",
          "VAR: 004° W.\nDEV: -002°.",
          "VAR: 004° E.\nDEV: +002°.",
          "VAR: 004° E.\nDEV: -002°.",
        ],
        correct: 1,
        points: 2,
      },
      {
        num: 32,
        question: "Where does the inclination reach its lowest value?",
        answers: [
          "At the magnetic equator",
          "At the magnetic poles",
          "At the geographic equator",
          "At the geographic poles",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 33,
        question:
          "The angle between compass north and magnetic north is called...",
        answers: ["WCA.", "variation.", "inclination.", "deviation."],
        correct: 3,
        points: 1,
      },
      {
        num: 34,
        question: "Which direction corresponds to 'compass north' (CN)?",
        answers: [
          "The most northerly part of the magnetic compass in the aircraft, where the reading takes place",
          "The direction from an arbitrary point on Earth to the geographical North Pole",
          "The angle between the aircraft heading and magnetic north",
          "The direction to which the direct reading compass aligns due to earth's and aircraft's magnetic fields",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 35,
        question:
          "The term 'isogonal' or 'isogonic line' is defined as a line on an aeronautical chart, connecting all points with the same value of...",
        answers: ["inclination.", "deviation.", "heading.", "variation."],
        correct: 3,
        points: 1,
      },
      {
        num: 36,
        question:
          "The term 'agonic line' is defined as a line on Earth or an aeronautical chart, connecting all points with the...",
        answers: [
          "deviation of 0°.",
          "inclination of 0°.",
          "heading of 0°.",
          "variation of 0°.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 37,
        question:
          "Which are the official basic units for horizontal distances used in aeronautical navigation and their abbreviations?",
        answers: [
          "feet (ft), inches (in)",
          "Nautical miles (NM), kilometers (km)",
          "Yards (yd), meters (m)",
          "Land miles (SM), sea miles (NM)",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 38,
        question: "1000 ft equal...",
        answers: ["30 km.", "3000 m.", "300 m.", "30 m."],
        correct: 2,
        points: 1,
      },
      {
        num: 39,
        question: "5500 m equal...",
        answers: ["7500 ft.", "18000 ft.", "10000 ft.", "30000 ft."],
        correct: 1,
        points: 1,
      },
      {
        num: 40,
        question:
          "Which of the items on the attached checklist are related to the direct reading compass?",
        answers: [
          '"Turning Instruments" only',
          '"Gyro" and "Circuit Breaker"',
          '"Gyro" and "Turning Instruments"',
          '"Turning Instruments" and "Circuit Breaker"',
        ],
        correct: 2,
        points: 1,
        figure: "NAV-004",
      },
      {
        num: 41,
        question:
          "What could be a reason for changing the runway indicators at aerodromes (e.g. from runway 06 to runway 07)?",
        answers: [
          "The magnetic deviation of the runway location has changed",
          "The magnetic variation of the runway location has changed",
          "The direction of the approach path has changed",
          "The true direction of the runway alignment has changed",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 42,
        question:
          "Electronic devices on board of an aeroplane have influence on the...",
        answers: [
          "turn coordinator.",
          "airspeed indicator.",
          "artificial horizon.",
          "direct reading compass.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 43,
        question: "Which are the properties of a Mercator chart?",
        answers: [
          "The scale is constant, great circles are depicted as curved lines, rhumb lines are depicted as straight lines",
          "The scales increases with latitude, great circles are depicted as curved lines, rhumb lines are depicted as straight lines",
          "The scales increases with latitude, great circles are depicted as straight lines, rhumb lines are depicted as curved lines",
          "The scale is constant, great circles are depicted as straight lines, rhumb lines are depicted as curved lines",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 44,
        question:
          "How are rhumb lines and great circles depicted on a direct Mercator chart?",
        answers: [
          "Rhumb lines: curved lines\nGreat circles: straight lines",
          "Rhumb lines: curved lines\nGreat circles: curved lines",
          "Rhumb lines: straight lines\nGreat circles: curved lines",
          "Rhumb lines: straight lines\nGreat circles: straight lines",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 45,
        question: "Which are the properties of a Lambert conformal chart?",
        answers: [
          "Great circles are depicted as straight lines and the chart is an equal-area projection",
          "The chart is conformal and an equal-area projection",
          "The chart is conformal and nearly true to scale",
          "Rhumb lines are depicted as straight lines and the chart is conformal",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 46,
        question:
          "Which lines have to be used by the pilot to determine the aircraft's position?",
        answers: [
          "True bearings (QTE)",
          "Magnetic bearings (QDR)",
          "Relative bearings (RB)",
          "Magnetic headings (MH)",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 47,
        question:
          "What is the radial from VOR Brünkendorf (BKD) (53°02'N, 011°33'E) to Pritzwalk (EDBU) (53°11'N, 12°11'E)?",
        answers: ["024°", "204°", "248°", "068°"],
        correct: 3,
        points: 1,
        figure: "NAV-031",
      },
      {
        num: 48,
        question:
          "The distance between two airports is 220 NM. On an aeronautical navigation chart the pilot measures 40.7 cm for this distance. The chart scale is...",
        answers: ["1 : 250000.", "1 : 2000000.", "1 : 500000.", "1 : 1000000."],
        correct: 3,
        points: 1,
      },
      {
        num: 49,
        question:
          "Given the following information, what is the aircraft position at the cross bearing?\nVOR Hamburg (HAM) (53°41'N, 010°12'E): Radial 119°\nVOR Brünkendorf (BKD) (53°02'N, 011°33'E): Radial 320°",
        answers: [
          "52°20'N, 10°10'E",
          "54°40'N, 12°50'E",
          "52°10'N, 10°20'E",
          "53°20'N, 11°10'E",
        ],
        correct: 3,
        points: 1,
        figure: "NAV-031",
      },
      {
        num: 50,
        question:
          "What is the distance from VOR Brünkendorf (BKD) (53°02'N, 011°33'E) to Pritzwalk (EDBU) (53°11'N, 12°11'E)?",
        answers: ["24 km", "42 NM", "24 NM", "42 km"],
        correct: 2,
        points: 1,
        figure: "NAV-031",
      },
      {
        num: 51,
        question:
          "A distance of 7.5 cm on an aeronautical chart represents a distance of 60.745 NM in reality.\nWhat is the chart scale?",
        answers: ["1 : 500000", "1 : 1 000000", "1 : 150000", "1 : 1500000"],
        correct: 3,
        points: 1,
      },
      {
        num: 52,
        question:
          "For a short flight from A to B the pilot extracts the following information from an aeronautical chart:\nTrue course: 245°.\nMagnetic variation: 7° W\nThe magnetic course (MC) equals...",
        answers: ["252°.", "245°.", "007°.", "238°."],
        correct: 0,
        points: 1,
      },
      {
        num: 53,
        question:
          "An aircraft is flying with an indicated airspeed (IAS) of 150 kt at 8000 ft MSL.\nAccording to the rule of thumb, the true airspeed (TAS) equals...",
        answers: ["174 kt.", "150 kt.", "142 kt.", "208 kt."],
        correct: 0,
        points: 1,
      },
      {
        num: 54,
        question:
          "Given:\nTrue course from A to B: 250°.\nGround distance: 210 NM.\nTAS: 130 kt.\nHeadwind component: 15 kt.\nEstimated time of departure (ETD): 0915 UTC.\nThe estimated time of arrival (ETA) is...",
        answers: ["1115 UTC.", "1105 UTC.", "1005 UTC.", "1052 UTC."],
        correct: 1,
        points: 2,
      },
      {
        num: 55,
        question:
          "Given:\nTrue course from A to B: 283°.\nGround distance: 75 NM.\nTAS: 105 kt.\nHeadwind component: 12 kt.\nEstimated time of departure (ETD): 1242 UTC.\nThe estimated time of arrival (ETA) is...",
        answers: ["1430 UTC", "1356 UTC", "1330 UTC", "1320 UTC"],
        correct: 2,
        points: 1,
      },
      {
        num: 56,
        question:
          "Given:\nTrue course from A to B: 352°.\nGround distance: 100 NM.\nGS: 107 kt.\nEstimated time of departure (ETD): 0933 UTC.\nThe estimated time of arrival (ETA) is...",
        answers: ["1129 UTC.", "1029 UTC.", "1146 UTC.", "1045 UTC."],
        correct: 1,
        points: 1,
      },
      {
        num: 57,
        question:
          "An aircraft travels 100 km in 56 minutes.\nThe ground speed (GS) equals...",
        answers: ["58 km/h.", "198 kt.", "93 kt.", "107 km/h."],
        correct: 3,
        points: 1,
      },
      {
        num: 58,
        question:
          "An aircraft travels 110 NM within 01:25.\nThe ground speed (GS) equals...",
        answers: ["120 km/h.", "160 km/h.", "86 kt.", "78 kt."],
        correct: 3,
        points: 1,
      },
      {
        num: 59,
        question:
          "What is the required flight time for a distance of 236 NM with a ground speed of 134 kt?",
        answers: ["1:34 h", "0:46 h", "1:46 h", "0:34 h"],
        correct: 2,
        points: 1,
      },
      {
        num: 60,
        question:
          "An aircraft is flying with a true airspeed (TAS) of 120 kt and experiences 35 kt tailwind.\nHow much time is needed for a distance of 185 NM?",
        answers: ["1 h 32 min", "0 h 50 min", "1 h 12 min", "2 h 11 min"],
        correct: 2,
        points: 1,
      },
      {
        num: 61,
        question:
          "An aircraft is flying with a true airspeed (TAS) of 180 kt and a headwind component of 25 kt for 2 hours and 25 minutes.\nThe distance flown equals...",
        answers: ["693 NM.", "435 NM.", "375 NM.", "202 NM."],
        correct: 2,
        points: 1,
      },
      {
        num: 62,
        question:
          "Given:\nCalibrated airspeed (CAS): 155 kt.\nFlight level (FL) 80.\nOutside air temperature (OAT): +15° C.\nThe true airspeed (TAS) equals...",
        answers: ["155 kts.", "170 kts.", "180 kts.", "134 kts."],
        correct: 2,
        points: 1,
      },
      {
        num: 63,
        question:
          "What is the true course (TC) from Uelzen (EDVU) (52°59'N, 10°28'E) to Neustadt (EDAN) (53°22'N, 011°37'E)?",
        answers: ["241°", "235°", "061°", "055°"],
        correct: 2,
        points: 1,
        figure: "NAV-031",
      },
      {
        num: 64,
        question:
          "An aircraft is flying at aFL 75 with an outside air temperature (OAT) of -9°C. The QNH altitude is 6500 ft.\nThe true altitude equals...",
        answers: ["6500 ft.", "6750 ft.", "7000 ft.", "6250 ft."],
        correct: 3,
        points: 1,
      },
      {
        num: 65,
        question:
          "What is the distance from Neustadt (EDAN) (53°22'N, 011°37'E) to Uelzen (EDVU) (52°59'N, 10°28'E)?",
        answers: ["46 NM", "78 km", "46 km", "78 NM"],
        correct: 0,
        points: 1,
        figure: "NAV-031",
      },
      {
        num: 66,
        question:
          "An aircraft is flying at a pressure altitude of 7000 feet with an outside air temperature (OAT) of +11°C. The QNH altitude is 6500 ft.\nThe true altitude equals...",
        answers: ["6500 ft.", "7000 ft.", "6750 ft.", "6250 ft."],
        correct: 2,
        points: 1,
      },
      {
        num: 67,
        question:
          "An aircraft is flying at a pressure altitude of 7000 feet with an outside air temperature (OAT) of +21°C. The QNH altitude is 6500 ft.\nThe true altitude equals...",
        answers: ["6750 ft.", "6250 ft.", "7000 ft.", "6500 ft."],
        correct: 2,
        points: 1,
      },
      {
        num: 68,
        question:
          "Given:\nTrue course: 255°.\nTAS: 100 kt.\nWind: 200°/10 kt.\nThe true heading equals...",
        answers: ["245°.", "275°.", "265°.", "250°."],
        correct: 3,
        points: 1,
      },
      {
        num: 69,
        question:
          "Given:\nTrue course: 165°.\nTAS: 90 kt.\nWind: 130°/20 kt.\nDistance: 153 NM.\nThe true heading equals...",
        answers: ["126°.", "158°.", "152°.", "165°."],
        correct: 1,
        points: 1,
      },
      {
        num: 70,
        question:
          "Given:\nGround speed (GS): 160 kt.\nTrue course (TC): 177°.\nWind vector (W/WS): 140°/20 kt.\nThe true heading (TH) equals...",
        answers: ["169°.", "173°.", "184°.", "180°."],
        correct: 1,
        points: 1,
      },
      {
        num: 71,
        question:
          "An aircraft is following a true course (TC) of 220° at a constant TAS of 220 kt. The wind vector is 270°/50 kt.\nThe ground speed (GS) equals...",
        answers: ["170 kt.", "185 kt.", "255 kt.", "135 kt."],
        correct: 1,
        points: 1,
      },
      {
        num: 72,
        question:
          "An aircraft is following a true course (TC) of 040° at a constant true airspeed (TAS) of 180 kt. The wind vector is 350°/30 kt.\nThe groundspeed (GS) equals...",
        answers: ["172 kt.", "159 kt.", "155 kt.", "168 kt."],
        correct: 1,
        points: 1,
      },
      {
        num: 73,
        question:
          "An aircraft is following a true course (TC) of 040° at a constant true airspeed (TAS) of 180 kt. The wind vector is 350°/30 kt.\nThe wind correction angle (WCA) equals...",
        answers: ["+ 5°", "+ 11°", "- 7°", "- 9°"],
        correct: 2,
        points: 1,
      },
      {
        num: 74,
        question:
          "Given:\nTrue course: 270°.\nTAS: 100 kt.\nWind: 090°/25 kt.\nDistance: 100 NM.\nThe ground speed (GS) equals...",
        answers: ["125 kt.", "117 kt.", "120 kt.", "131 kt."],
        correct: 0,
        points: 1,
      },
      {
        num: 75,
        question:
          "Given:\nTrue course: 270°.\nTAS: 100 kt.\nWind: 090°/25 kt.\nDistance: 100 NM.\nThe flight time equals...",
        answers: ["62 Min.", "37 Min.", "48 Min.", "84 Min."],
        correct: 2,
        points: 1,
      },
      {
        num: 76,
        question:
          "An aircraft is following a true course (TC) of 040° at a constant true airspeed (TAS) of 180 kt. The wind vector is 350°/30 kt.\nThe wind correction angle (WCA) equals...",
        answers: ["7° left.", "3° right.", "3° left.", "7° right."],
        correct: 0,
        points: 1,
      },
      {
        num: 77,
        question:
          "Given:\nTrue course: 120°.\nTAS: 120 kt.\nWind: 150°/12 kt.\nThe WCA equals...",
        answers: [
          "6° to the right.",
          "3° to the left.",
          "3° to the right.",
          "6° to the left.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 78,
        question:
          "The distance from 'A' to 'B' measures 120 NM. At a distance of 55 NM from 'A' the pilot realizes a deviation of 7 NM to the right.\nWhat approximate course change must be made to reach 'B' directly?",
        answers: ["8° left", "6° left", "15° left", "14° left"],
        correct: 3,
        points: 1,
      },
      {
        num: 79,
        question:
          "An aeroplane has a heading of 090°. The distance which has to be flown is 90 NM. After 45 NM the aeroplane is 4.5 NM north of the planned flight path.\nWhat is the corrected heading to reach the arrival aerodrome directly?",
        answers: [
          "18° to the right",
          "12° to the right",
          "6° to the right",
          "9° to the right",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 80,
        question: "What is the meaning of the 1:60 rule?",
        answers: [
          "6 NM lateral offset at 1° drift after 10 NM",
          "60 NM lateral offset at 1° drift after 1 NM",
          "1 NM lateral offset at 1° drift after 60 NM",
          "10 NM lateral offset at 1° drift after 60 NM",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 81,
        question:
          "An aircraft is flying from 'A' to 'B' (distance 220 NM) at an average ground speed (GS) of 120 kt. It departs 'A' at 1200 UTC. After 70 NM along the course from 'A', the aircraft is 5 min ahead of the planned schedule.\nUsing the actual GS, what is the revised estimated time of arrival (ETA) at B?",
        answers: ["1335 UTC", "1340 UTC", "1345 UTC", "1330 UTC"],
        correct: 0,
        points: 1,
      },
      {
        num: 82,
        question:
          "Assume calm wind and an aircraft descending from 9000 ft to 1500 ft.\nThe rate of descent (ROD) equals 1200 ft/min.\nThe elapsed time will be...",
        answers: ["6 min.", "15 min.", "12 min.", "8 min."],
        correct: 0,
        points: 1,
      },
      {
        num: 83,
        question:
          "Assume zero wind and an aircraft descending from 7500 ft to 1200 ft with an average true airspeed (TAS) during the descent of 105 kt. The rate of descent (ROD) equals 800 ft/min.\nThe elapsed time will be...",
        answers: ["8 Min.", "6 Min.", "15 Min.", "12 Min."],
        correct: 0,
        points: 1,
      },
      {
        num: 84,
        question: "Which answer completes the flight plan (marked cells)?",
        answers: [
          "TH: 185°.\nMH: 185°.\nMC: 180°.",
          "TH: 185°.\nMH: 184°.\nMC: 178°.",
          "TH: 173°.\nMH: 174°.\nMC: 178°.",
          "TH: 173°.\nMH: 184°.\nMC: 178°.",
        ],
        correct: 1,
        points: 3,
        figure: "NAV-014",
      },
      {
        num: 85,
        question:
          "What radio navigation aid can be received with the attached aerial?",
        answers: ["VOR", "DME", "NDB", "VDF"],
        correct: 2,
        points: 1,
        figure: "NAV-017",
      },
      {
        num: 86,
        question:
          "The approximate propagation speed of electromagnetic waves is...",
        answers: [
          "300000 km/s.",
          "300000 m/s.",
          "300000 NM/s.",
          "300000 ft/s.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 87,
        question:
          "Radio waves within the LF and MF range (e.g. NDB) travel as...",
        answers: [
          "sky wave.",
          "sky wave and as ground / surface wave.",
          "ground / surface wave.",
          "space wave (quasi-optical).",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 88,
        question: "Radio waves within the VHF range (e.g. VOR) travel as...",
        answers: [
          "sky wave and ground / surface wave.",
          "ground / surface wave.",
          "sky wave.",
          "space wave (quasi-optical).",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 89,
        question: "Quasi-optical waves travel...",
        answers: [
          "along the surface of the earth.",
          "through the air directly from the transmitter to the receiver.",
          "through the air and are influenced (e.g. reflected) by the ionosphere.",
          "along the surface of the earth, but are absorbed by the sea.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 90,
        question: "A VHF direction finder (VDF) can determine...",
        answers: [
          "slant ranges.",
          "magnetic bearings.",
          "approach speeds.",
          "true courses.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 91,
        question:
          "Which equipment is needed on board of an aircraft to use a VHF direction finder (VDF)?",
        answers: [
          "At least two VHF aerials",
          "A VHF radio",
          "A relative bearing indicator (RBI)",
          "A VDF receiver",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 92,
        question: "Given:\nQDM: 138°\nVAR: 10° E\nThe QUJ equals...",
        answers: ["168°.", "318°.", "328°.", "148°."],
        correct: 3,
        points: 1,
      },
      {
        num: 93,
        question: "Given:\nQTE: 229°\nVAR: 10° W\nThe QDM equals...",
        answers: ["039°.", "059°.", "239°.", "049°."],
        correct: 1,
        points: 1,
      },
      {
        num: 94,
        question: "Given:\nQDR: 022°\nVAR: 10° E\nThe QTE equals...",
        answers: ["212°.", "202°.", "052°.", "032°."],
        correct: 3,
        points: 1,
      },
      {
        num: 95,
        question: "Given:\nQDM: 248°\nVAR: 10° W\nThe QTE is...",
        answers: ["238°.", "078°.", "058°.", "258°."],
        correct: 2,
        points: 1,
      },
      {
        num: 96,
        question: "Given:\nQDR: 067°\nVAR: 5° E\nThe QDM equals...",
        answers: ["247°.", "072°.", "252°.", "257°."],
        correct: 0,
        points: 1,
      },
      {
        num: 97,
        question: "Given:\nQDR: 152°\nVAR: 5° W\nDEV: 5° E\nThe QUJ equals...",
        answers: ["332°.", "147°.", "327°.", "317°."],
        correct: 2,
        points: 1,
      },
      {
        num: 98,
        question: "Given:\nQTE: 203°\nVAR: 10° E\nThe QDR equals...",
        answers: ["023°.", "213°.", "193°.", "013°."],
        correct: 2,
        points: 1,
      },
      {
        num: 99,
        question: "Given:\nQTE: 248°\nVAR: 10° W\nThe QDR equals...",
        answers: ["068°.", "238°.", "258°.", "078°."],
        correct: 2,
        points: 1,
      },
      {
        num: 100,
        question: "Given:\nQDM: 134°\nVAR: 5° W\nThe QTE equals...",
        answers: ["314°.", "299°.", "129°.", "309°."],
        correct: 3,
        points: 1,
      },
      {
        num: 101,
        question:
          "The pilot receives a QDR of 225° from the VDF ground station.\nWhere is the aircraft located in relation to the ground station?",
        answers: ["Southeast", "Northeast", "Southwest", "Northwest"],
        correct: 2,
        points: 1,
      },
      {
        num: 102,
        question: "The term QDR means...",
        answers: [
          "magnetic bearing from the station to the aircraft.",
          "true bearing from the aircraft to the station.",
          "magnetic bearing from the aircraft to the station.",
          "true bearing from the station to the aircraft.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 103,
        question: "The term QTE means...",
        answers: [
          "magnetic bearing from the station to the aircraft.",
          "magnetic bearing from the aircraft to the station.",
          "true bearing from the station to the aircraft.",
          "true bearing from the aircraft to the station.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 104,
        question:
          "A pilot receives a QDR of 135° from the VDF ground station.\nWhere is the aircraft located in relation to the ground station?",
        answers: ["Northwest", "Southeast", "Southwest.", "Northeast"],
        correct: 1,
        points: 1,
      },
      {
        num: 105,
        question:
          "A pilot receives a QDR of 315° from the VDF ground station.\nWhere is the aircraft located in relation to the ground station?",
        answers: ["Northwest", "Northeast", "Southeast", "Southwest"],
        correct: 0,
        points: 1,
      },
      {
        num: 106,
        question: "The VDF range depends on...",
        answers: [
          "the aircraft's speed.",
          "the condition of the ionosphere.",
          "the range of the ground / surface wave.",
          "the aircraft's altitude.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 107,
        question:
          "Which equipment is needed on board of an aircraft to receive signals from a nondirectional beacon (NDB)?",
        answers: [
          "Course deviation indicator (CDI)",
          "Horizontal situation indicator (HSI)",
          "Automatic direction finder (ADF)",
          "Secondary surveillance radar (SSR)",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 108,
        question:
          "Non-directional beacons (NDBs) transmit within which frequency band?",
        answers: [
          "Very low frequency (VLF) and low frequency (LF)",
          "Low frequency (LF) and medium frequency (MF)",
          "High frequency (HF)",
          "Very high frequency (VHF)",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 109,
        question:
          "A pilot wants to approach an NDB on QDM 090°. The aircraft flies for about 5 minutes with a magnetic heading (MH) of 095° and the RBI indication of 355°. After 6 minutes the RBI indicates 358°.\nWhich statement is correct?",
        answers: [
          "The crosswind component increased; the pilot has to increase the MH",
          "The crosswind component increased; the pilot has to decrease the MH",
          "The crosswind component decreased; the pilot has to increase the MH",
          "The crosswind component decreased; the pilot has to decrease the MH",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 110,
        question:
          "The pilot wants to proceed directly to the beacon. The wind is calm.\nThe pilot should follow a QDM of...",
        answers: ["080°.", "200°.", "260°.", "230°."],
        correct: 2,
        points: 1,
        figure: "NAV-019",
      },
      {
        num: 111,
        question:
          "What is the difference between a locator beacon and a non-directional beacon (NDB)?",
        answers: [
          "Locator beacons transmit more precisely",
          "Locator beacons have a higher range than NDBs",
          "Locator beacons transmit on request only",
          "Locator beacons have a lower range than NDBs",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 112,
        question:
          "The range of NDBs transmitting in the medium frequency range is greatest...",
        answers: [
          "before midday.",
          "at night.",
          "on midday.",
          "in the daytime.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 113,
        question:
          "The shoreline effect is greatest with radio wave propagation...",
        answers: [
          "at a right angle to the coast; aircraft below 6000 ft.",
          "at an acute angle to the coast; aircraft above 6000 ft.",
          "at an acute angle to the coast; aircraft below 6000 ft.",
          "at a right angle to the coast; aircraft above 6000 ft.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 114,
        question: "Fading in LF/MF frequency range occurs mainly...",
        answers: [
          "in the late afternoon.",
          "at midday.",
          "during the night.",
          "in the daytime.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 115,
        question:
          "The progress of an electromagnetic oscillation can be described by the...",
        answers: [
          "phase angle.",
          "amplitude angle.",
          "wave angle.",
          "frequency angle.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 116,
        question:
          "When transmitter and receiver are moving towards each other...",
        answers: [
          "the frequency varies, but the wavelength remains constant.",
          "the perceived frequency equals the transmitted frequency.",
          "the perceived frequency increases.",
          "the perceived frequency decreases.",
        ],
        correct: 2,
        points: 1,
      },
      {
        num: 117,
        question:
          "When transmitter and receiver are moving away from each other...",
        answers: [
          "the perceived frequency increases.",
          "the perceived frequency decreases.",
          "the frequency varies, but the wavelength remains constant.",
          "the perceived frequency equals the transmitted frequency.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 118,
        question: "VOR radials are defined based on the principle of...",
        answers: [
          "phase comparison of two signals.",
          "frequency comparison of two signals.",
          "pulse comparison of two signals.",
          "amplitude comparison of two signals.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 119,
        question: "A VOR radial corresponds to the...",
        answers: ["QTE.", "QUJ.", "QDR.", "QDM."],
        correct: 2,
        points: 1,
      },
      {
        num: 120,
        question:
          "Full deflection of the course deviation indicator (CDI) means that the aircraft is located at least...",
        answers: [
          "2 NM beside the selected course.",
          "10 NM beside the selected course.",
          "2° beside the selected course.",
          "10° beside the selected course.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 121,
        question: "Where is the aircraft located in relation to the VOR?",
        answers: ["Northeast", "Southeast", "Southwest", "Northwest"],
        correct: 0,
        points: 1,
        figure: "NAV-022",
      },
      {
        num: 122,
        question: "The aircraft is on radial...",
        answers: ["066°.", "234°.", "060°.", "246°."],
        correct: 1,
        points: 1,
        figure: "NAV-024",
      },
      {
        num: 123,
        question: "The range of a VOR is affected by...",
        answers: [
          "daylight interference.",
          "reflected sky waves.",
          "multipath propagation of the ground wave.",
          "transmitter and receiver altitude.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 124,
        question:
          "The distance measuring equipment (DME) determines the distance based on the principle of...",
        answers: [
          "time measurement.",
          "Doppler.",
          "laser measurement.",
          "phase comparison.",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 125,
        question: "The DME reading is a...",
        answers: [
          "ground distance.",
          "air range.",
          "radial distance.",
          "slant range.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 126,
        question:
          "The differenz between indicated DME slant range and horizontal distance from the DME station increases...",
        answers: [
          "when circling around the DME station.",
          "when descending.",
          "when departing the DME station.",
          "when approaching the DME station.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 127,
        question:
          "Using primary ground radar, the direction of the aeroplane in relation to the antenna is determined by...",
        answers: [
          "the pulse pair interval.",
          "the orientation of the antenna.",
          "the frequency shift of the received pulse.",
          "time measurement.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 128,
        question:
          "Which instantaneous information can be obtained from ground radar equipment?",
        answers: [
          "Distance and direction",
          "Airspeed (TAS) and distance",
          "Airspeed (TAS) and heading",
          "Direction and airspeed (TAS)",
        ],
        correct: 0,
        points: 1,
      },
      {
        num: 129,
        question:
          "The on-board equipment of the secondary surveillance radar (SSR) is called...",
        answers: [
          "course indicator.",
          "transponder.",
          "interrogator.",
          "decoder.",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 130,
        question: "What is the difference between primary and secondary radar?",
        answers: [
          "The pulses of a primary radar are variably pulse-modulated, the pulses of a secondary radar are statically amplitude-modulated",
          "The pulses of a primary radar are variably amplitude-modulated, the pulses of a secondary radar are statically pulse-modulated",
          "The primary radar is displayed on a computer screen, the secondary radar on a radar strip",
          "The pulses of a primary radar are reflected by the aircraft's surface, the pulses of a secondary radar system are answered by a transponder",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 131,
        question: "The transponder code in case of hi-jacking is...",
        answers: ["7000.", "7600.", "7500.", "7700."],
        correct: 2,
        points: 1,
      },
      {
        num: 132,
        question:
          "The transponder code in case of a radio communication failure is...",
        answers: ["7700.", "7500.", "7000.", "7600."],
        correct: 3,
        points: 1,
      },
      {
        num: 133,
        question: "Which altitude is transmitted by the transponder in mode C?",
        answers: [
          "QFE altitude",
          "Pressure altitude",
          "QNH altitude",
          "Radio altitude",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 134,
        question:
          "How many satellites are necessary for a precise and verified three-dimensional determination of the position?",
        answers: ["Two", "Three", "Five", "Four"],
        correct: 3,
        points: 1,
      },
      {
        num: 135,
        question:
          "When using a GPS for tracking to the next waypoint, a deviation indication is shown by a vertical bar and dots to the left and to the right of the bar.\nWhat statement describes the correct interpretation of the display?",
        answers: [
          "The deviation of the bar from the center indicates the track error as angular distance in degrees; the scale for full deflection depends on the operating mode of the GPS.",
          "The deviation of the bar from the center indicates the track error as angular distance in degrees; the scale for full deflection is +-10°.",
          "The deviation of the bar from the center indicates the track error as absolute distance in NM; the scale for full deflection is +-10 NM.",
          "The deviation of the bar from the center indicates the track error as absolute distance in NM; the scale for full deflection depends on the operating mode of the GPS.",
        ],
        correct: 3,
        points: 1,
      },
      {
        num: 136,
        question: 'What is meant by the term "terrestrial navigation"?',
        answers: [
          "Orientation by ground celestial object during visual flight",
          "Orientation by ground features during visual flight",
          "Orientation by GPS during visual flight",
          "Orientation by instrument readings during visual flight",
        ],
        correct: 1,
        points: 1,
      },
      {
        num: 137,
        question:
          "What ground features should preferrably be used for orientation during visual flight?",
        answers: [
          "Border lines",
          "Farm tracks and creeks",
          "Power lines",
          "Rivers, railroads, highways",
        ],
        correct: 3,
        points: 1,
      },
    ],
  },
];

export default ppl;
