import { memo, useMemo, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Content, Footer, Icon, Question } from "../components";
import data from "../ppl";
import { arrayShuffle, arraySum, getRand } from "../util";
import css from "./quiz.module.css";

const RATIOS = [
  16, // air law
  12, // human performance
  16, // meteo
  12, // comms
  12, // principles of flight
  12, // operational procedures
  12, // flight performance + planning
  16, // aircraft general knowledge
  12, // navigation
];
const RATIOS_SUM = arraySum(RATIOS);
const LENGTH_MIN = 10;
const LENGTH_MAX = 120;

function Quiz() {
  const p = useParams<{ length: string }>();
  const length =
    Math.min(LENGTH_MAX, Math.max(LENGTH_MIN, parseInt(p.length || ""))) ||
    LENGTH_MIN;
  const [answers, setAnswer] = useState<TScoreProps["answers"]>({});
  const [finished, setFinished] = useState(false);
  const [attempt, setAttempt] = useState(1);
  const quiz = useMemo(() => {
    const randomQuestions: TScoreProps["quiz"] = [];
    const numPerSubject: number[] = [];
    RATIOS.forEach((r) =>
      numPerSubject.push(Math.floor((r * length) / RATIOS_SUM))
    );
    const numSubjects = numPerSubject.length;
    for (let i = arraySum(numPerSubject); i < length; i += 1) {
      numPerSubject[getRand(numSubjects)] += 1;
    }

    if (attempt > 0) {
      numPerSubject.forEach((num, i) => {
        randomQuestions[i] = arrayShuffle([...data[i].questions]).slice(0, num);
      });
    }

    return randomQuestions;
  }, [length, attempt]);

  const onAnswer = useCallback((question: number, answer: number) => {
    setAnswer((a) => ({ ...a, [question]: answer }));
  }, []);

  let questionNum = 0;
  return (
    <>
      <Content tag="header">
        <Link to="/">
          <Icon type="back" />
        </Link>
        <h1>Quiz {length}!</h1>
      </Content>
      <main>
        <section>
          {quiz.map((g) => {
            return g.map((question) => {
              questionNum += 1;
              if (finished && answers[questionNum] === question.correct)
                return null;

              return (
                <Question
                  mode={finished ? "study" : "quiz"}
                  question={question}
                  num={questionNum}
                  answer={
                    finished && answers[questionNum] === undefined
                      ? 6
                      : answers[questionNum]
                  }
                  onAnswer={onAnswer}
                />
              );
            });
          })}
        </section>
        <Content className={css.results} tag="section">
          {!finished && (
            <Button onClick={() => setFinished(true)}>Show results!</Button>
          )}
          {finished && (
            <>
              <Score quiz={quiz} answers={answers} />
              <div>
                <Button
                  onClick={() => {
                    setFinished(false);
                    setAnswer({});
                    setAttempt((a) => a + 1);
                    window.scrollTo(0, 0);
                  }}
                >
                  Try again
                </Button>
              </div>
            </>
          )}
        </Content>
      </main>
      <Footer />
    </>
  );
}

const Score = memo(({ quiz, answers }: TScoreProps) => {
  const results = [];
  let totalQuestions = 0;
  let totalCorrect = 0;
  for (let g = 0, q = 1; g < quiz.length; g += 1) {
    results[g] = { group: data[g].group, questions: 0, correct: 0 };
    for (let gq = 0; gq < quiz[g].length; gq += 1, q += 1) {
      results[g].questions += 1;
      totalQuestions += 1;
      if (quiz[g][gq].correct === answers[q]) {
        results[g].correct += 1;
        totalCorrect += 1;
      }
    }
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Subject</th>
            <th>Result</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {results.map((r, i) => {
            return (
              <tr key={i}>
                <td>{r.group}</td>
                <td>
                  {r.correct} / {r.questions}
                </td>
                <TdPercentage correct={r.correct} questions={r.questions} />
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td>
              {totalCorrect} / {totalQuestions}
            </td>
            <TdPercentage correct={totalCorrect} questions={totalQuestions} />
          </tr>
        </tfoot>
      </table>
    </>
  );
});

function TdPercentage({
  correct,
  questions,
}: {
  correct: number;
  questions: number;
}) {
  const percentage = (correct / questions) * 100;
  const className =
    percentage > 90 ? css.green : percentage >= 75 ? css.orange : css.red;

  return <td className={className}>{percentage.toFixed(0)}%</td>;
}

type TScoreProps = {
  quiz: TData[number]["questions"][];
  answers: { [q: number]: number };
};

export default Quiz;
