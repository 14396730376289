import { memo } from "react";
import { Content } from "../content";
import { Icon, TIconProps } from "../icon";
import { insertNewLines } from "../../util";
import css from "./question.module.css";

export const Question = memo(
  ({ mode, num, question, onAnswer, answer = -1, className }: TProps) => {
    const classNames = [css.question];
    if (className) classNames.push(className);

    return (
      <Content className={classNames.join(" ")}>
        <div className={css.text}>
          <div>{num}</div>
          <p>{insertNewLines(question.question)}</p>
        </div>
        <div className={css.points}>
          ({question.points.toFixed(2).replace(".", ",")} P.)
        </div>
        {question.figure && (
          <img src={`/figures/${question.figure.toLowerCase()}.jpg`} alt="" />
        )}
        <ul>
          {question.answers.map((a, i) => {
            let icon: TIconProps["type"] | undefined;
            const isStudy = mode === "study";
            if (isStudy) {
              if (answer === i)
                icon = i === question.correct ? "check" : "cross";
              else if (answer >= 0 && i === question.correct) icon = "check";
            } else if (answer === i) icon = "cross";

            return (
              <li key={i} className={css.answer}>
                <div
                  onClick={
                    isStudy && answer >= 0 ? undefined : () => onAnswer(num, i)
                  }
                >
                  <button className={icon && isStudy ? css[icon] : ""}>
                    {icon && <Icon type={icon} />}
                  </button>
                  {insertNewLines(a)}
                </div>
              </li>
            );
          })}
        </ul>
      </Content>
    );
  }
);

type TProps = {
  mode: "study" | "quiz";
  num: number;
  question: TData[number]["questions"][number];
  onAnswer: (question: number, option: number) => void;
  answer?: number;
  className?: string;
};
