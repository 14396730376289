import { Content } from "../content";
import css from "./footer.module.css";

export function Footer() {
  return (
    <Content tag="footer" className={css.footer}>
      ECQB-PPL(A) v2020.2
    </Content>
  );
}
