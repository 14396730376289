const RANDOMIZE = "randomize";
const THEME = "theme";

export function lsReadRandomize(): boolean {
  return localStorage.getItem(RANDOMIZE) === "1";
}

export function lsWriteRandomize(randomize: boolean) {
  localStorage.setItem(RANDOMIZE, randomize ? "1" : "0");
}

export function lsReadTheme(): string {
  const theme = localStorage.getItem(THEME);

  return theme
    ? theme
    : window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
}

export function lsWriteTheme(theme: string) {
  localStorage.setItem(THEME, theme);
}
