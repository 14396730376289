import type { PropsWithChildren } from "react";
import css from "./content.module.css";

export function Content({
  children,
  tag = "div",
  className = "",
}: PropsWithChildren<TProps>) {
  const Tag = tag;
  return (
    <Tag className={className}>
      <div className={css.content}>{children}</div>
    </Tag>
  );
}

type TProps = {
  className?: string;
  tag?: "div" | "main" | "header" | "section" | "footer";
};
