import { useState, useCallback, useEffect, PropsWithChildren } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Main from "./main";
import Study from "./study";
import Quiz from "./quiz";
import { lsReadTheme, lsWriteTheme } from "./util";

function App() {
  const [theme, setTheme] = useState(lsReadTheme());
  const onTheme = useCallback(() => {
    setTheme((t) => {
      const newTheme = t === "dark" ? "light" : "dark";
      lsWriteTheme(newTheme);
      return newTheme;
    });
  }, []);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Main onTheme={onTheme} />} />
          <Route path="/:subject" element={<Study />} />
          <Route path="/quiz/:length" element={<Quiz />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

const ScrollToTop = (props: PropsWithChildren) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default App;
