import { PropsWithChildren } from "react";
import css from "./button.module.css";

export function Button({
  children,
  className,
  onClick,
}: PropsWithChildren<TProps>) {
  const classNames = [css.button];
  if (className) classNames.push(className);

  return (
    <button className={classNames.join(" ")} onClick={onClick}>
      {children}
    </button>
  );
}

type TProps = {
  className?: string;
  onClick?: () => void;
};
