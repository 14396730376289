import css from "./icon.module.css";

export function Icon({ type, className = "" }: TIconProps) {
  const style = [css.icon];
  if (className) style.push(className);

  return (
    <svg viewBox="0 0 100 100" className={style.join(" ")}>
      <use xlinkHref={`#icon-${type}`} />
    </svg>
  );
}

export type TIconProps = {
  type: "back" | "theme" | "check" | "cross" | "random";
  className?: string;
};
