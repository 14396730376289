import { Fragment } from "react";

export function insertNewLines(text: string) {
  return text.split("\n").map((s, i) => (
    <Fragment key={i}>
      {i > 0 ? <br /> : ""}
      {s}
    </Fragment>
  ));
}

export function arrayShuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function arraySum(array: number[]) {
  return array.reduce((partialSum, a) => partialSum + a, 0);
}

export function getRand(max: number, min = 0): number {
  return Math.floor(Math.random() * (max - min)) + min;
}
