import { Link } from "react-router-dom";
import { Content, Footer, Icon } from "../components";
import ppl from "../ppl";
import css from "./main.module.css";

function Main({ onTheme }: TProps) {
  return (
    <>
      <Content className={css.header} tag="header">
        <h1>PPL Practice Questions</h1>
        <button title="Toggle theme" onClick={onTheme}>
          <Icon type="theme" />
        </button>
      </Content>
      <Content tag="main">
        <h2>Study</h2>
        <ul className={css.list}>
          {ppl.map((s) => (
            <li key={s.num}>
              <Link to={makeLink(s)}>
                <span>{s.group}</span> <span>({s.questions.length})</span>
              </Link>
            </li>
          ))}
        </ul>
        <h2>Quiz</h2>
        <ul className={css.list}>
          <li>
            <Link to="/quiz/10">10 questions</Link>
          </li>
          <li>
            <Link to="/quiz/25">25 questions</Link>
          </li>
          <li>
            <Link to="/quiz/50">50 questions</Link>
          </li>
          <li>
            <Link to="/quiz/100">100 questions</Link>
          </li>
          <li>
            <Link to="/quiz/120">120 questions</Link>
          </li>
        </ul>
      </Content>
      <Footer />
    </>
  );
}

type TProps = { onTheme: () => void };
function makeLink(s: typeof ppl[number]) {
  return `${s.num}-${s.group.toLowerCase().replaceAll(" ", "-")}`;
}

export default Main;
